import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  GetProfile,
  formatBankDetail,
  formatBankDetails,
  formatEmployeeTimeFormData,
  formatManagerData,
  formatProjectList,
  formatUsersData,
  getEmpTime
} from "redux/selector/Employee/profile";
import {
  allBankDetails,
  empTime,
  isLoading,
  setCityName,
  setCountryState,
  setManagerName,
  setPersonalStatus,
  setProfile,
  setProfileStatus,
  setProjectList,
  setSuccess,
  setUsersName,
  userProfile
} from "redux/actions/action";
import ToastServices from "ToastServices";

export const profileData = (id, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const path = apiUrl.user + id;
    const { response, status } = await Helper.get(path);
    if (status === 200 || status === 201) {
      dispatch(userProfile(response?.user));
    }
    setLoading(false);
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error During Fetch Data ! ",
      autoClose: 3000,
    });
    setLoading(false);
  }
};

export const handleCountryName = () => async (dispatch) => {
  try {
    const path = `state_city?country=IN`;
    const { response } = await Helper.get(path);
    dispatch(setCountryState(response?.state));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error During Fetch Data ! ",
      autoClose: 3000,
    });
  }
};

export const getCities = (city, state) => async (dispatch) => {
  try {
    if (city || state) {
      const path = `city_add?state=${city}`;
      const { response } = await Helper.get(path);
      dispatch(setCityName(response?.city));
    }
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error During Fetch Data ! ",
      autoClose: 3000,
    });
  }
};

export const updateUserProfile =
  (id, jsonObj, setHandle, setSubmitshow, setReload) => async (dispatch) => {
    try {
      setReload(false);
      setHandle(true);
      dispatch(isLoading(false));
      const path = apiUrl.userProfile + id;

      const { status } = await Helper.put(jsonObj, path);

      if (status === 200 || status === 201) {
        ToastServices.showToast({
          type: "success",
          message: "Details has been Updated.",
          autoClose: 3000,
        });
        setReload(true);
        setHandle(false);
        setSubmitshow(false);
      }
    } catch (error) {
      setHandle(false);
      if (error.message.includes("422")) {
        ToastServices.showToast({
          type: "error",
          message: "Invalid Pin Code Entered",
          autoClose: 3000,
        });
      } else {
        ToastServices.showToast({
          type: "error",
          message: "Not Update Data internal error! ",
          autoClose: 3000,
        });
      }
    } finally {
      dispatch(isLoading(true));
    }
  };

export const updateRestrictedUserProfile =
  (id, jsonObj, setHandle, setSubmitshow, setReload) => async (dispatch) => {
    try {
      setReload(false);
      setHandle(true);
      dispatch(isLoading(false));
      const path = apiUrl.userRestrictProfile + id;

      const { status } = await Helper.put(jsonObj, path);

      if (status === 200 || status === 201) {
        ToastServices.showToast({
          type: "success",
          message: "Details has been Updated.",
          autoClose: 3000,
        });
        setReload(true);
        setHandle(false);
        setSubmitshow(false);
      }
    } catch (error) {
      setHandle(false);
      ToastServices.showToast({
        type: "error",
        message: "Not Update Data internal error! ",
        autoClose: 3000,
      });
    } finally {
      dispatch(isLoading(true));
    }
  };

export const fetchAllManager = () => async (dispatch) => {
  const path = "fetch_all_manager";
  try {
    const { response } = await Helper.get(path);
    const formattedResponse = formatManagerData(response);
    dispatch(setManagerName(formattedResponse));
  } catch (error) {
    console.error("Error!", error);
  }
};

export const fetchAllUsers = () => async (dispatch) => {
  const path = "users/search";
  try {
    const { response } = await Helper.get(path);
    const formattedResponse = formatUsersData(response);
    dispatch(setUsersName(formattedResponse));
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const updateUserDocuments =
  (id, jsonObj, setEditState, setHandle) => async () => {
    setHandle(true);
    try {
      const path = "documents" + `/${id}`;
      const { status } = await Helper.put(jsonObj, path, true);
      if (status === 200 || status === 201) {
        ToastServices.showToast({
          type: "success",
          message: "Documents Updated has been Updated.",
          autoClose: 3000,
        });
        setEditState(false);
        setHandle(false);
      }
    } catch (error) {
      ToastServices.showToast({
        type: "error",
        message: error,
        autoClose: 3000,
      });
      setHandle(false);
    }
  };

export const handleBankDetails =
  (eyeid, userid, setLoading) => async (dispatch) => {
    let path = "";
    if (eyeid) {
      path = apiUrl.bank + eyeid;
    } else {
      path = apiUrl.bank + userid;
    }
    try {
      setLoading(true);
      const { response } = await Helper.get(path);
      const formattedBankResponse = formatBankDetails(response);
      dispatch(allBankDetails(formattedBankResponse));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToastServices.showToast({
        type: "error",
        message: "Error During Fetch Data ! ",
        autoClose: 3000,
      });
    }
  };

export const updateBankDetails =
  (eyeId, formdata, setSubmitshow, setEditState, setReload) =>
    async (dispatch) => {
      try {
        setReload(true);
        const path = `${apiUrl.bank}${eyeId}`;
        const response = await Helper.put(formdata, path, true);
        const { status, data } = response;
        if (status === 200 || status === 201) {
          const formattedBankResponse = formatBankDetail(data);
          dispatch(setSuccess(true, formattedBankResponse));
          ToastServices.showToast({
            type: "success",
            message: "Successfully updated!",
            autoClose: 3000,
          });
          setSubmitshow(false);
          setEditState(false);
        } else {
          ToastServices.showToast({
            message: "Something went wrong!",
            type: "error",
            autoClose: 3000,
          });
        }
      } catch (error) {
        ToastServices.showToast({
          message:
          error.message || "An error occurred while updating bank details.",
          type: "error",
          autoClose: 3000,
        });
      } finally {
        setReload(false);
      }
    };

export const updateBankDetailnew =
  (eyeId, formdata, setSubmitshow, setReload) => async (dispatch) => {
    const userData = JSON.parse(localStorage.getItem("userLoginToken"));
    try {
      setReload(true);
      const path = apiUrl.bank + eyeId;
      const { status } = await Helper.put(formdata, path, true);
      dispatch(setSuccess(true));
      if (status === 200 || status === 201) {
        ToastServices.showToast({
          type: "success",
          message: "Successfully !",
          autoClose: 3000,
        });
        setSubmitshow(false);
        setReload(false);
        dispatch(handleBankDetails(eyeId, userData?.id, setReload));
      }
    } catch (error) {
      setReload(false);
      ToastServices.showToast({
        message: error,
        type: "error",
        autoClose: "3000",
      });
    }
  };

export const changePassword =
  (id, jsonObj, setSubmitshow, setAccordionOpen, setReload) =>
    async (dispatch) => {
      try {
        setReload(true);
        const path = apiUrl.changePassword + id;
        const { status } = await Helper.put(jsonObj, path);
        if (status === 200 || status === 201) {
          setSubmitshow(false);
          ToastServices.showToast({
            message: "Password Updated Successfully",
            type: "success",
            autoClose: "3000",
          });
          setReload(false);
          setAccordionOpen(false);
        }
        dispatch(setSuccess(true));
      } catch (error) {
        setReload(false);
        ToastServices.showToast({
          message: error,
          type: "error",
          autoClose: "3000",
        });
      }
    };

export const salaryInfo = (id, jsonObj) => async (dispatch) => {
  try {
    const path = `${apiUrl.salaryInfo}/${id}`;
    const { status } = await Helper.patch(jsonObj, path);
    dispatch(setSuccess(true));
    if (status === 201) {
      ToastServices.showToast({
        type: "success",
        message: "Status Has Been Updated !",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: "3000",
    });
  }
};

export const fetchAllProjectList = () => async (dispatch) => {
  const path = "projects";
  try {
    const { response } = await Helper.get(path);
    const formattedResponse = formatProjectList(response);

    dispatch(setProjectList(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: "3000",
    });
  }
};

export const AssignProject = (id, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const path = `${apiUrl.user}${id}/${apiUrl.assignProject}`;
    const { status } = await Helper.post(data, path);
    setLoading(false);
    dispatch(setSuccess(true));
    if (status === 200) {
      ToastServices.showToast({
        type: "success",
        message: "Successfully !",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: "3000",
    });
  }
};

export const AssignManager = (id, data, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const path = `${apiUrl.user}${id}/${apiUrl.assignManager}`;
    const { status } = await Helper.post(data, path);
    setLoading(false);
    dispatch(setSuccess(true));
    if (status === 200) {
      ToastServices.showToast({
        message: "Successfully !",
        type: "success",
        autoClose: "3000",
      });
    }
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error ! ",
      autoClose: 3000,
    });
  }
};

export const UpdateUserProfile = (formData, id, setShow) => async () => {
  try {
    setShow(false);
    const path = `users/${id}`;
    const { status } = await Helper.put(formData, path, true);
    if (status === 201 || status === 200) {
      setShow(true);
      ToastServices.showToast({
        message: "profile picture updated successfully !",
        type: "success",
        autoClose: 1500,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: error,
      autoClose: 1500,
    });
  }
};

export const ShowProfile = (id, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const path = `users/show_profile_picture/${id}`;
    const { response } = await Helper.get(path);
    const formattedResponse = GetProfile(response);
    dispatch(setProfile(formattedResponse));
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export const Profile = (id) => async (dispatch) => {
  try {
    const path = `background_verification/profile_completion/${id}`;
    const { response } = await Helper.get(path);
    dispatch(setProfileStatus(response?.profile_completion));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: error,
      autoClose: 1500,
    });
  }
};

export const empTimeData = (id) => async (dispatch) => {
  try {
    const path = `users/${id}/employee_times`;
    const { response } = await Helper.get(path);
    const formattedResponse = getEmpTime(response);
    dispatch(empTime(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: error,
      autoClose: 1500,
    });
  }
};

export const empTimeDataCreate = (formData, id,setSubmitshow) => async (dispatch) => {
  const formDatas = formatEmployeeTimeFormData(formData);
  try {
    const path = `users/${id}/employee_times/create`;
    const { status } = await Helper.post(formDatas,path,true);
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        type: "success",
        message: "Successfully created!",
        autoClose: 3000,
      });
      setSubmitshow(false);
      dispatch(empTimeData(id));
    }
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: error.message || "An error occurred",
      autoClose: 3000,
    });
  }
};

export const PersonalProgress = (id) => async (dispatch) => {
  try {
    const path = `background_verification/user_profile_percentag/${id}`;
    const { response } = await Helper.get(path);
    dispatch(setPersonalStatus(response?.profile_percentage));
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: error,
      autoClose: 1500,
    });
  }
};

export const EmployeeTimes = (id, formData) => async () => {
  try {
    const path = `${apiUrl.user}${id}/${apiUrl.employeeTimes}/create`;
    const { status } = await Helper.post(formData, path);
    if (status === 201 || status === 200) {
      ToastServices.showToast({
        message: "EmployeeTime Added",
        type: "success",
        autoClose: "2000",
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};
