import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import PropTypes from "prop-types";

const FilterColumn = ({
  setShowFilter,
  punchType,
  status,
  dataFilter,
  dataFilterValue,
  ColumnValue,
  columnFilter,
  handlePunchTypeChange,
  handleStatusChange,
  handleApply,
  handleRefresh,
  localSelectedPunchType,
  localSelectedStatus,
}) => {

  const [resourceOpen, setResourceOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);

  const { t } = useTranslation();

  const menuRef = useRef();

  const handleResourceToggle = () => setResourceOpen(!resourceOpen);
  const handleProjectsToggle = () => setProjectsOpen(!projectsOpen);

  const onCLose = () => {
    setShowFilter(false);
  };

  useOnClickOutside(menuRef, () => setShowFilter(false));

  return (
    <div
      ref={menuRef}
      className="w-96 p-4 bg-white border rounded-md shadow-md top-96  max-h-[60vh] overflow-y-auto custom_scroll"
    >
      <div className="flex justify-between items-center ">
        <div className="text-[#031B59] text-xl font-bold ">{t("filters")}</div>
        <div onClick={onCLose} data-testid="onClose" className="text-xl">
          <IoClose />
        </div>
      </div>

      <div className="">
        <div className="flex flex-col gap-2 mt-4">
          <div
            className="border p-2 cursor-pointer flex justify-between items-center "
            data-testid="toggleResource"
            onClick={handleResourceToggle}
          >
            <div className="capitalize">{punchType}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {resourceOpen && (
            <div className="p-2 my-1 border">
              <div className="p-2 my-1 ">
                {dataFilter?.map((resource,item) => (
                  <div key={resource} className="flex items-center mb-2">
                    <input
                      id={item.id}
                      type="checkbox"
                      className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                      data-testid="handlePunch"
                      checked={localSelectedPunchType?.includes(resource.title)}
                      onChange={() => handlePunchTypeChange(resource.title)}
                    />
                    <label className="text-blue-950">{resource?.title}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div>
          <div
            className="border p-2 cursor-pointer flex justify-between items-center"
            onClick={handleProjectsToggle}
            data-testid="toggleProjects"
          >
            <div className="capitalize">{status}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {projectsOpen && (
            <div className="p-2 my-1 border ">
              <div className="p-2 my-1 ">
                {dataFilterValue?.map((project) => (
                  <div key={project} className="flex items-center mb-2 ">
                    <input
                      type="checkbox"
                      className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                      data-testid="statusChange"
                      checked={localSelectedStatus?.includes(project.title)}
                      onChange={() => handleStatusChange(project.title)}
                    />
                    <label className="text-blue-950">{project?.title}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-2">
        {columnFilter && ColumnValue && (
          <h1 className="font-semibold text-blue-950">{columnFilter}</h1>
        )}
        <div className="p-2 my-1 max-h-fit">
          {ColumnValue?.map((project) => (
            <div key={project} className="flex items-center mb-2 ">
              <input
                type="checkbox"
                className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                checked={project.isChecked}
                disabled={project.disable}
              />
              <label className="text-blue-950">{project?.title}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button
          onClick={handleRefresh}
          data-testid="refresh"
          className="px-4 py-2 bg-white border border-[#A1A1A1] rounded-md">
          <MdRefresh data-testid="refreshIcon"/>
        </button>
        <button
          onClick={handleApply}
          className="px-4 py-2 bg-white border border-[#031B59] text-[#031B59]
         rounded-md"
          data-testid="applyBtn"
        >
          {t("applyNow")}
        </button>
      </div>
    </div>
  );
};

export default FilterColumn;

FilterColumn.propTypes = {
  setShowFilter: PropTypes.bool,
  punchType: PropTypes.any,
  status: PropTypes.any,
  ColumnValue: PropTypes.any,
  dataFilter: PropTypes.any,
  dataFilterValue: PropTypes.any,
  columnFilter: PropTypes.string,
  handlePunchTypeChange: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleApply: PropTypes.func,
  handleRefresh: PropTypes.func,
  localSelectedPunchType: PropTypes.any,
  localSelectedStatus: PropTypes.any,
};
