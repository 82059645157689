import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { convertDateFormat } from "utils/date";
import { useDispatch } from "react-redux";
import { fetchAllLeaveType, handleAddLeaves } from "redux/appThunk/Admin/leave";
import { Svg18 } from "svgComponents/Svg";
import Popup from "component/common/Popup";
import PropTypes from "prop-types";
import { RxCross2 } from "react-icons/rx";
import { addLeaveSchema } from "./addAttendanceSchema";
import { BiCloudUpload } from "react-icons/bi";

export default function ApplyLeave({ setApplyLeavePop, tableData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [submitOnce] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const imgRef = useRef(null);
  const [image, setImage] = useState({ name: "", url: "" });

  const initialvalues = {
    id: "",
    fromDate: "",
    toDate: "",
    fromshift: "",
    toshift: "",
    numOfDays: "",
    reason: "",
    leaveType: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: addLeaveSchema,
    onSubmit: async (values) => {
      const data = {
        leave: {
          id_of_user: tableData,
          leave_type: values?.leaveType,
          from_date: values?.fromDate,
          to_date: values?.toDate,
          reason: values?.reason,
          consumed_leave: values?.numOfDays,
        },
      };
      addEmployessDialoBox();
      dispatch(handleAddLeaves(data, setApplyLeavePop));
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = Formik;

  const displayLeaveData = {
    idOfUser: tableData,
    fromDate: values?.fromDate,
    toDate: values?.toDate,
    consumedLeave: values?.numOfDays,
    Reason: values?.reason,
    fromShift: values?.fromshift,
    toShift: values?.toshift,
    leaveType: values?.leaveType,
  };

  function calculateNumberOfDays(fromDate, toDate, fromShift, toShift) {
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const startshift = fromShift;
    const endshift = toShift;
    let daysDifference = Math.floor((endDate - startDate) / millisecondsInDay);

    if (startshift === "FirstShift" && endshift === "FirstShift") {
      daysDifference += 0.5;
    } else if (startshift === "FirstShift" && endshift === "SecondShift") {
      daysDifference += 1;
    } else if (startshift === "SecondShift" && endshift === "FirstShift") {
      daysDifference += 0;
    } else if (startshift === "SecondShift" && endshift === "SecondShift") {
      daysDifference += 0.5;
    }

    setFieldValue("numOfDays", daysDifference ? daysDifference : "");

    return daysDifference;
  }

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showModal]);

  useEffect(() => {
    if (values?.fromDate && values?.toDate) {
      calculateNumberOfDays(
        values?.fromDate,
        values?.toDate,
        values?.fromshift,
        values?.toshift
      );
    }
  }, [values?.fromDate, values?.toDate.fromshift, values?.toshift]);

  const enterClick = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      Formik.handleSubmit();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setApplyLeavePop(false);
    }
  };

  const handleDateChange = (e) => {
    setFieldValue(`${e.target.name}`, convertDateFormat(e.target.value));
  };

  useEffect(() => {
    dispatch(fetchAllLeaveType());
  }, []);

  const addEmployessDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  const handleClearImage = () => {
    setImage({ name: "", url: "" });
  };

  const handleImageClick = () => {
    imgRef.current.click();
  };

  const handleImageSelect = (e) => {
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    setImage({ url: fileUrl, name: e.target.files[0].name });
  };

  const handleRedirectToImg = () => {
    window.open(image.url, "_blank");
  };

  const leaveTypes = [
    {tilte: 'Select'},
    {tilte: 'paid'},
    {tilte: 'unpaid'},
    {tilte: 'casual'},
  ];

  return (
    <div
      className="w-full h-full flex items-center
    justify-end fixed top-0 left-0 z-50 bg-[rgba(3,27,89,.2)]  "
    >
      <div
        className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full h-16 bg-white flex justify-between">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center">
              <h2 className="font-extrabold text-xl text-[#031B59]">
                {t("Applyleave")}
              </h2>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[2.625rem]  h-[2.688rem] flex text-xl justify-center items-center rounded-full"
              data-testid="applyLeavePop"
              onClick={() => setApplyLeavePop(false)}
            >
              <RxCross2 />
            </button>
          </div>
          {showPopUp && (
            <Popup
              popupBox={addEmployessDialoBox}
              title={t("addLeave")}
              handleSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col border gap-2 capitalize">
                {displayLeaveData &&
                  Object.keys(displayLeaveData)?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center gap-4 p-2 "
                      >
                        <p className="basis-1/4 w-full">
                          {val} <span>:</span>
                        </p>
                        <p>{displayLeaveData[`${val}`]}</p>
                      </div>
                    );
                  })}
              </div>
            </Popup>
          )}
        </div>
        <div className="flex flex-col gap-[28rem]">
          <div>
            <form
              className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4"
              autoComplete="off"
            >
              <div className="relative flex flex-col">
                <label htmlFor="fromDate" className="text-[#313135]">
                  {t("fromDate")}
                </label>
                <div className="flex border p-2 rounded-lg w-[100%]">
                  <input
                    className="w-[90%] mr-3 bg-inherit"
                    type="text"
                    placeholder="DD MM YYYY"
                    value={convertDateFormat(values?.fromDate)}
                    disabled
                    data-testid="date"
                  />
                  <input
                    className="w-5 rounded text-[#191919] outline-none ml-2"
                    type="date"
                    name="fromDate"
                    id="fromDate"
                    onChange={handleDateChange}
                    min={new Date().toISOString()?.split("T")[0]}
                    onBlur={handleBlur}
                    data-testid="formdate"
                  />
                </div>
                {errors.fromDate && touched.fromDate ? (
                  <p className="text-[red]">{errors.fromDate}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="shift" className="text-[#313135]">
                  {t("shift")}
                </label>
                <select
                  type="text"
                  name="fromshift"
                  id="shift"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded-[0.5rem] bg-white appearance-none"
                  value={values.fromshift}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                  data-testid="shiftSelect"
                >
                  <option value="">Select</option>
                  <option value="FirstShift">{t("firstShift")}</option>
                  <option value="SecondShift">{t("secondShift")}</option>
                </select>
                <div className="absolute inset-y-0 right-0 h-fit  mt-9 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
                {errors.fromshift && touched.fromshift ? (
                  <p className="text-[red]">{errors.fromshift}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="toDate" className="text-[#313135]">
                  {t("toDate")}
                </label>
                <div className="flex border p-2 rounded-lg w-[100%]">
                  <input
                    className="w-[90%] mr-3 bg-inherit"
                    type="text"
                    placeholder="DD MM YYYY"
                    value={convertDateFormat(values?.toDate)}
                    disabled
                  />
                  <input
                    className="m-0 w-5 rounded text-[#191919] outline-none ml-2"
                    type="date"
                    name="toDate"
                    id="toDate"
                    onChange={handleDateChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => enterClick(e)}
                    data-testid="todate"
                    min={new Date().toISOString()?.split("T")[0]}
                  />
                </div>
                {errors.toDate && touched.toDate ? (
                  <p className="text-[red]">{errors.toDate}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="shift" className="text-[#313135]">
                  {t("shift")}
                </label>
                <select
                  type="text"
                  name="toshift"
                  id="shift"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded-[0.5rem] bg-white appearance-none"
                  value={values.toshift}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                >
                  <option value="">Select</option>
                  <option value="FirstShift">{t("firstShift")}</option>
                  <option value="SecondShift">{t("secondShift")}</option>
                </select>
                <div className="absolute inset-y-0 right-0 h-fit mt-9 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
                {errors.toshift && touched.toshift ? (
                  <p className="text-[red]">{errors.toshift}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="leaveType" className="text-[#313135]">
                  {t("leaveType")}
                </label>
                <select
                  type="text"
                  name="leaveType"
                  id="leaveType"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded-[0.5rem] bg-white appearance-none capitalize"
                  value={values.leaveType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                >
                  {leaveTypes.map((data) => (
                    <option
                      key={data.id}
                      value={data.id}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    >
                      {data.tilte}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 h-fit mt-9 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
                {errors.leaveType && touched.leaveType ? (
                  <p className="text-[red]">{errors.leaveType}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="reason" className="text-[#313135]">
                  {t("NumberOfDays")}
                </label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                  value={displayLeaveData?.["consumedLeave"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                  disabled
                  data-testid="consumedLeave"
                />
                {errors.reason && touched.reason ? (
                  <p className="text-[red]">{errors.numOfDays}</p>
                ) : null}
              </div>
              <div className="relative flex flex-col">
                <label htmlFor="reason" className="text-[#313135]">
                  {t("reason")}
                </label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  placeholder="Writing a reason here"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 text-[#191919]"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => enterClick(e)}
                  data-testid="reason"
                />
                {errors.reason && touched.reason ? (
                  <p className="text-[red]">{errors.reason}</p>
                ) : null}
              </div>
            </form>
            <div>
              <div className="mt-3 text-[#313135] font-medium">
                {t("upload_image")}
              </div>
              <div className="border w-[685px] h-[190px] mt-1">
                <div className="flex justify-center items-center h-[140px]">
                  <label
                    htmlFor="status_image"
                    className="border rounded-md w-[600px] h-[100px] flex items-center justify-center flex-col cursor-pointer"
                  >
                    <BiCloudUpload className="h-[30px] w-[30px] text-[#A1A1A1] mb-2" />
                    <span>
                      {t("drag_and_drop")}{" "}
                      <b className="text-[#031B59]">{t("browse_file")}</b>
                    </span>
                    <input
                      type="file"
                      id="status_image"
                      multiple
                      className="hidden"
                      ref={imgRef}
                      onClick={handleImageClick}
                      onChange={handleImageSelect}
                      accept=".pdf,.jpg,.png"
                      data-testid="fileInput"
                    />
                  </label>
                </div>
                <div className="flex justify-center items-center mt-[-1rem]">
                  <div className="border rounded-md w-[600px] h-8 flex justify-between items-center px-2">
                    <div className="cursor-pointer">
                      {image ? (
                        <span
                          data-testid="redirect"
                          onClick={handleRedirectToImg}
                          className="truncate"
                        >
                          {image.name}
                        </span>
                      ) : (
                        <span>{t(" choose_image")}</span>
                      )}
                    </div>
                    <RxCross2
                      className="cursor-pointer"
                      onClick={handleClearImage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-end mt-[-18rem] justify-end space-x-4">
              <button
                className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
                onClick={() => setApplyLeavePop(false)}
                data-testid="applyLeave"
              >
                {t("cancel")}
              </button>
              <button
                className="h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem]
                  p-2 bg-[#23275E] text-white rounded-full"
                type="submit"
                disabled={submitOnce}
                onClick={addEmployessDialoBox}
                data-testid="applyBtn"
              >
                {t("apply")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ApplyLeave.propTypes = {
  setApplyLeavePop: PropTypes.any,
  tableData: PropTypes.any,
};
