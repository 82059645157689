/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import AddLeave from "./AddLeaveData";
import Search from "component/admin/search_comp/Search";
import { handleLeaveSearchData, handleStatusChange, handleTeamLeaveAdmin } from "redux/appThunk/Admin/leave";
import { useDispatch, useSelector } from "react-redux";
import { AddButton } from "component/common/accessControlUi/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTable from "component/common/table/CustomTable";
import ProfileAvtar from "component/common/ProfileAvtar";
import { FaEye } from "react-icons/fa";
import LeaveFilterColumn from "./LeaveFilterColumn";
import { IoFilter } from "react-icons/io5";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";
import useDebounce from "hooks/useDebounce";
import Paginate from "component/admin/Employee/Paginate";
import { alignValues } from "./column";
import StatusPopup from "./StatusPopup";
import MyLeavepopup from "./MyLeavepopup";
import Teamleavestable from "./Teamleavestable";

export default function TeamLeaves({activeTab}) {
  const columns = [
    {
      title: "Employee Name",
      field: "name",
      align: "left",
      className: "pl-2 w-[650px]",
      render: (value, job) => {
        return (
          <div className="flex">
            <div className="w-12 h-12">
              {job?.employeePicture ? (
                <img
                  className="w-full h-full object-cover flex items-center border-2 border-white rounded-full "
                  src={job?.employeePicture}
                  alt="Profile Avatar"
                />
              ) : (
                <ProfileAvtar
                  height="3rem"
                  width="3rem"
                  name={job?.name ? job?.name : null}
                />
              )}
            </div>
            <div className="pl-2 text-left">
              <div>{job?.name}</div>
              <div>{job?.empCode}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Designation",
      field: "designation",
      align: "center",
      className: "",
    },
  ];

  const dataFilter = [
    { id: 1, title: "Intern" },
    { id: 2, title: "Front-end Developer" },
    { id: 3, title: "Back-end Developer" },
    { id: 4, title: "Business Development Executive" },
  ];

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const searchName = useDebounce(searchTerm, 800);
  const [currentPage, setCurrentPage] = useState("1");
  const [addLeavePop, setAddLeavePop] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filter, setFilter] = useState(false);
  const { adminTeamLeave, isLoading} = useSelector((state) => state.leaveReducer);
  const { t } = useTranslation();
  const { userPermission } =
    GetPermission(mappedPermissionObj?.Leave) || {};
  const [checkedResources, setCheckedResources] = useState(new Set());
  const [checkedDepartment, setCheckedDepartment] = useState(new Set());
  const [openPopup, setOpenPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupBtn, setPopupBtn] = useState("");
  const [state, setState] = useState({
    datas: null,
    userIdAction: null,
    popupData: null,
    selectedIds: "",
    dropdown: {
      isOpen: false,
      selectedId: null,
    },
  });
  useEffect(() => {
    dispatch(handleTeamLeaveAdmin("", searchTerm));
  }, [dispatch, searchName]);

  const handleFilterApply = () => {
    dispatch(handleTeamLeaveAdmin([...checkedDepartment]));
    setFilter(false);
  };

  const handleResetFilters = () => {
    setCheckedResources([]);
    setCheckedDepartment(new Set());
    dispatch(handleTeamLeaveAdmin());
    setFilter(false);
  };

  const handleOpen = (data) => {
    setOpenPopup(true);
    if (state.dropdown?.isOpen && state.dropdown?.selectedId === data?.id) {
      setState({
        dropdown: {
          ...state,
          isOpen: false,
          selectedId: null,
        },
      });
    } else {
      setState({
        ...state,
        popupData: data,
        dropdown: {
          isOpen: true,
          selectedId: data?.id,
        },
      });
    }
  };

  const handleViewDetails = (data) => {
    setState({
      datas: data,
      userIdAction: data?.userId,
    });
    setShowDetails(true);
    dispatch(handleLeaveSearchData({ userId: data?.userId }));
  };

  const handleOpenDropDown = (id) => {
    setState({
      selectedIds: id,
    });
  };

  const handleOptionClick = (option, leaves) => {
    dispatch(handleStatusChange(leaves, state.selectedIds, option));
  };

  const tableColumn = [
    {
      title: "Status ",
      field: "status",
      align: alignValues.left,
      render: (index, data) => {
        return (
          <>
            <div className="items-start">
              <StatusPopup
                key={index}
                leave={data}
                handleOpenDropDown={handleOpenDropDown}
                handleSelect={handleOptionClick}
                showDropDown={data?.id === state.selectedIds}
                popupTitle={popupTitle}
                setPopupTitle={setPopupTitle}
                popupBtn={popupBtn}
                setPopupBtn={setPopupBtn}
                activeTab={activeTab}
                data-testId='status-popup'
              />
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index, data) => {
        return (
          <>
            <div className="items-start">
              <div onClick={() => handleOpen(data)}>
                <FaEye/>
              </div>
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  return (
    <div className="w-full h-[80vh] overflow-hidden">
      {openPopup && (
        <MyLeavepopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          datas={state.popupData}
          data-testId="openPopup"
        />
      )}
      <div
        className="pl-1 pr-3 overflow-auto h-full"
        style={{ height: "calc(100vh - 64px)" }}
      >
        <div className="w-[100%] h-[85%] bg-white space-y-4 flex flex-col pb-1 rounded-xl  mt-4">
          {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}

          {!showDetails ? (
            <div className="p-5">
              <div className="w-full h-16 bg-white flex justify-between mt-[-15px]">
                <div className="flex justify-center items-center">
                  <h2 className="font-extrabold text-xl text-[#031B59]">
                    {t("details")}
                  </h2>
                </div>
                <div className="flex items-center justify-center lg:space-x-4 md:space-x-2">
                  <Search
                    searchItem={searchTerm}
                    setSearchItem={setSearchTerm}
                  />
                  <button
                    className="w-[2.688rem] h-[2.688rem] relative flex items-center
                     justify-center border-[1.5px] border-[#E2E8F0] rounded-full"
                    onClick={() => setFilterVisible(!filterVisible)}
                  >
                    <IoFilter
                      className="w-6 h-6 stroke-[#031B59]"
                      onClick={() => {
                        setFilter(!filter);
                      }}
                    />
                  </button>
                  {filter && (
                    <div className="absolute top-[9rem] right-48 z-50">
                      <LeaveFilterColumn
                        showFilter={filter}
                        checkedDepartment={checkedDepartment}
                        setCheckedDepartment={setCheckedDepartment}
                        setFilter={setFilter}
                        setShowFilter={setFilter}
                        filterTitleFirst={"Designation"}
                        dataFilter={dataFilter}
                        handleFilterApply={handleFilterApply}
                        handleResetFilters={handleResetFilters}
                        checkedResources={checkedResources}
                        setCheckedResources={setCheckedResources}
                      />
                    </div>
                  )}
                  {userPermission?.can_create_team_leave && (
                    <AddButton
                      title={t("Apply Leave")}
                      onClick={() => setAddLeavePop(true)}
                    />
                  )}
                </div>
              </div>

              <CustomTable
                columns={columns}
                data={adminTeamLeave?.data}
                renderAction={(value) => (
                  <FaEye onClick={() => handleViewDetails(value)} />
                )}
              />
              <div className="w-full h-12 bg-[white] flex justify-between items-center">
                {!isLoading && (
                  <>
                    <div className="w-full flex flex-wrap justify-between items-center mb-4 ">
                      <div className="text-[#031B59] bg-white font-medium ">
                        {t("Showing")} {currentPage} {t("of")} {adminTeamLeave?.pageCount}
                      </div>
                      <Paginate
                        currentPage={currentPage}
                        initialPageCount={adminTeamLeave?.pageCount}
                        pageRangeDisplayed={5}
                        next=">"
                        previous="<"
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              <Teamleavestable
                tableColumn={tableColumn}
                setShowDetails={setShowDetails}
                state={state}
                datas={state.datas}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

TeamLeaves.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
  activeTab: PropTypes.func,
};
