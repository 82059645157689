/* eslint-disable react/no-unknown-property */
import PropTypes from "prop-types";
import React ,{ useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import swalService from "utils/SwalServices";
import { useTranslation } from "react-i18next";
import { bankDetailsSchema } from "redux/validator/Profile/bankInfo";
import {
  handleBankDetails,
  updateBankDetails
} from "redux/appThunk/Employee/profile";
import { awsURL } from "utils/Constants";
import { employeeTypeObj } from "component/common/enum";
import LoaderComp from "component/loader/LoaderComp";
import { formatBankDetail, formatBankDetailsForSubmission } from "redux/selector/Employee/profile";

export default function BankDetailForm({ editState, setEditState, bankId }) {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const bankDetails = useSelector((state) => state.bankReducer.bankData);

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [cheque, setCheque] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [reload, setReload] = useState(false);

  const initialValues = {
    accountName: bankDetails?.accountName || "",
    accountNumber: bankDetails?.accountNumber || "",
    ifsc: bankDetails?.bank_details?.ifsc || "",
    cancelCheckUrl: bankDetails?.cancelCheckUrl || "",
    swiftCode: bankDetails?.swiftCode || "",
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFileUrl(fileURL);
    }
  };

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: bankDetailsSchema,
    onSubmit: async (values) => {
      const formdata = formatBankDetailsForSubmission(values, cheque);
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this?",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel",
        })
        .then(async (result) => {
          if (result.value) {
            dispatch(
              updateBankDetails(bankId, formdata, setEditState, setEditState, setReload)
            );
          }
        });
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = Formik;

  useEffect(() => {
    const formattedBankDetails = formatBankDetail(bankDetails);
    Formik.setValues({
      accountName: formattedBankDetails.accountName,
      accountNumber: formattedBankDetails.accountNumber,
      ifsc: formattedBankDetails.ifsc,
      cancelCheckUrl: formattedBankDetails.cancelCheckUrl,
      swiftCode: formattedBankDetails.swiftCode,
    });
  }, [bankDetails]);

  const handleChequeInput = (event) => {
    event.preventDefault();
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      window.open(bankDetails?.cancelledCheque, "_blank");
    }
  };

  useEffect(() => {
    dispatch(handleBankDetails(bankId, userData?.id, setReload));
  }, []);

  const buttonClick = () => {
    inputRef.current.click();
  };

  const getMaskedAccountNumber = () => {
    const fullAccountNumber =
      values?.accountNumber !== undefined
        ? values.accountNumber
        : bankDetails?.accountNumber;

    if (fullAccountNumber && fullAccountNumber.length > 4) {
      const maskedPart =
        "*".repeat(fullAccountNumber.length - 4) + fullAccountNumber.slice(-4);

      return maskedPart;
    }

    return fullAccountNumber;
  };

  function handleChanges(e) {
    handleChange(e);
  }
  const handleClick = (e) => {
    setFieldValue(e.target.files);
    setCheque(e.target.files[0]);
    handleFileChange(e);
  };

  return (
    <div>
      <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
        <div className="flex flex-col">
          <label htmlFor="accName">{t("accName")}</label>
          <input
            type="text"
            name="accountName"
            id="accName"
            className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2"
            value={
              values?.accountName !== undefined
                ? values.accountName
                : bankDetails?.accountName
            }
            disabled={userData?.role === employeeTypeObj.employee || !editState}
            onChange={handleChanges}
            onBlur={handleBlur}
          />
          {errors.accountName && touched.accountName ? (
            <p className="text-[red]">{errors.accountName}</p>
          ) : null}
        </div>
        <div className="flex flex-col">
          <label htmlFor="accNumber">{t("accNumber")}</label>
          <input
            type="text"
            name="accountNumber"
            id="accNumber"
            className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2"
            value={getMaskedAccountNumber()}
            disabled={userData?.role === employeeTypeObj.employee || !editState}
            onChange={handleChanges}
            onBlur={handleBlur}
            onKeyPress={(e) => {
              const pattern = /[0-9]/;
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                e.preventDefault();
              }
            }}
          />
          {errors.accountNumber && touched.accountNumber ? (
            <p className="text-[red]">{errors.accountNumber}</p>
          ) : null}
        </div>
        <div className="flex flex-col">
          <label htmlFor="Ifsc">{t("Ifsc")}</label>
          <input
            type="text"
            name="ifsc"
            id="Ifsc"
            className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 "
            value={
              values.ifsc !== undefined
                ? values.ifsc
                : bankDetails?.ifsc
            }
            disabled={userData?.role === employeeTypeObj.employee || !editState}
            onChange={handleChanges}
            onBlur={handleBlur}
          />
          {errors.ifsc && touched.ifsc ? (
            <p className="text-[red]">{errors.ifsc}</p>
          ) : null}
        </div>
        <div className="relative flex flex-col ">
          <label htmlFor="cancelledCheque">{t("cancelledCheque")}</label>
          <div
            className="flex h-[2.625rem] w-full border border-[#E2E8F0] rounded items-center
                    justify-between p-2 mt-2"
            onClick={() => buttonClick(inputRef)}
          >
            <input
              type="file"
              name="cancelled_cheque"
              ref={inputRef}
              id="cancelledCheque"
              className="hidden"
              accept=".pdf,.jpg,.png,.jpeg,.svg,.WebP,.avif,.bmp"
              disabled={
                userData?.role === employeeTypeObj.employee || !editState
              }
              onChange={handleClick}
              onBlur={handleBlur}
            />
            <button onClick={handleChequeInput} className="text-sm">
              {cheque?.name !== undefined
                ? cheque?.name
                : bankDetails?.cancelCheckUrl}
            </button>
            <img
              className="absolute  right-[5px]"
              src={`${awsURL}/images/cloudUpload.png`}
              alt="cloud upload"
            />
          </div>
          {errors.cancelled_cheque && touched.cancelled_cheque ? (
            <p className="text-[red]">{errors.cancelled_cheque}</p>
          ) : null}
        </div>
        <div className="flex flex-col">
          <label htmlFor="swift_code">{t("swift_code")}</label>
          <input
            type="text"
            name="swiftCode"
            id="swift_code"
            className="mt-2 h-[2.625rem] w-full border border-[#E2E8F0] rounded p-2 "
            maxLength={20}
            value={
              values.swiftCode !== undefined
                ? values.swiftCode
                : bankDetails?.swiftCode
            }
            disabled={userData?.role === employeeTypeObj.employee || !editState}
            onChange={handleChanges}
            onBlur={handleBlur}
          />
          {errors.swiftCode && touched.swiftCode ? (
            <p className="text-[red]">{errors.swiftCode}</p>
          ) : null}
        </div>
      </form>
      {userData?.role === employeeTypeObj.admin && (
        <>
          {editState === 5 && (
            <>
              {reload ? (
                <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
                  <LoaderComp />
                </div>
              ) : (
                <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
                  <button
                    className="border border-[#031b59] p-2 w-20 rounded-3xl"
                    onClick={() => setEditState(false)}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

BankDetailForm.propTypes = {
  setEditState: PropTypes.func,
  editState: PropTypes.func,
  bankId: PropTypes.func,
};
