import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const ViewLeaveDetails = ({ setOpenPopup, datas }) => {
  const [selectedLeave, setSelectedLeave] = useState({});
  const { t } = useTranslation();

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSelectedLeave(null);
  };

  return (
    <>
      {selectedLeave && (
        <>
          <div className="w-full h-full flex items-center justify-end
        fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)]">
            <div className="max-w-[40%] h-full p-5 bg-white flex flex-col top-0
           space-y-2 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] min-w-[40%] overflow-auto">
              <div className="w-full flex items-center justify-between ">
                <h2 className="font-Roboto text-2xl text-[#031B59] font-extrabold"> {t("viewleave")}</h2>
                <IoClose onClick={handleClosePopup} className="fill-[#686868] w-6 h-6 cursor-pointer"aria-label="close-edit-popup" />
              </div>
              <hr className="my-4 mt-4 " />
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold"> {t("leaveDetails")}</h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="text-[#686868] text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("emp_id")}</label>
                  <input
                    type="text"
                    name="employeeid"
                    value={datas?.id || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] mx-2 text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("name")}</label>
                  <input
                    type="text"
                    name="employeename"
                    value={datas?.name || ''}
                    readOnly
                    className={`mt-1 ml-2.5 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("fromDate")}</label>
                  <input
                    type="text"
                    name="fromDate"
                    value={datas?.fromDate || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] mx-2 text-base font-medium
                font-['Roboto'] leading-snug">
                    {t("toDate")}</label>
                  <input
                    type="text"
                    name="toDate"
                    value={datas?.toDate || ''}
                    readOnly
                    className={`mt-1 ml-2.5 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] text-base font-medium
                 font-['Roboto'] leading-snug">
                    {t("numberofdays")}</label>
                  <input
                    type="text"
                    name="consumedleave"
                    value={datas?.consumedLeave || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] mx-2 text-base font-medium
                 font-['Roboto'] leading-snug">
                    {t("leaveType")}</label>
                  <input
                    type="text"
                    name="leaveType"
                    value={datas?.leaveType || ''}
                    readOnly
                    className={`mt-1 ml-2.5 p-2 text-base w-full capitalize border border-slate-200 rounded-[4px]`}
                  />
                </div>
                <div>
                  <label className="text-[#686868] text-base font-medium font-['Roboto'] leading-snug">
                    {t("reason")}</label>
                  <input
                    type="text"
                    name="reason"
                    value={datas?.reason || ''}
                    readOnly
                    className={`mt-1 p-2 text-base w-full border border-slate-200 rounded-[4px]`}
                  />
                </div>

              </div>
              <hr className="my-4 mt-4 " />
              <label className="text-[#686868] text-base font-medium
                font-['Roboto'] leading-snug px-5 py-5">
                {t("Image")}</label>
              {datas?.imagesUrl ? (
                <img src={datas?.imagesUrl} alt="Leave document"
                  className="mt-2 w-full h-40 object-cover p-2 border border-slate-200 rounded-[4px]" />
              ) : (
                <div className="flex items-center gap-1 mt-2">
                  <img className="w-[22.77px] h-6" src="https://via.placeholder.com/23x24" alt="Default" />
                  <div className="text-zinc-900 text-base font-normal font-['Roboto']"> {t("Image")}</div>
                </div>
              )}

            </div>
          </div>
        </>
      )}
    </>
  );
};

ViewLeaveDetails.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
  datas: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    consumedLeave: PropTypes.number.isRequired,
    leaveType: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    imagesUrl: PropTypes.string,
  }).isRequired,
};

export default ViewLeaveDetails;
