/* eslint-disable no-unused-vars */
import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  allusers,
  isLoading,
  setAllEmployeeData,
  setFiles,
  setPageCount,
  setSuccess,
  totalEmployeesNumber
} from "redux/actions/action";
import { FilterUsers } from "utils/Constants";
import { DeleteSelectEmployee } from "./jobOpening";
import { formatEmployeeListData } from "redux/selector/Admin/employee";

export const handleUploadBulkFile = (values) => async (dispatch) => {
  try {
    const path = apiUrl?.bulkUploadPost;
    const { status, response } = await Helper.post(values, path, true);
    dispatch(setFiles(true));
    if (status === 200 || status === 201) {
      ToastServices.showToast({
        message: "Data inserted successfully.",
        type: "success",
        autoClose: 3000,
      });
    } else if (status === 422) {
      ToastServices.showToast({
        message: "File already uploaded.",
        type: "warning",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      type: "error",
      message: "Error during fetch data!",
      autoClose: 3000,
    });
  }
};

export const fetchEmployeeData =
  (employeesType, currentPage, itemsPerPage,searchQuery) => async (dispatch) => {
    dispatch(isLoading(true));
    const getParams = FilterUsers.find(
      (users) => employeesType === users.header
    );
    const path = `users?user_type=${getParams?.key || "all"}&page=${
      currentPage}&per_page=${itemsPerPage
    }&query=${searchQuery || ""}`;
    try {
      const { response } = await Helper.get(path);
      const formattedResp = formatEmployeeListData(response?.users);
      if (response.message === "No records found") {
        dispatch(setAllEmployeeData([]));
        dispatch(isLoading(false));
      } else {
        dispatch(setAllEmployeeData(formattedResp));
        dispatch(setPageCount(response?.total_pages));
        dispatch(totalEmployeesNumber(response?.total_count));
        dispatch(isLoading(false));
      }
    } catch (error) {
      dispatch(isLoading(false));
      ToastServices.showToast({
        message: error,
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const addEmployeeDetail =
  (employeesData, addEmployessDialoBox, setSubmitOnce) => async (dispatch) => {
    try {
      setSubmitOnce(true);
      const path = apiUrl.sign;
      const { status } = await Helper.post(employeesData, path);
      setSubmitOnce(false);
      if (status === 200) {
        dispatch(setSuccess(true));
        addEmployessDialoBox();
        ToastServices.showToast({
          message: "Added New Employee !",
          type: "success",
          autoClose: 3000,
        });
      } else {
        ToastServices.showToast({
          message: "Failed to Add New Employee !",
          type: "error",
          autoClose: 3000,
        });
      }
      dispatch(fetchEmployeeData());
    } catch (error) {
      setSubmitOnce(false);
      dispatch(setSuccess(false));
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const handleFileDownload = () => async () => {
  try {
    const path = apiUrl?.SampleUploadDownload;
    const response = await Helper.getDownload(path, true);

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sample.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      ToastServices.showToast({
        message: "error",
        type: "error",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const reInvitationSent =
  (id, setOnSpotChange, onSpotChange, adminName) => async () => {
    // http://localhost:3000/api/re_invite?id=3&invited_by=navdeep
    const path = `${apiUrl?.reInvitation}id=${id}&invited_by=${adminName.full_name}`;
    const { status } = await Helper.post({}, path);
    try {
      if (status === 200 || status === 201) {
        ToastServices.showToast({ message: "Invite sent", type: "success" });
        setOnSpotChange(!onSpotChange);
      } else {
        ToastServices.showToast({ message: "Invite not sent", type: "error" });
      }
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const jobAddEmployee =
  (employeesData, job, setAgainData) => async (dispatch) => {
    try {
      const path = apiUrl.sign;
      const { response, status } = await Helper.post(employeesData, path);
      if (status === 200 || status === 201) {
        dispatch(setSuccess(true));
        dispatch(DeleteSelectEmployee(job?.id, setAgainData));
        ToastServices.showToast({
          message: "Added New Employee !",
          type: "success",
          autoClose: 3000,
        });
      } else {
        ToastServices.showToast({
          message: response?.message
            ? response?.message
            : "Failed to Add New Employee !",
          type: "error",
          autoClose: 3000,
        });
      }
    } catch (error) {
      dispatch(setSuccess(false));
      ToastServices.showToast({
        message: "Error!",
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const assignLead = (id, ids) => async (dispatch) => {
  const data = {};
  try {
    const path = `${apiUrl.assignLead}${id}?lead_id=${ids}`;
    const { response, status } = await Helper.post(data, path);
    if (status === 200 || status === 201) {
      dispatch(setSuccess(true));
      // dispatch(DeleteSelectEmployee(job?.id, setAgainData));
      ToastServices.showToast({
        message: "Assigned New Lead !",
        type: "success",
        autoClose: 3000,
      });
    } else {
      ToastServices.showToast({
        message: response?.message
          ? response?.message
          : "Failed to Assign New Lead !",
        type: "error",
        autoClose: 3000,
      });
    }
  } catch (error) {
    dispatch(setSuccess(false));
    ToastServices.showToast({
      message: "Error!",
      type: "error",
      autoClose: 3000,
    });
  }
};
