export const SET_PUNCH_IN_DATA = "SET_PUNCH_IN_DATA";

export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";

export const TIMER_DATA = "TIMER_DATA";

export const TOTAL_WORKING_DAYS_LEAVES = "TOTAL_WORKING_DAYS_LEAVES";

export const SET_SHOW_TOAST = "SET_SHOW_TOAST";

export const SET_MONTHLY_REVENUE = "SET_MONTHLY_REVENUE";

export const SET_OVERVIEW_REVENUE = "SET_OVERVIEW_REVENUE";

export const SET_MONTHLY_CLIENTS = "SET_MONTHLY_CLIENTS";

export const SET_MONTHLY_EMPLOYEES = "SET_MONTHLY_EMPLOYEES";

export const SET_TOTAL_EMPLOYEES = "SET_TOTAL_EMPLOYEES";

export const SET_MONTHLY_WORKINGHOURS = "SET_MONTHLY_WORKINGHOURS";

export const SET_TOTAL_AVG = "SET_TOTAL_AVG";

export const SET_ALL_LEAVE_TYPE = "SET_ALL_LEAVE_TYPE";

export const PAGE_COUNT = "PAGE_COUNT";

export const ALL_LEAVES = "ALL_LEAVES";

export const Organisation_LEAVES = "Organisation_LEAVES";

export const SET_SERVICE_AVAILABLE_DATA = "SET_SERVICE_AVAILABLE_DATA";

export const SET_YOUR_AVG = "SET_YOUR_AVG";

export const SET_YEARLY_WORKINGHOURS = "SET_YEARLY_WORKINGHOURS";

export const SET_OVERIVEW_REVIEW = "SET_OVERIVEW_REVIEW";

export const SET_NOTIFICATION = "SET_NOTIFICATION";

export const SET_INTERVIEW_ID = "SET_INTERVIEW_ID";

export const SET_CHATS = "SET_CHATS";

export const SET_ACTIVECHAT = "SET_ACTIVECHAT";

export const SET_USERNAME = "SET_USERNAME";

export const SET_GROUP_CHATS = "SET_GROUP_CHATS";

export const SET_MESSAGES = "SET_MESSAGES";

export const SET_SENDMESSAGES = "SET_SENDMESSAGES";

export const SET_BROADCASTEDMESSAGE = "SET_BROADCASTEDMESSAGE";

export const SET_USERLIST = "SET_USERLIST";

export const SET_DESIGNATION_DATA = "SET_DESIGNATION_DATA";

export const SET_MY_TEAMS_DATA = "SET_MY_TEAMS_DATA";

export const SET_MY_PROJECT_DATA = "SET_MY_PROJECT_DATA";

export const SET_MY_PROJECT_MEMBERS = "SET_MY_PROJECT_MEMBERS";

export const SET_MY_MEMBERS_DATA = "SET_MY_MEMBERS_DATA";

export const SET_MY_ORGANIZATION_DATA = "SET_MY_ORGANIZATION_DATA";

export const ALL_DESIGNATION_DATA = "ALL_DESIGNATION_DATA";

export const SET_SHOW_GROUP_LIST = "SET_SHOW_GROUP_LIST";

export const SET_INTERVIEW_DATA = "SET_INTERVIEW_DATA";

export const SET_INDIVIDUAL_INTERVIEW_DATA = "SET_INDIVIDUAL_INTERVIEW_DATA";

export const SET_ALL_FEEDBACK_DATA = "SET_ALL_FEEDBACK_DATA";

export const SET_ATTENDANCE = "SET_ATTENDANCE";

export const SET_TRACKER = "SET_TRACKER";

export const UPDATE_LEAVE_STATUS = "UPDATE_LEAVE_STATUS";

export const SET_INVOICE_DATA = "SET_INVOICE_DATA";

export const SET_NOTIFICATION_UPDATE = "SET_NOTIFICATION_UPDATE";

export const SET_SEARCH_RESULT_FOR_CHAT = "SET_SEARCH_RESULT_FOR_CHAT";

export const SET_MY_FEEDBACK_DATA = "SET_MY_FEEDBACK_DATA";

export const SET_JOB_OPENING_DATA = "SET_JOB_OPENING_DATA";

export const SET_JOB_APPLICATION_DATA = "SET_JOB_APPLICATION_DATA";

export const INDIVIDUAL_JOB_OEPNING = "INDIVIDUAL_JOB_OEPNING";

export const UPDATE_JOB_OPENING = "UPDATE_JOB_OPENING";

export const SET_EXPENSE_DATA = " SET_EXPENSE_DATA";

export const SET_ASSET_DOWNLOAD = "SET_ASSET_DOWNLOAD";

export const GET_COMPANY_DATA = "GET_COMPANY_DATA";

export const ALL_COMPANY_TAX_DETAILS = "ALL_COMPANY_TAX_DETAILS";

export const SINGLE_COMPANY_DATA = "SINGLE_COMPANY_DATA";

export const SINGLE_REVIEW = "SINGLE_REVIEW";

export const SINGLE_EXPENSE = "SINGLE_EXPENSE";

export const SINGLE_TDS_DATA = "SINGLE_TDS_DATA";

export const SINGLE_PF_DATA = "SINGLE_PF_DATA";

export const SINGLE_ASSET_DATA = "SINGLE_ASSET_DATA";

export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";

export const EDIT_ASSET_SUCCESS = "EDIT_ASSET_SUCCESS";

export const ADD_TDS_SUCCESS = "ADD_TDS_SUCCESS";

export const ADD_EXPENSES_SUCCESS = "ADD_EXPENSES_SUCCESS";

export const SET_FILES = "SET_FILES";

export const SET_ROOMID = "SET_ROOMID";

export const SET_DEPARTMENT = "SET_DEPARTMENT";

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";

export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";

export const SET_DEPARTMENT_DATA = "SET_DEPARTMENT_DATA";

export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";

export const CREATE_DESIGNATION = "CREATE_DESIGNATION";

export const SHOW_DESIGNATION = "SHOW_DESIGNATION";

export const SET_CHAT_USERS_STATUS = "SET_CHAT_USERS_STATUS";

export const STORE_FILE_DATA = "STORE_FILE_DATA";

export const SET_MY_STATUS = "SET_MY_STATUS";

export const SET_STATUS_BY_NAME_AND_DATE = "SET_STATUS_BY_NAME_AND_DATE";

export const SET_PROJECT_LIST = "SET_PROJECT_LIST";

export const SET_UPDATE_CHATS = "SET_UPDATE_CHATS";

export const SET_INVOICE_BYID = "SET_INVOICE_BYID";

export const SEARCH_LEAVE = "SEARCH_LEAVE";

export const SET_SHOW_PROFILE = "SET_SHOW_PROFILE";

export const SET_EMPLOYEE_NUMBER = "SET_EMPLOYEE_NUMBER";

export const SET_CLIENTS_NUMBER = "SET_CLIENTS_NUMBER";

export const SET_PROJECT_ID = "SET_PROJECT_ID";

export const SET_RESOURCE_ID = "SET_RESOURCE_ID";

export const PROJECT_BY_ID = "PROJECT_BY_ID";

export const ALL_ROLES = "ALL_ROLES";

export const ALL_PERMISSIONS = "ALL_PERMISSIONS";

export const SET_PERMISSIONS = "SET_PERMISSIONS";

export const SET_USER_PROJECTS = "SET_USER_PROJECTS";

export const COUNTRY_NAME = "COUNTRY_NAME";

export const STATE_NAME = "STATE_NAME";

export const CITY_NAME = "CITY_NAME";

export const PROFILE_ID = "PROFILE_ID";

export const PROFILE_PICTURE = "PROFILE_PICTURE";

export const STATUS = "STATUS";

export const EMPLOYEE_TIME = "EMPLOYEE_TIME";

export const APISTATUS = "SET_API_STATUS";

export const SET_EMPEYE = "SET_EMPEYE";

export const SET_BIRTHDAYS = "SET_BIRTHDAYS";

export const SET_REQUESTS = "SET_REQUESTS";

export const SET_NEW_HIRES = "SET_NEW_HIRES";

export const SET_WORK_ANIVERSARY = "SET_WORK_ANIVERSARY";

export const COMPANY_PROFILE = "COMPANY_PROFILE";

export const SET_DASHBOARD_ALLATEND = "SET_DASHBOARD_ALLATEND";

export const SET_LEAVE_BALANCE = "SET_LEAVE_BALANCE";

export const SET_ONGOING_DATA = "SET_ONGOING_DATA";

export const SET_APPLICATION = "SET_APPLICATION";

export const SET_CREATE_FEED_GET = "SET_CREATE_FEED_GET";

export const SET_DELETE_FEED = "SET_DELETE_FEED";

export const SET_WORKING_DAYS = "SET_WORKING_DAYS";

export const SET_PROBATION = "SET_PROBATION";

export const SET_PERSONAL_STATUS = "SET_PERSONAL_STATUS";

export const SET_TEAMATTEND_POPUP = "SET_TEAMATTEND_POPUP";

export const SET_WFH_REQUEST = "SET_WFH_REQUEST";

export const SET_ASSET_INFO = "SET_ASSET_INFO";

export const SET_ALLAPPLICANTS = "SET_ALLAPPLICANTS";

export const UPDATE_WFH_STATUS = "UPDATE_WFH_STATUS";

export const SET_ASSIGN_LEAVE = "SET_ASSIGN_LEAVE";

export const SET_LEAVE_DETAILS = "SET_LEAVE_DETAILS";

export const SET_ROLE_DET = "SET_ROLE_DET";

export const SET_VIEW_EVENT = "SET_VIEW_EVENT";

export const SET_GET_FEEDBACK = "SET_GET_FEEDBACK";

export const SET_CLIENTS_TIMESHEET = "SET_CLIENTS_TIMESHEET";

export const SET_CLIENT_NAME = "SET_CLIENT_NAME";

export const SET_HIERARCHY_DATA = "SET_HIERARCHY_DATA";

export const SET_CHECKIN_DATA = "SET_CHECKIN_DATA";

export const SET_CHECKBOX = "SET_CHECKBOX";

export const SET_SALARY_SLIP = "SET_SALARY_SLIP";

export const SET_ONBOARDING = "SET_ONBOARDING";

export const SET_FILTER_DATA = "SET_FILTER_DATA";

export const SET_EMPLOYEE_DATA = "SET_EMPLOYEE_DATA";

export const SET_EMPLOYEE_LEAD = "SET_EMPLOYEE_LEAD";

export const SET_ALL_EMPLOYEE_DATA = "SET_ALL_EMPLOYEE_DATA";

export const GET_LEAVES_DATA = "GET_LEAVES_DATA";

export const GET_PROJECT = "GET_PROJECT";

export const GET_TEAM_PROJECT = "GET_TEAM_PROJECT";
