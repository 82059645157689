const initialstate = {
  employeeData: [],
  assetsInfo: [],
  allUsers: [],
  employeetime: [],
};

export const employeeReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "ALL_EMPLOYEE_LIST":
    return {
      ...state,
      employeeData: payload,
    };

  case "SET_ASSET_INFO":
    return {
      ...state,
      assetsInfo: payload,
    };

  case "ALL_USERS":
    return {
      ...state,
      allUsers: payload,
    };

  case "EMPLOYEE_TIME":
    return {
      ...state,
      employeetime: payload,
    };
  default:
    return state;
  }
};
