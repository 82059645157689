import { t } from "i18next";
import React, { useState , useEffect} from "react";
import PropTypes from "prop-types";
import ToastServices from "ToastServices";

const Consent = ({ setCurrentStep, changetab }) => {

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const savedChecked = localStorage.getItem('consentChecked');
    if (savedChecked === 'true') {
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('consentChecked', checked);
  }, [checked]);

  return (
    <div className="p-6 overflow-y-auto custom_scroll h-[350px]">
      <form>
        <div className="mt-6">
          <p>{t("consentPara")}</p>
        </div>

        <div className="p-2 mt-6 flex gap-8">
          <input
            type="checkbox"
            name="isChecked"
            checked={checked}
            onChange={() => setChecked(!checked)}
            className="checkbox"
          />
          <label className="checkbox-label font-bold">
            {t("consentTickBox")}
          </label>
        </div>

        <div className="flex justify-end static">
          <button
            className="mt-10 h-[2.813rem] w-[7.625rem] p-2
              text-gray-500"
            onClick={() => setCurrentStep(4)}
          >
            {t("back")}
          </button>
          <button
            type="button"
            disabled={!checked}
            className={`mt-10 h-[2.813rem] w-[7.625rem] p-2 rounded-full 
              ${checked ? 'bg-[#23275E] text-white' : 'bg-gray-400 text-gray-700'}`}
            onClick={() => {
              if (checked) {
                ToastServices.showToast({
                  type: "success",
                  message: " All Documents Has been submitted !",
                  autoClose: 1500,
                });
              }
              changetab(0);
            }}
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Consent;

Consent.propTypes = {
  setCurrentStep: PropTypes.number,
  identiityData: PropTypes.object,
  changetab: PropTypes.any,
};
