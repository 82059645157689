import {
  SET_ALL_LEAVE_TYPE,
  SET_MONTHLY_CLIENTS,
  SET_MONTHLY_EMPLOYEES,
  SET_MONTHLY_REVENUE,
  SET_MONTHLY_WORKINGHOURS,
  SET_OVERIVEW_REVIEW,
  SET_OVERVIEW_REVENUE,
  SET_PUNCH_IN_DATA,
  SET_SEARCH_RESULT,
  SET_SHOW_TOAST,
  SET_TOTAL_AVG,
  SET_TOTAL_EMPLOYEES,
  SET_YEARLY_WORKINGHOURS,
  SET_YOUR_AVG,
  TIMER_DATA,
  TOTAL_WORKING_DAYS_LEAVES,
  ALL_LEAVES,
  PAGE_COUNT,
  SET_SERVICE_AVAILABLE_DATA,
  SET_USERNAME,
  SET_NOTIFICATION,
  SET_CHATS,
  SET_GROUP_CHATS,
  SET_ACTIVECHAT,
  SET_MESSAGES,
  SET_SENDMESSAGES,
  SET_BROADCASTEDMESSAGE,
  SET_USERLIST,
  SET_INDIVIDUAL_INTERVIEW_DATA,
  SET_INTERVIEW_DATA,
  SET_ALL_FEEDBACK_DATA,
  SET_INTERVIEW_ID,
  SET_ATTENDANCE,
  SET_SHOW_GROUP_LIST,
  SET_NOTIFICATION_UPDATE,
  SET_SEARCH_RESULT_FOR_CHAT,
  SET_MY_FEEDBACK_DATA,
  SET_JOB_OPENING_DATA,
  UPDATE_JOB_OPENING,
  SET_EXPENSE_DATA,
  SINGLE_COMPANY_DATA,
  SINGLE_TDS_DATA,
  SINGLE_PF_DATA,
  SINGLE_ASSET_DATA,
  SET_FILES,
  SET_ROOMID,
  SET_CHAT_USERS_STATUS,
  STORE_FILE_DATA,
  SET_MY_STATUS,
  SET_PROJECT_LIST,
  SET_UPDATE_CHATS,
  SET_INVOICE_BYID,
  SEARCH_LEAVE,
  SET_SHOW_PROFILE,
  SET_EMPLOYEE_NUMBER,
  SET_CLIENTS_NUMBER,
  SET_PROJECT_ID,
  PROJECT_BY_ID,
  ALL_ROLES,
  SET_RESOURCE_ID,
  ALL_PERMISSIONS,
  SET_PERMISSIONS,
  SET_USER_PROJECTS,
  COUNTRY_NAME,
  STATE_NAME,
  CITY_NAME,
  PROFILE_ID,
  PROFILE_PICTURE,
  STATUS,
  APISTATUS,
  EMPLOYEE_TIME,
  SET_EMPEYE,
  SET_BIRTHDAYS,
  SET_REQUESTS,
  SET_NEW_HIRES,
  SET_WORK_ANIVERSARY,
  COMPANY_PROFILE,
  SET_DASHBOARD_ALLATEND,
  ALL_DESIGNATION_DATA,
  SET_LEAVE_BALANCE,
  SET_ONGOING_DATA,
  SET_APPLICATION,
  SET_CREATE_FEED_GET,
  SET_DELETE_FEED,
  SET_WORKING_DAYS,
  SET_PROBATION,
  SET_PERSONAL_STATUS,
  SET_JOB_APPLICATION_DATA,
  SET_TEAMATTEND_POPUP,
  SET_WFH_REQUEST,
  SET_ASSIGN_LEAVE,
  SET_LEAVE_DETAILS,
  SET_ROLE_DET,
  SET_VIEW_EVENT,
  SET_HIERARCHY_DATA,
  SET_CLIENT_NAME,
  SET_CHECKIN_DATA,
  SET_ALLAPPLICANTS,
  SET_CHECKBOX,
  SET_GET_FEEDBACK,
  SET_MY_PROJECT_MEMBERS,
  SET_ONBOARDING,
  SET_FILTER_DATA,
  SET_DEPARTMENT,
  DELETE_DEPARTMENT,
  CREATE_DEPARTMENT,
  SET_EMPLOYEE_DATA,
  CREATE_DESIGNATION,
  SHOW_DESIGNATION,
  UPDATE_DEPARTMENT,
  SET_EMPLOYEE_LEAD,
  SET_ALL_EMPLOYEE_DATA,
  SET_DEPARTMENT_DATA,
  Organisation_LEAVES,
  SET_ASSET_INFO,
  GET_LEAVES_DATA,
  SET_TRACKER

} from "./types";

export const setleave = (payload) => {
  return {
    type: "SET_LEAVE",
    payload,
  };
};

export const setDepartment = (payload) => {
  return {
    type: SET_DEPARTMENT,
    payload,
  };
};

export const deleteDepartment = (payload) => {
  return {
    type: DELETE_DEPARTMENT,
    payload,
  };
};

export const updateDepartment = (payload) => {
  return {
    type: UPDATE_DEPARTMENT,
    payload,
  };
};

export const createDepartment = (payload) => {
  return {
    type: CREATE_DEPARTMENT,
    payload,
  };
};

export const createDesignation = (payload) => {
  return {
    type: CREATE_DESIGNATION,
    payload,
  };
};

export const fetchDesignation = (payload) => {
  return {
    type: SHOW_DESIGNATION,
    payload,
  };
};

export const setDepartmentSearchData = (payload) => {
  return {
    type: SET_DEPARTMENT_DATA,
    payload,
  };
};

export const setCheckBox = (payload) => {
  return {
    type: SET_CHECKBOX,
    payload,
  };
};

export const dashboardLeaves = (payload) => {
  return {
    type: "DASHBOARD_LEAVES",
    payload,
  };
};

export const getdynamicpage = (payload) => {
  return {
    type: "SET_DY_PAGE",
    payload,
  };
};

export const setIndividualleave = (payload) => {
  return {
    type: "INDIVIDUAL_LEAVE",
    payload,
  };
};

export const timerData = (payload) => {
  return {
    type: TIMER_DATA,
    payload,
  };
};

export const textReject = (payload) => {
  return {
    type: "TEXT_REJECT",
    payload,
  };
};

export const permissionAction = (payload) => {
  return {
    type: "PERMISSION_ACTION",
    payload,
  };
};

export const deleteleave = (payload) => {
  return {
    type: "DELETE_LEAVE",
    payload,
  };
};

export const setgetfeeback = (payload) => {
  return {
    type: SET_GET_FEEDBACK,
    payload,
  };
};

export const changeMargin = (payload) => {
  return {
    type: "UPDATE_MARGIN",
    payload,
  };
};

export const indiviualstatus = (payload) => {
  return {
    type: "INDIVIUAL_STATUS",
    payload,
  };
};

export const deletestatus = (payload) => {
  return {
    type: "DELETE_STATUS",
    payload,
  };
};

export const addMoretask = (payload) => {
  return {
    type: "ADD_MORE_TASK",
    payload,
  };
};

export const attandanceData = (payload) => {
  return {
    type: "ATTANDANCE_DATA",
    payload,
  };
};

export const setstatus = (payload) => {
  return {
    type: "SET_STATUS",
    payload,
  };
};

export const setIsSuccess = (payload) => {
  return {
    type: "SET_IS_SUCCESS",
    payload,
  };
};

export const setIsLoading = (payload) => {
  return {
    type: "SET_IS_LOADING",
    payload,
  };
};

export const setStatusByNameAndDate = (payload) => {
  return {
    type: "SEARCH_STATUS_NAME_DATE",
    payload,
  };
};

export const setMyTimesheetData = (payload) => {
  return {
    type: "SET_MY_TIMESHEET",
    payload,
  };
};

export const setjobOpening = (payload) => {
  return {
    type: SET_JOB_OPENING_DATA,
    payload,
  };
};

export const setjobApplication = (payload) => {
  return {
    type: SET_JOB_APPLICATION_DATA,
    payload,
  };
};

export const setAllApplications = (payload) => {
  return {
    type: "SET_ALL_JOB_APPLICATION_DATA",
    payload,
  };
};

export const updatejobOpening = (payload) => {
  return {
    type: UPDATE_JOB_OPENING,
    payload,
  };
};

export const deleteJobOpening = (payload) => {
  return {
    type: "DELETE_JOB_OPENING_DATA",
    payload,
  };
};

export const setContactUsData = (payload) => {
  return {
    type: "SET_CONTACTUS_DATA",
    payload,
  };
};

export const userProfile = (payload) => {
  return {
    type: "USER_PROFILE",
    payload,
  };
};

export const updateProfile = (payload) => {
  return {
    type: "UPDATE_PROFILE",
    payload,
  };
};

export const setSelectRole = (payload) => {
  return {
    type: "SET_SELECT_ROLE",
    payload,
  };
};

export const setAllEmployeeData = (payload) => {
  return {
    type: SET_ALL_EMPLOYEE_DATA,
    payload,
  };
};

export const setServiceAvailableData = (payload) => {
  return {
    type: SET_SERVICE_AVAILABLE_DATA,
    payload,
  };
};

export const setSalaryInfo = (payload) => {
  return {
    type: "SET_SALARY_INFO",
    payload,
  };
};

export const setSalary = (payload) => {
  return {
    type: "SET_SALARY_INFO",
    payload,
  };
};

export const setEmployeeSalaryInfo = (payload) => {
  return {
    type: "SET_EMPLOYEE_SALARY_INFO",
    payload,
  };
};

export const setApplyJob = () => {
  return {
    type: "Set_Apply_Job",
  };
};

export const setTdsData = (payload) => {
  return {
    type: "SET_TDS_DATA",
    payload,
  };
};

export const getAllLeaves = (payload) => {
  return {
    type: ALL_LEAVES,
    payload,
  };
};

export const setSubscribeApi = (payload) => {
  return {
    type: "Set_Subscribe_Api",
    payload,
  };
};

export const isShowDialogBoxChange = (payload) => {
  return {
    type: "SET_SHOW_DIALOG_BOX",
    payload,
  };
};

export const isAdditionDialogBoxToggle = (payload) => {
  return {
    type: "SET_SHOW_ADDITION_DIALOG_BOX",
    payload,
  };
};

export const isDeductionDialogBoxToggle = (payload) => {
  return {
    type: "SET_SHOW_DEDUCTION_DIALOG_BOX",
    payload,
  };
};

export const isOtherDialogBoxToggle = (payload) => {
  return {
    type: "SET_SHOW_OTHER_DIALOG_BOX",
    payload,
  };
};

export const isDeleteDialogBoxToggle = (payload) => {
  return {
    type: "SET_DELETE_DIALOG_BOX",
    payload,
  };
};

export const setMyTeams = (payload) => {
  return {
    type: "SET_MY_TEAMS",
    payload,
  };
};

//
export const setMyTeamsCount = (payload) => {
  return {
    type: "My_Team_Value",
    payload,
  };
};

export const setIndividualJobOpening = (payload) => {
  return {
    type: "INDIVIDUAL_JOB_OEPNING",
    payload,
  };
};

export const setIndividualStatus = (payload) => {
  return {
    type: "SET_INDIVIDUAL_STATUS",
    payload,
  };
};

export const setJobOpeningStatus = (payload) => {
  return {
    type: "SET_JOB_OPENING_STATUS",
    payload,
  };
};

export const setAssetsData = (payload) => {
  return {
    type: "SET_ASSETS_DATA",
    payload,
  };
};

export const setUserByPermission = (payload) => {
  return {
    type: "SET_USER_BY_PERMISSION",
    payload,
  };
};

export const setCompanyTaxData = (payload) => {
  return {
    type: "SET_COMPANY_TAX_DATA",
    payload,
  };
};

export const allEmployeeList = (payload) => {
  return {
    type: "ALL_EMPLOYEE_LIST",
    payload,
  };
};

export const loadUserToken = () => {
  const token = JSON.parse(localStorage.getItem("userLoginToken"));

  return {
    type: "SET_USER_TOKEN",
    payload: token,
  };
};

export const allBankDetails = (payload) => {
  return {
    type: "ALL_BANK_DETAILS",
    payload,
  };
};

export const allCompanyTaxDetails = (payload) => {
  return {
    type: "ALL_COMPANY_TAX_DETAILS",
    payload,
  };
};

export const setClientsDetails = (payload) => {
  return {
    type: "SET_CLIENTS_DETAILS",
    payload,
  };
};

export const setUsername = (payload) => {
  return {
    type: SET_USERNAME,
    payload,
  };
};

export const setShowGroup = (payload) => {
  return {
    type: "SET_SHOWGROUP",
    payload,
  };
};

export const setShowGroupList = (payload) => {
  return {
    type: SET_SHOW_GROUP_LIST,
    payload,
  };
};

export const setSearchRslt = (payload) => {
  return {
    type: SET_SEARCH_RESULT,
    payload,
  };
};

export const setDashboardData = (payload) => {
  return {
    type: SET_PUNCH_IN_DATA,
    payload,
  };
};

export const setTotalWorkingDays = (payload) => {
  return {
    type: TOTAL_WORKING_DAYS_LEAVES,
    payload,
  };
};

export const allExpensesDetails = (payload) => {
  return {
    type: "ALL_EXPENSES_DETAILS",
    payload,
  };
};

export const singleExpense = (payload) => {
  return {
    type: "SINGLE_EXPENSE",
    payload,
  };
};

export const assetsDetails = (payload) => {
  return {
    type: "SET_ASSET_DATA",
    payload,
  };
};

export const setProvidentFundData = (payload) => {
  return {
    type: "SET_PROVIDENT_DATA",
    payload,
  };
};

export const setAllLeaveType = (payload) => {
  return {
    type: SET_ALL_LEAVE_TYPE,
    payload,
  };
};

export const setSearchRsltForChat = (payload) => {
  return {
    type: SET_SEARCH_RESULT_FOR_CHAT,
    payload,
  };
};

export const setNotification = (payload) => {
  return {
    type: SET_NOTIFICATION,
    payload,
  };
};

export const setNotificationUpdate = (payload) => {
  return {
    type: SET_NOTIFICATION_UPDATE,
    payload,
  };
};

export const setRoomId = (payload) => {
  return {
    type: SET_ROOMID,
    payload,
  };
};

export const setChatUserUpdate = (payload) => {
  return {
    type: SET_CHAT_USERS_STATUS,
    payload,
  };
};

export const setMyStatus = (payload) => {
  return {
    type: SET_MY_STATUS,
    payload,
  };
};

export const setChats = (payload) => {
  return {
    type: SET_CHATS,
    payload,
  };
};

export const setUpdateChats = (payload) => {
  return {
    type: SET_UPDATE_CHATS,
    payload,
  };
};

export const setGroupChats = (payload) => {
  return {
    type: SET_GROUP_CHATS,
    payload,
  };
};

export const setActiveChat = (payload) => {
  return {
    type: SET_ACTIVECHAT,
    payload,
  };
};

export const setMessages = (payload) => {
  return {
    type: SET_MESSAGES,
    payload,
  };
};

export const setSendMessages = (payload) => {
  return {
    type: SET_SENDMESSAGES,
    payload,
  };
};

export const setBroadCastedMessages = (payload) => {
  return {
    type: SET_BROADCASTEDMESSAGE,
    payload,
  };
};

export const setMessaegUserList = (payload) => {
  return {
    type: SET_USERLIST,
    payload,
  };
};

export const setShowToast = (payload) => {
  return {
    type: SET_SHOW_TOAST,
    payload,
  };
};

export const setMonthlyRevenue = (payload) => {
  return {
    type: SET_MONTHLY_REVENUE,
    payload,
  };
};

export const setRevenue = (payload) => {
  return {
    type: SET_OVERVIEW_REVENUE,
    payload,
  };
};

export const setMonthlyClients = (payload) => {
  return {
    type: SET_MONTHLY_CLIENTS,
    payload,
  };
};

export const setMonthlyEmployees = (payload) => {
  return {
    type: SET_MONTHLY_EMPLOYEES,
    payload,
  };
};

export const setTotalEmployees = (payload) => {
  return {
    type: SET_TOTAL_EMPLOYEES,
    payload,
  };
};

export const setmonthlyWorkingHourGraph = (payload) => {
  return {
    type: SET_MONTHLY_WORKINGHOURS,
    payload,
  };
};

export const setYearlyWorkingHourGraph = (payload) => {
  return {
    type: SET_YEARLY_WORKINGHOURS,
    payload,
  };
};

export const setTotalAvg = (payload) => {
  return {
    type: SET_TOTAL_AVG,
    payload,
  };
};

export const setPageCount = (payload) => {
  return {
    type: PAGE_COUNT,
    payload,
  };
};

export const setYourAvg = (payload) => {
  return {
    type: SET_YOUR_AVG,
    payload,
  };
};

export const getAllReviews = (payload) => {
  return {
    type: "GET_REVIEWS",
    payload,
  };
};

export const setCompanyProfile = (payload) => {
  return {
    type: COMPANY_PROFILE,
    payload,
  };
};

export const getAllProjectResources = (payload) => {
  return {
    type: "GET_PROJECT_RESOURCES",
    payload,
  };
};

export const setMonthlyReview = (payload) => {
  return {
    type: SET_OVERIVEW_REVIEW,
    payload,
  };
};

export const setInterviewId = (payload) => {
  return {
    type: SET_INTERVIEW_ID,
    payload,
  };
};

export const setClientInformation = (payload) => {
  return {
    type: "SET_CLIENT_INFORMATION",
    payload,
  };
};

export const setAllProjects = (payload) => {
  return {
    type: "SET_ALL_PROJECTS",
    payload,
  };
};

export const setClientInvoiceData = (payload) => {
  return {
    type: "GET_CLIENT_INVOICES",
    payload,
  };
};

export const setInvoiceData = (payload) => {
  return {
    type: "SET_INVOICE_DATA",
    payload,
  };
};

export const setInvoiceById = (payload) => {
  return {
    type: SET_INVOICE_BYID,
    payload,
  };
};

export const isLoading = (payload) => {
  return {
    type: "IS_LOADING",
    payload,
  };
};

export const manageAccessRole = (payload) => {
  return {
    type: "MANAGE_ACCESS_ROLE",
    payload,
  };
};

export const searchEmployeeData = (payload) => {
  return {
    type: "SEARCH_EMPLOYEE",
    payload,
  };
};

export const setSuccess = (payload) => {
  return {
    type: "SET_SUCCESS",
    payload,
  };
};

export const setFetchSuccess = (payload) => {
  return {
    type: "SET_FETCH_SUCCESS",
    payload,
  };
};

export const setDesignationSearchData = (payload) => {
  return {
    type: "SET_DESIGNATION_DATA",
    payload,
  };
};

export const setMyTeamsData = (payload) => {
  return {
    type: "SET_MY_TEAMS_DATA",
    payload,
  };
};

export const setMyProject = (payload) => {
  return {
    type: "SET_MY_PROJECT_DATA",
    payload,
  };
};

export const setMyProjectMembers = (payload) => {
  return {
    type: SET_MY_PROJECT_MEMBERS,
    payload,
  };
};

export const setMyMembers = (payload) => {
  return {
    type: "SET_MY_MEMBERS_DATA",
    payload,
  };
};

export const setNewTeam = (payload) => {
  return {
    type: "SET_NEW_TEAM",
    payload,
  };
};

export const setMyOrganizationData = (payload) => {
  return {
    type: "SET_MY_ORGANIZATION_DATA",
    payload,
  };
};

export const setDesignationData = (payload) => {
  return {
    type: ALL_DESIGNATION_DATA,
    payload,
  };
};

export const singleReview = (payload) => {
  return {
    type: "SINGLE_REVIEW",
    payload,
  };
};

export const allProjects = (payload) => {
  return {
    type: "ALL_PROJECTS",
    payload,
  };
};

export const ProjectInfoById = (payload) => {
  return {
    type: PROJECT_BY_ID,
    payload,
  };
};

export const setCountryState = (payload) => {
  return {
    type: "SET_COUNTRY_STATE",
    payload,
  };
};

export const setCityName = (payload) => {
  return {
    type: "SET_CITY",
    payload,
  };
};

export const setManagerName = (payload) => {
  return {
    type: "SET_MANAGER_NAMES",
    payload,
  };
};

export const setUsersName = (payload) => {
  return {
    type: "SET_USERS_NAMES",
    payload,
  };
};

export const setIndividualInterviewData = (payload) => {
  return {
    type: SET_INDIVIDUAL_INTERVIEW_DATA,
    payload,
  };
};

export const setInterviewData = (payload) => {
  return {
    type: SET_INTERVIEW_DATA,
    payload,
  };
};

export const setAllFeedbackData = (payload) => {
  return {
    type: SET_ALL_FEEDBACK_DATA,
    payload,
  };
};

export const setMyFeedbackData = (payload) => {
  return {
    type: SET_MY_FEEDBACK_DATA,
    payload,
  };
};

export const setAttendance = (payload) => {
  return {
    type: SET_ATTENDANCE,
    payload,
  };
};

export const locationTracker = (payload) => {
  return {
    type: SET_TRACKER,
    payload,
  };
};

export const updateLeaveStatus = (leaveId, newStatus) => ({
  type: "UPDATE_LEAVE_STATUS",
  payload: { leaveId, newStatus },
});

export const updateWfhStatus = (wfhId, newStatus) => ({
  type: "UPDATE_WFH_STATUS",
  payload: { wfhId, newStatus },
});

export const setExpense = (payload) => {
  return {
    type: SET_EXPENSE_DATA,
    payload,
  };
};

export const setDownloadAssets = (payload) => {
  return {
    type: "SET_ASSET_DOWNLOAD",
    payload,
  };
};

export const getCompanyData = (payload) => {
  return {
    type: SINGLE_COMPANY_DATA,
    payload,
  };
};

export const getTdsDataByID = (payload) => {
  return {
    type: SINGLE_TDS_DATA,
    payload,
  };
};

export const getPFDataByID = (payload) => {
  return {
    type: SINGLE_PF_DATA,
    payload,
  };
};

export const setSalaryStructure = (payload) => ({
  type: "SET_SALARY_STRUCTURE",
  payload,
});

export const getAssetsDataById = (payload) => {
  return {
    type: SINGLE_ASSET_DATA,
    payload,
  };
};

export const addAssetSuccess = (response) => ({
  type: "ADD_ASSET_SUCCESS",
  payload: response,
});

export const editAssetSuccess = (response) => ({
  type: "EDIT_ASSET_SUCCESS",
  payload: response,
});

// TDS
export const addTdsSuccess = (response) => ({
  type: "ADD_TDS_SUCCESS",
  payload: response,
});

//Expenses
export const addExpensesSuccess = (response) => ({
  type: "ADD_EXPENSES_SUCCESS",
  payload: response,
});

export const setFiles = (file) => ({
  type: SET_FILES,
  payload: file,
});

export const storeFileData = (fileData) => ({
  type: STORE_FILE_DATA,
  payload: fileData,
});

export const setProjectList = (Data) => ({
  type: SET_PROJECT_LIST,
  payload: Data,
});

export const setLeaveSearchData = (Data) => ({
  type: SEARCH_LEAVE,
  payload: Data,
});

export const setSalaryFilterListing = (Data) => ({
  type: "SET_SALARY_FILTER",
  payload: Data,
});

export const setSection192 = (Data) => ({
  type: "SET_SECTION_192",
  payload: Data,
});

export const setShowProfile = (payload) => ({
  type: SET_SHOW_PROFILE,
  payload,
});

export const totalEmployeesNumber = (payload) => ({
  type: SET_EMPLOYEE_NUMBER,
  payload,
});

export const totalClientsNumber = (payload) => ({
  type: SET_CLIENTS_NUMBER,
  payload,
});

export const setProjectId = (payload) => ({
  type: SET_PROJECT_ID,
  payload,
});

export const setJobID = (payload) => ({
  type: "SET_JOBID",
  payload,
});

export const setResourceId = (payload) => ({
  type: SET_RESOURCE_ID,
  payload,
});

export const getAllRoles = (payload) => ({
  type: ALL_ROLES,
  payload,
});

export const TotalProjectCount = (payload) => ({
  type: "SET_TOTAL_PROJECT_COUNT",
  payload,
});

export const viewRoleData = (payload) => ({
  type: "SET_ROLE_DATA",
  payload,
});

export const allusers = (payload) => ({
  type: "ALL_USERS",
  payload,
});

export const getAllPermissions = (payload) => ({
  type: ALL_PERMISSIONS,
  payload,
});

export const setPermissionsByRoleId = (payload) => ({
  type: SET_PERMISSIONS,
  payload,
});

export const setUserProjects = (payload) => ({
  type: SET_USER_PROJECTS,
  payload,
});

export const emptyPermission = (payload) => ({
  type: "SET_EMPTY_PERM",
  payload,
});

export const setPermissionRoles = (payload) => ({
  type: "SET_ROLE_PERMISSION_ID",
  payload,
});

export const setCountryName = (payload) => {
  return {
    type: COUNTRY_NAME,
    payload,
  };
};

export const setStateName = (payload) => {
  return {
    type: STATE_NAME,
    payload,
  };
};

export const setCityNames = (payload) => {
  return {
    type: CITY_NAME,
    payload,
  };
};

export const setProfileId = (payload) => {
  return {
    type: PROFILE_ID,
    payload,
  };
};

export const setProfile = (payload) => {
  return {
    type: PROFILE_PICTURE,
    payload,
  };
};

export const setProfileStatus = (payload) => {
  return {
    type: STATUS,
    payload,
  };
};

export const empTime = (payload) => {
  return {
    type: EMPLOYEE_TIME,
    payload,
  };
};

export const empTimeDataCreate = (payload) => {
  return {
    type: "SET_SUCCESS",
    payload,
  };
};

export const setApiStatus = (payload) => {
  return {
    type: APISTATUS,
    payload,
  };
};

export const employeeEye = (payload) => {
  return {
    type: SET_EMPEYE,
    payload,
  };
};

export const retrieveIsLoading = (payload) => {
  return {
    type: "retrieve_IsLoding",
    payload,
  };
};

export const resourceAction = (payload) => {
  return {
    type: "RESOURCES_ACTION",
    payload,
  };
};

export const projectAction = (payload) => {
  return {
    type: "PROJECT_ACTION",
    payload,
  };
};

export const setBirthdays = (payload) => {
  return {
    type: SET_BIRTHDAYS,
    payload,
  };
};

export const setRequests = (payload) => {
  return {
    type: SET_REQUESTS,
    payload,
  };
};

export const setNewhires = (payload) => {
  return {
    type: SET_NEW_HIRES,
    payload,
  };
};

export const setWorkAniversary = (payload) => {
  return {
    type: SET_WORK_ANIVERSARY,
    payload,
  };
};

export const setDashboardatendAll = (payload) => {
  return {
    type: SET_DASHBOARD_ALLATEND,
    payload,
  };
};

export const setLeaveBalance = (payload) => {
  return {
    type: SET_LEAVE_BALANCE,
    payload,
  };
};

export const setOngoingData = (payload) => {
  return {
    type: SET_ONGOING_DATA,
    payload,
  };
};

export const setcreateFeedGet = (payload) => {
  return {
    type: SET_CREATE_FEED_GET,
    payload,
  };
};

export const setApplication = (payload) => {
  return {
    type: SET_APPLICATION,
    payload,
  };
};

export const setdeleteFeed = (payload) => {
  return {
    type: SET_DELETE_FEED,
    payload,
  };
};

export const setworkingdays = (payload) => {
  return {
    type: SET_WORKING_DAYS,
    payload,
  };
};

export const setProbation = (payload) => {
  return {
    type: SET_PROBATION,
    payload,
  };
};

export const setPersonalStatus = (payload) => {
  return {
    type: SET_PERSONAL_STATUS,
    payload,
  };
};

export const setTeamAttendPopup = (payload) => {
  return {
    type: SET_TEAMATTEND_POPUP,
    payload,
  };
};

export const setTeamLeave = (payload) => {
  return {
    type: SET_ASSIGN_LEAVE,
    payload,
  };
};

export const setTeamLeaveAdmin = (payload) => {
  return {
    type: "SET_ADMIN_LEAVE",
    payload,
  };
};

export const setOrganisationLeave = (payload) => {
  return {
    type: Organisation_LEAVES,
    payload,
  };
};

export const setWfhRrequest = (payload) => {
  return {
    type: SET_WFH_REQUEST,
    payload,
  };
};

export const AssetInfoItem = (payload) => {
  return {
    type: SET_ASSET_INFO,
    payload,
  };
};

export const setAllApplicantsData = (payload) => {
  return {
    type: SET_ALLAPPLICANTS,
    payload,
  };
};

export const setLeave = (payload) => {
  return {
    type: SET_LEAVE_DETAILS,
    payload,
  };
};

export const setRoleDetails = (payload) => {
  return {
    type: SET_ROLE_DET,
    payload,
  };
};

export const setViewEvent = (payload) => {
  return {
    type: SET_VIEW_EVENT,
    payload,
  };
};

export const setProfileDocumentData = (payload) => {
  return {
    type: "SET_POFILE_DOCUMT_DATA",
    payload,
  };
};

export const setClientTimesheetData = (payload) => {
  return {
    type: "GET_CLIENT_TIMESHEET",
    payload,
  };
};

export const setClientTimesheet = (payload) => {
  return {
    type: "GET_TIMESHEET",
    payload,
  };
};

export const settimesheetFilter = (payload) => {
  return {
    type: "SET_TIMESHEET_FILTER",
    payload,
  };
};

export const setHierarchyData = (payload) => {
  return {
    type: SET_HIERARCHY_DATA,
    payload,
  };
};

export const setAllEvent = (payload) => {
  return {
    type: "SET_ALL_EVENT",
    payload,
  };
};

export const setcheckIn = (payload) => {
  return {
    type: SET_CHECKIN_DATA,
    payload,
  };
};

export const setClientname = (payload) => {
  return {
    type: SET_CLIENT_NAME,
    payload,
  };
};

export const setData = (payload) => {
  return {
    type: "SET_DATA",
    payload,
  };
};

export const setsalaryslip = (payload) => {
  return {
    type: "SET_SALARY_SLIP",
    payload,
  };
};

export const setOnboarding = (payload) => {
  return {
    type: SET_ONBOARDING,
    payload,
  };
};

export const setFilterData = (payload) => {
  return {
    type: SET_FILTER_DATA,
    payload,
  };
};

export const setEmployeeData = (payload) => {
  return {
    type: SET_EMPLOYEE_DATA,
    payload,
  };
};

export const getFeedBackData = (payload) => {
  return {
    type: "SET_FEEDBACK_DATA",
    payload,
  };
};

export const setEmployeeLead = (payload) => {
  return {
    type: SET_EMPLOYEE_LEAD,
    payload,
  };
};

export const setTimeSheetCount = (payload) => {
  return {
    type: "SET_TIMESHEET",
    payload,
  };
};

export const employeeData = (payload) => {
  return {
    type: "SET_ATTENDANCE_Data",
    payload,
  };
};

export const getLeavesData = (payload) => {
  return {
    type: GET_LEAVES_DATA,
    payload,
  };
};

export const WFH_REQUEST = (payload) => {
  return {
    type: "SET_WFH_REQUEST",
    payload,
  };
};

export const ProjectData = (payload) => {
  return {
    type: "GET_PROJECT",
    payload,
  };
};

export const ProjectTeamData = (payload) => {
  return {
    type: "GET_TEAM_PROJECT",
    payload,
  };
};
