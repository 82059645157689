import { PAGE_COUNT, SET_ATTENDANCE } from "redux/actions/types";

const initialstate = {
  attandance: [],
  employeeAttendance: {},
  workfromdata: {},
  projectdata: [],
  projectteam: {},
  locationTracker: [],
};

export const attendanceReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
  case "ATTANDANCE_DATA":
    return {
      attandance: payload,
    };

  case SET_ATTENDANCE:
    return {
      ...state,
      attandance: payload,
    };

  case "IS_LOADING":
    return{
      ...state,
      isLoading: payload,
    };

  case PAGE_COUNT:
    return { ...state, pageCount: payload };

  case "SET_ATTENDANCE_Data":
    return{
      ...state,
      employeeAttendance: payload,
    };

  case "SET_WFH_REQUEST":
    return{
      ...state,
      employeeAttendance: payload,
    };

  case "GET_PROJECT":
    return{
      ...state,
      projectdata: payload,
    };

  case "GET_TEAM_PROJECT":
    return{
      ...state,
      projectteam: payload,
    };

  case "SET_TRACKER":
    return{
      ...state,
      locationTracker: payload,
    };

  default:
    return state;
  }
};
