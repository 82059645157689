import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { RxCross2 } from "react-icons/rx";
import { IoLocationSharp } from "react-icons/io5";
import Map, { Marker, Layer, Source } from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { convertTo12HourFormat, dateConvertFormat } from "utils/date";
import { useDispatch, useSelector } from "react-redux";
import { LocationTrackerData } from "redux/appThunk/Employee/attendance";

export default function LocationData({ setLocation, emp }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { locationTracker } = useSelector((state) => state.attendanceReducer);

  const [latitude, setLatitude] = useState(emp?.lat);
  const [longitude, setLongitude] = useState(emp?.long);
  const [mapCenter, setMapCenter] = useState({ lat: emp?.lat || 0, lng: emp?.long || 0 });

  const punchInTime = emp.checkIn || null;
  const punchOutTime = emp?.checkOut || null;

  const formattedPunchIn = punchInTime ? convertTo12HourFormat(punchInTime) : t("No data");
  const formattedPunchOut = punchOutTime ? convertTo12HourFormat(punchOutTime) : t("No data");

  useEffect(() => {
    dispatch(LocationTrackerData(emp?.date,emp?.user,emp?.date,emp?.empCode));
  },[]);

  const containerStyle = {
    width: "1350px",
    height: "869px",
    borderRadius: "15px",
  };

  const circleLayer = {
    id: "highlight-circle",
    type: "circle",
    paint: {
      "circle-radius": 80,
      "circle-color": "rgba(66, 135, 245, 0.5)",
    },
  };

  const circleSource = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude],
          },
        },
      ],
    },
  };

  const handlePunchInClick = () => {
    setLatitude(emp.lat);
    setLongitude(emp.long);
    setMapCenter({ lat: emp.lat, lng: emp.long });
  };

  const handlePunchOutClick = () => {
    setLatitude(emp.checkOutLat);
    setLongitude(emp.checkOutLong);
    setMapCenter({ lat: emp.checkOutLat, lng: emp.checkOutLong });
  };

  return (
    <div className="w-full h-full fixed top-0 left-0 z-50 bg-[rgba(3,27,89,.2)]">
      <div className="w-full h-full p-8 bg-white shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight">
        <div className="w-[25rem] bg-white border h-[90vh]">
          <div className="">
            <button className="text-gray-500 text-xl p-6" onClick={() => setLocation(false)}>
              <RxCross2 />
            </button>
          </div>
          <div className="p-6">
            <h1 className="text-[#686868] font-normal text-sm">{t("punch-In")}</h1>
            <div className="flex justify-between">
              <p className="text-[#031B59] font-medium cursor-pointer" onClick={handlePunchInClick}>
                {formattedPunchIn}
              </p>
              <p className="text-[#1B4FB6] text-xl"><IoLocationSharp /></p>
            </div>
            <h1 className="text-[#686868] font-normal text-sm mt-2">{t("punch-Out")}</h1>
            <div className="flex justify-between">
              <p className="text-[#031B59] font-medium cursor-pointer" onClick={handlePunchOutClick}>
                {formattedPunchOut}
              </p>
              <p className="text-[#ED6E0F] text-xl"><IoLocationSharp /></p>
            </div>
          </div>
          <div className="flex p-6 space-x-4">
            <div className="border h-0 w-[3.5rem] mt-[0.6rem]"></div>
            <h1 className="bg-blue-100 w-[15rem] text-center">{t("locations")}</h1>
            <div className="border h-0 w-[3.5rem] mt-[0.6rem]"></div>
          </div>
          <div className="p-6 ">
            <ul className="h-[54vh]  overflow-y-auto custom_scroll">
              {locationTracker.map((data, index) => (
                <li key={index} className="flex justify-between mt-2">
                  <div className="flex justify-between">
                    <p
                      className="text-[#191919]  w-[20rem] font-medium cursor-pointer capitalize"
                      onClick={handlePunchInClick}
                    >
                      {dateConvertFormat(data?.trackedAt)}
                      <div className="flex">
                        <p className="text-[#A1A1A1] text-lg">
                          <IoLocationSharp />
                        </p>
                        <p className="text-[#686868] font-normal">
                          Jaipur Rajasthan
                        </p>
                      </div>
                      <div className="w-full h-[0.1rem] bg-[#DEE4EB] mt-2"></div>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="absolute top-8 left-[28.5rem]">
            <Map
              initialViewState={{
                latitude: mapCenter.lat,
                longitude: mapCenter.lng,
                zoom: 16,
              }}
              style={containerStyle}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxAccessToken="pk.eyJ1Ijoia3Jpc2huODU4MSIsImEiOiJjbTFrOTB0OWkxOWI4MmpxbGZqdjY4Y2s0In0.mJNCeNz9qLg8srqwnYcwgQ"
            >
              <Marker longitude={longitude} latitude={latitude} />
              <Source id="circle-source" type="geojson" data={circleSource.data}>
                <Layer {...circleLayer} />
              </Source>
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
}

LocationData.propTypes = {
  setLocation: PropTypes.any,
  emp: PropTypes.any,
};
