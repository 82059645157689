import {
  LOCALHOST_URL,
  STAGING_HIRING_URL,
  PRODUCTION_HIRING_URL,
  STAGING_BGV_URL,
  PRODUCTION_BGV_URL,
  STAGING_PM_TOOL_URL,
  PRODUCTION_PM_TOOL_URL,
  STAGING_FINANCE_URL,
  PRODUCTION_FINANCE_URL,
  STAGING_SALES_URL,
  PRODUCTION_SALES_URL,
  STAGING_CHAT_URL,
  PRODUCTION_CHAT_URL
} from "utils/Constants";

export const getChatHost = () => {
  const host = window.location.host;

  if (host.includes("localhost") || host.includes("staging.deeporion.com")) {
    // return `ws://localhost:3000/cable`;
    // return `ws://deeporionback-0cd6e44c3c14.herokuapp.com/cable`;
    return STAGING_CHAT_URL;
    // return `https://api.deeporion.com/`;
  } else if (host.includes("deeporion.com")) {
    // return `ws://deeporionback-0cd6e44c3c14.herokuapp.com/cable`;
    return PRODUCTION_CHAT_URL;
  }
};

export const getNotoficationHost = () => {
  const host = window.location.host;
  if (host.includes("localhost") || host.includes("staging.deeporion.com")) {
    return `http://localhost:3000/`;
    // use for server in future
    // return `ws://deeporionback-0cd6e44c3c14.herokuapp.com/cable`;
    // return `https://api.staging.deeporion.com/`;
    // return `https://api.deeporion.com/`;
  } else if (host.includes("deeporion.com")) {
    return `ws://api.deeporion.com/cable`;
  }
};

export const getHiringHost = () => {
  const host = window.location.host;
  if (host.startsWith("localhost")) {
    return LOCALHOST_URL;
  } else if (host.includes("staging")) {
    return STAGING_HIRING_URL;
  } else {
    return PRODUCTION_HIRING_URL;
  }
};

export const getBackgroundVerificationHost = () => {
  const host = window.location.host;
  if (host.startsWith("localhost")) {
    return LOCALHOST_URL;
  }
  if (host.includes("staging")) {
    return STAGING_BGV_URL;
  }

  return PRODUCTION_BGV_URL;
};

export const getProjectManagementToolHost = () => {
  const host = window.location.host;
  if (host.startsWith("localhost")) {
    return LOCALHOST_URL;
  }
  if (host.includes("staging")) {
    return STAGING_PM_TOOL_URL;
  }

  return PRODUCTION_PM_TOOL_URL;
};

export const getFinanceHost = () => {
  const host = window.location.host;
  if (host.startsWith("localhost")) {
    return LOCALHOST_URL;
  }
  if (host.includes("staging")) {
    return STAGING_FINANCE_URL;
  }

  return PRODUCTION_FINANCE_URL;
};

export const getSalesHost = () => {
  const host = window.location.host;
  if (host.startsWith("localhost")) {
    return LOCALHOST_URL;
  }
  if (host.includes("staging")) {
    return STAGING_SALES_URL;
  }

  return PRODUCTION_SALES_URL;
};

export const pginationNumber = [10, 25, 50, 100, 150];
