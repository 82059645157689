import React from "react";
import PropTypes from "prop-types";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import LoaderComp from "component/loader/LoaderComp";
import { useTranslation } from "react-i18next";

export default function PasswordForm({
  onSubmit,
  userData,
  togglePasswordVisibility,
  passwordVisible,
  errors,
  touched,
  handleChange,
  handleBlur,
  values,
  reload,
  handleCancel,
  shouldRender,
}) {
  const { t } = useTranslation();

  return (
    <form className="flex flex-col m-3 p-3 flex-wrap bg-[#f2f6ff] justify-center">
      <div className="grid grid-cols-2 gap-2">
        {userData?.role === "employee" && (
          <div>
            <label className="text-[1rem] text-[grey]">
              {t("oldPassword")}
            </label>
            <div className="relative flex flex-col space-y-2">
              <input
                name="currentPassword"
                type={passwordVisible[0] ? "text" : "password"}
                id="currentPassword"
                className="h-[2.625rem] w-full p-2 pr-10 border border-[#E2E8F0] rounded text-[#191919]"
                value={values?.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="absolute right-4 top-1/3 transform -translate-y-1/2 cursor-pointer"
                onClick={() => togglePasswordVisibility(0)}
              >
                {passwordVisible[0] ? (
                  <BsEyeFill className="h-5 w-5 fill-[#64748B]" />
                ) : (
                  <BsEyeSlashFill className="h-5 w-5 fill-[#64748B]" />
                )}
              </div>
              {errors.currentPassword && touched.currentPassword && (
                <p className="text-[red]">{errors.currentPassword}</p>
              )}
            </div>
          </div>
        )}
        <div>
          <label className="text-[1rem] text-[grey]" htmlFor="newPassword">
            {t("newPassword")}
          </label>
          <div>
            <div className="relative flex">
              <input
                maxLength={50}
                type={passwordVisible[1] ? "text" : "password"}
                name="newPassword"
                id="newPassword"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={values?.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                onPaste={(e) => e.preventDefault()}
              />
              {passwordVisible[1] ? (
                <BsEyeFill
                  className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(1)}
                />
              ) : (
                <BsEyeSlashFill
                  className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(1)}
                />
              )}
            </div>
            {errors.newPassword && touched.newPassword && (
              <p className="text-[red]">{errors.newPassword}</p>
            )}
          </div>
        </div>
        <div>
          <label className="text-[1rem] text-[grey]" htmlFor="confirmPassword">
            {t("confirmNewPassword")}
          </label>
          <div>
            <div className="relative flex">
              <input
                maxLength={50}
                type={passwordVisible[2] ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                value={values?.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                onPaste={(e) => e.preventDefault()}
              />
              {passwordVisible[2] ? (
                <BsEyeFill
                  className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(2)}
                />
              ) : (
                <BsEyeSlashFill
                  className="h-5 w-5 fill-[#64748B] absolute right-4 bottom-[0.7rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(2)}
                />
              )}
            </div>
            {errors.confirmPassword && touched.confirmPassword && (
              <p className="text-[red]">{errors.confirmPassword}</p>
            )}
          </div>
        </div>
      </div>
      {reload ? (
        <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
          <LoaderComp />
        </div>
      ) : (
        <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
          <button
            className="border border-[#031b59] p-2 w-20 rounded-3xl"
            onClick={handleCancel}
          >
            {t("cancel")}
          </button>
          <button
            className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
            type="submit"
            onClick={onSubmit}
          >
            {shouldRender ? t("saveUpdate") : t("save")}
          </button>
        </div>
      )}
    </form>
  );
}

PasswordForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  togglePasswordVisibility: PropTypes.func.isRequired,
  passwordVisible: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  reload: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  shouldRender: PropTypes.bool.isRequired,
};
