import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Popup from "component/common/Popup";
import PropTypes from "prop-types";
import { RxCross2 } from "react-icons/rx";
import { addRegularize, addRegularizeS } from "./addAttendanceSchema";
import { APPLY_REGULARIZE } from "redux/appThunk/Admin/dashboard";

export default function ApplyRegularize({ setApplyRegularizePop , tableData}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [submitOnce] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedOption, setSelectedOption] = useState("optionone");

  const initialvalues = {
    checkIn: "",
    checkOut: "",
    note: "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: selectedOption === "optionone" ? addRegularize : addRegularizeS,
    onSubmit: async (values) => {
      const data = {
        regularize: {
          check_in: values?.checkIn,
          check_out: values?.checkOut,
          note: values?.note,
        },
      };
      addEmployessDialoBox();
      dispatch(APPLY_REGULARIZE(data, setApplyRegularizePop,tableData));
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = Formik;

  const displayLeaveData = {
    "check In": values?.checkIn,
    "check out": values?.checkOut,
    "Note": values?.note,
  };
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showModal && ref.current && !ref.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showModal]);
  const addEmployessDialoBox = () => {
    setShowPopUp(!showPopUp);
  };

  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div
      className="w-full h-full flex items-center
    justify-end fixed top-0 left-0 z-50 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="w-[35%] h-full  bg-white flex-flex-col
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div className="w-full bg-white flex justify-between p-4">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center ">
              <h2 className="font-extrabold px-12  text-xl text-[#031B59]">
                {t("regularize")}
              </h2>
            </div>
          </div>
          <div className="flex items-center justify-center ">
            <button className="flex text-xl justify-center items-center rounded-full">
              <RxCross2 onClick={() => setApplyRegularizePop(false)} />
            </button>
          </div>
          {showPopUp && (
            <Popup
              popupBox={addEmployessDialoBox}
              title={t("Regularize")}
              handleSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col border gap-2 capitalize">
                {displayLeaveData &&
                  Object.keys(displayLeaveData)?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="flex w-full items-center gap-4 p-2 "
                      >
                        <p className="basis-1/4 w-full">
                          {val} <span>:</span>
                        </p>
                        <p>{displayLeaveData[`${val}`]}</p>
                      </div>
                    );
                  })}
              </div>
            </Popup>
          )}
        </div>
        <h1 className="border"></h1>
        <div className="flex flex-col justify-between h-[82%] p-5">
          <div>
            <form
              className="w-full h-fit flex px-10 flex-col gap-3"
              autoComplete="off"
            >
              <div className="flex justify-start h-full gap-1 ">
                <input
                  type="radio"
                  checked={selectedOption === "optionone"}
                  onChange={() => handleRadioChange("optionone")}
                />
                <div className="text-[16px] font-[400] flex text-justify">
                  <h1>{t("optionone")}</h1>
                </div>
              </div>
              <div className="flex justify-start h-full gap-1 ">
                <input
                  type="radio"
                  checked={selectedOption === "optiontwo"}
                  onChange={() => handleRadioChange("optiontwo")}
                />
                <h1>{t("optiontwo")}</h1>
              </div>
              {selectedOption === "optionone" && (
                <>
                  <h1>{t("attendanceadjustment")}</h1>
                  <div className="flex w-full justify-between items-center">
                    <div className="border h-[38px] w-[15rem] flex items-center gap-1 p-2">
                      <input
                        type="time"
                        className="w-full"
                        placeholder="00:00"
                        value={values.checkIn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="checkIn"
                      />
                      {errors.checkIn && touched.checkIn ? (
                        <p className="text-[red]">{errors.checkIn}</p>
                      ) : null}
                    </div>

                    <h1 className="text-sm font-">To</h1>
                    <div className="border h-[38px] w-[15rem] flex items-center gap-1 p-2">
                      <input
                        type="time"
                        className="w-full"
                        placeholder="00:00"
                        name="checkOut"
                        value={values.checkOut}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.checkOut && touched.checkOut ? (
                        <p className="text-[red]">{errors.checkOut}</p>
                      ) : null}
                    </div>
                  </div>
                </>
              )}

              <div>
                <h1>{t("note")}</h1>
                <textarea
                  type="text"
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="border w-full h-[4rem]"
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      event.target.value += "\n";
                    }
                  }}
                />
              </div>
            </form>
          </div>
          <div>
            <div className="flex items-end justify-end h-[5rem]  space-x-4">
              <button
                className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
                onClick={() => setApplyRegularizePop(false)}
              >
                {t("cancel")}
              </button>
              <button
                className="h-[2.406rem] w-[6.25rem] lg:h-[2.813rem] lg:w-[8.5rem]
           p-2 bg-[#23275E] text-white rounded-full"
                type="submit"
                disabled={submitOnce}
                onClick={addEmployessDialoBox}
              >
                {t("Request")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ApplyRegularize.propTypes = {
  setApplyRegularizePop: PropTypes.any,
  tableData: PropTypes.any,
};
