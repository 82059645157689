import { RiArrowDropDownLine } from "react-icons/ri";
import React, { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import { fetchEmployeeAttendance } from "redux/appThunk/Employee/attendance";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AttendanceTypeDisplay = () => {
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const { employeeAttendance } = useSelector(
    (state) => state.attendanceReducer
  );

  const [btnValue, setBtnValue] = useState("Week");
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);
  const { t } = useTranslation();
  const handleButtonClick = () => {
    setPopupOpen(!popupOpen);
  };
  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setPopupOpen(false));
  useEffect(() => {
    dispatch(fetchEmployeeAttendance(userData?.emp_code, btnValue));
  }, [btnValue]);

  return (
    <div className="w-[96%] flex flex-col justify-center bg-none h-fit">
      <div
        onClick={() => handleButtonClick()}
        className="relative flex p-[0.5rem] w-[12rem] outline-none text-[#031B59]  cursor-pointer"
      >
        <button>{btnValue}</button>
        <RiArrowDropDownLine className="h-[2rem] w-[2rem]" />
        {popupOpen && (
          <div
            ref={menuRef}
            className="p-[0.5rem] border-[#E2E8F0] cursor-pointer absolute mt-[2rem]
             h-[5.5rem] w-[8rem] flex-col bg-[#fff]"
          >
            <div
              onClick={() => {
                setBtnValue("Week");
              }}
              className="p-[0.5rem] bg-[#fff] hover:bg-[#F2F6FF] rounded-md"
            >
              {t("lastweek")}
            </div>
            <div
              onClick={() => {
                setBtnValue("Month");
              }}
              className="p-[0.5rem] bg-[#fff] hover:bg-[#F2F6FF] rounded-md"
            >
              {t("lastmonth")}
            </div>
          </div>
        )}
      </div>
      <div className="h-full w-full flex flex-row gap-7 text-white rounded-[20px] mt-2">
        <div
          className="h-[97px] w-[195px] pl-[2rem] pt-[1.5rem] rounded-[10px] bg-[#FFFFFF]
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          <div>
            <h4 className=" md:text-base text-xl font-bold text-[#ED6E0F]">
              {employeeAttendance?.avg_hr}
            </h4>
          </div>
          <div className="text-[#191919]">
            <p className="md:text-sm pt-[0.5rem] font-medium">
              {t("totalhr")}
            </p>
          </div>
        </div>
        <div
          className="h-[97px] w-[195px] pl-[2rem] pt-[1.5rem] rounded-[10px] bg-[#FFFFFF]
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          <div>
            <h4 className=" md:text-base text-xl font-bold text-[#1A8718]">
              {employeeAttendance?.on_time_active
                ? parseFloat(employeeAttendance.on_time_active).toFixed(2)
                : "0.00"}%
            </h4>
          </div>
          <div className="text-[#191919]">
            <p className="md:text-sm pt-[0.5rem] font-medium">{t("ontime")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceTypeDisplay;
