import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import swalService from "../../utils/SwalServices";
import { useTranslation } from "react-i18next";
import LoaderComp from "component/loader/LoaderComp";
import Svg18 from "svgComponents/Svg18";
import {
  fetchAllManager,
  profileData,
  updateUserProfile
} from "redux/appThunk/Employee/profile";
import { fetchDesignationDataa } from "redux/appThunk/Admin/department";
import DatePicker from "component/common/DatePicker";

export default function OtherInformation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer.profileData);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [empType, setEmpType] = useState(user?.employee_type);
  const [loading, setLoading] = useState(false);
  const [submitshow,setSubmitshow] = useState(false);
  const [reload,setReload] = useState(false);
  const [handelvalue , setHandelValue] = useState(false);
  const { id } = useParams();

  const designationState = useSelector(
    (state) => state.designationReducer.allDesignation
  );

  useEffect(() => {
    dispatch(fetchAllManager());
    dispatch(fetchDesignationDataa());
  }, [id,userData?.id ]);

  useEffect(() => {
    if (id) {
      dispatch(profileData(id, setLoading ));
      dispatch(fetchDesignationDataa());
    } else {
      dispatch(profileData(userData?.id, setLoading));
    }
  }, [id,reload ]);

  useEffect(() => {
    dispatch(fetchAllManager());
    dispatch(fetchDesignationDataa());
  }, []);

  const initialValues = {
    id: user?.employee_id,
    employeeType: user?.employee_type ,
    uanNo: user?.uan_no,
    esicNo: user?.esic_no,
    designation: user?.designation?.designation,
    designationId: user?.designation?.id,
    jobType: user?.job_type ? user?.job_type : t("work_from_home"),
    dateOfJoining: user?.date_of_joining,
    relievingDate: user?.relieving_date,
    resignationDate: user?.resignation_date,
    resignationStatus: user?.resignation_status,
    noticePeriod: user?.notice_period,
    retentionBonus: user?.retention_bonus,
    retentionTime: user?.retention_time,
    retentionBonusNo: user?.retention_bonus_no,
    sectionApplicable: user?.section_applicable,
    managerId: user?.manager_id,
    incomeTax: user?.salary?.income_tax,
    managerName: user?.manager_name,
  };

  const Formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      let jsonObj = {};
      if (values?.sectionApplicable === "section 192") {
        jsonObj = {
          user: {
            uan_no: values?.uanNo,
            esic_no: values?.esicNo,
            id: values?.id,
            employee_type: values?.employeeType,
            job_type: values?.jobType,
            date_of_joining: values?.dateOfJoining,
            relieving_date: values?.relievingDate,
            resignation_date: values?.relievingDate,
            resignation_status: values?.resignationStatus,
            notice_period: values?.noticePeriod,
            retention_bonus: values?.retentionBonus,
            retention_time: values?.retentionTime,
            retention_bonus_no: values?.retentionBonusNo,
            designation_id: values?.designationId,
            section_applicable: values?.sectionApplicable,
            manager_id: Number(values?.managerId),
            income_tax: values?.incomeTax,
          },
        };
      } else if (values?.sectionApplicable === "section 194") {
        jsonObj = {
          user: {
            id: values?.id,
            employee_type: values?.employeeType,
            job_type: values?.jobType,
            date_of_joining: values?.dateOfJoining,
            relieving_date: values?.relievingDate,
            resignation_date: values?.relievingDate,
            resignation_status: values?.resignationStatus,
            notice_period: values?.noticePeriod,
            retention_bonus: values?.retentionBonus,
            retention_time: values?.retentionTime,
            retention_bonus_no: values?.retentionBonusNo,
            designation_id: values?.designationId,
            section_applicable: values?.sectionApplicable,
            manager_id: Number(values?.managerId),
          },
        };
      }
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            var id = user?.id;
            dispatch(updateUserProfile(id, jsonObj,setReload,setSubmitshow,setReload));
          }
        });
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
  } = Formik;

  const handleOnchange = (e ) => {
    handleChange(e);
    setSubmitshow(true);
    setHandelValue(true);
    if (e.target.name === "employeeType") {
      setEmpType(e.target.value);
      if (e.target.value === "contract") {
        setFieldValue("sectionApplicable", "section 194");
      } else {
        setFieldValue("sectionApplicable", "section 192");
      }
    }
  };

  return (
    <div
      className="w-[96%] h-[47.563] p-5 bg-white flex-flex-col space-y-8 rounded-[20px]"
    >
      {!loading ? (
        <form className="w-full h-fit grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
          <div className="flex flex-col space-y-2">
            <label htmlFor="id" className="text-[#313135]">
              {t("emp_id")}
            </label>
            <input
              type="text"
              name="id"
              id="id"
              data-TestId="id"
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
              disabled
              value={values.id}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="employee_type" className="text-[#313135]">
              {t("employeeType")}
            </label>
            <div className="relative">
              <select
                name="employeeType"
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none"
                id="employeeType"
                data-TestId="EmpType"
                value={handelvalue ? values?.employeeType : initialValues?.employeeType || ""}
                onChange={handleOnchange}
                onBlur={handleBlur}
              >
                <option value="" disabled>{t("select")}</option>
                <option value="fullTime">{t("fullTime")}</option>
                <option value="contract">{t("contract")}</option>
              </select>

              <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="id" className="text-[#313135]">
              {t("secApplicable")}
            </label>
            <input
              type="text"
              name="id"
              id="id"
              data-TestId="secApplicable"
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
              disabled
              value={values.sectionApplicable === "section_192" ? "Section 192" : "Section 194"}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          {userData?.role === "admin" && (
            <div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="secApplicable" className="text-[#313135]">
                  {t("secApplicable")}
                </label>
                <div className="relative">
                  <select
                    className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                    bg-white rounded text-[#191919] appearance-none"
                    name="sectionApplicable"
                    type="number"
                    data-TestId="secApplicable"
                    id="secApplicable"
                    value={handelvalue ? values?.sectionApplicable : initialValues?.sectionApplicable}
                    onChange={handleOnchange}
                    onBlur={handleBlur}
                  >
                    {empType !== "contract" && (
                      <option value="Section 192">{t("section192")}</option>
                    )}
                    <option value="Section 194">{t("section194")}</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                    <Svg18 />
                  </div>
                </div>
              </div>
              {handelvalue && (errors.sectionApplicable && touched.sectionApplicable ? (
                <p className="text-[red]">{errors.sectionApplicable}</p>
              ) : null)}
            </div>
          )}

          {values?.sectionApplicable !== "section 194" && (
            <>
              <div className="flex flex-col space-y-2">
                <label htmlFor="uAN" className="text-[#313135]">
                  {t("uAN")}
                </label>
                <input
                  type="text"
                  name="uanNo"
                  id="uAN"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                  disabled={userData?.role === "employee"}
                  value={handelvalue ? values?.uanNo : initialValues?.uanNo}
                  onChange={handleOnchange}
                  onBlur={handleBlur}
                  max="12"
                  maxLength="12"
                  onKeyPress={(e) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
                {handelvalue && (errors.uanNo && touched.uanNo ? (
                  <p className="text-[red]">{errors.uanNo}</p>
                ) : null)}
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="empSIC" className="text-[#313135]">
                  {t("empSIC")}
                </label>
                <input
                  type="text"
                  name="esicNo"
                  id="empSIC"
                  data-TestId="empSIC"
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
                  value={handelvalue ? values?.esicNo : initialValues?.esicNo}
                  disabled={userData?.role === "employee"}
                  onChange={handleOnchange}
                  onBlur={handleBlur}
                />
                {handelvalue && (errors.esicNo && touched.esicNo ? (
                  <p className="text-[red]">{errors.esicNo}</p>
                ) : null)}
              </div>
            </>
          )}

          <div className="flex flex-col space-y-2">
            <label htmlFor="designation" className="text-[#313135]">
              {t("designation")}
            </label>
            <div className="relative">
              <select
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                bg-white rounded text-[#191919] appearance-none capitalize"
                type="text"
                name="designationId"
                id="designation"
                value={handelvalue ? values?.designationId : initialValues?.designationId || "" }
                onChange={handleOnchange}
                onBlur={handleBlur}
                disabled={userData?.role === "employee"}
              >
                <option value="" disabled> {t("select")} </option>
                {Array.isArray(designationState) &&
                  designationState
                    .filter((item) => item && item.designation !== null)
                    .map((item, id) => (
                      <option key={id} value={item.id}>
                      </option>
                    ))}
              </select>
              <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
            { handelvalue && (errors.designation && touched.designation ? (
              <p className="text-[red]">{errors.designation}</p>
            ) : null)}
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="jobType" className="text-[#313135]">
              {t("jobType")}
            </label>
            <div className="relative">
              <select
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                  bg-white rounded text-[#191919] appearance-none"
                type="number"
                name="jobType"
                id="jobType"
                value={handelvalue ? values?.jobType : initialValues?.jobType}
                onChange={handleOnchange}
                onBlur={handleBlur}
                disabled={userData?.role === "employee"}
              >
                <option value="wfo">{t("work_from_office")}</option>
                <option value="remote">{t("remote")}</option>
                <option value="hybrid">{t("hybrid")}</option>
              </select>
              <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="dOJoining" className="text-[#313135]">
              {t("dOJoining")}
            </label>
            <DatePicker
              handleChange={handleOnchange}
              name="dateOfJoining"
              value={handelvalue ? values?.dateOfJoining : initialValues?.dateOfJoining}
              handleBlur={handleBlur}
              disabled={userData?.role === "employee"}
              id="date"
              styles = "w-[100%]"

            />
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="relievingDate" className="text-[#313135]">
              {t("relievingDate")}
            </label>

            <DatePicker
              handleChange={handleOnchange}
              name="relievingDate"
              value={handelvalue ? values?.relievingDate : initialValues?.relievingDate}
              handleBlur={handleBlur}
              disabled={userData?.role === "employee"}
              placeholder="DD MM YYYY"
              id="date"
              styles = "w-[100%]"

            />

          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="resiDate" className="text-[#313135]">
              {t("resiDate")}
            </label>
            <DatePicker
              handleChange={handleOnchange}
              name="resignationDate"
              value={handelvalue ? values?.resignationDate : initialValues?.resignationDate}
              handleBlur={handleBlur}
              disabled={userData?.role === "employee"}
              placeholder="DD MM YYYY"
              id="date"
              styles = "w-[100%]"

            />
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="resiStatus" className="text-[#313135]">
              {t("resiStatus")}
            </label>
            <div className="relative">
              <select
                className={`h-[2.625rem] w-full p-2 border border-[#E2E8F0] bg-white appearance-none
              rounded ${
        values.resignationStatus === "approved"
          ? "text-[#1A8718]"
          : values.resignationStatus === "cancelled"
            ? "text-[#FF0000]"
            : "text-[#191919]"
        }`}
                name="resignationStatus"
                id="resiStatus"
                value={handelvalue ? values?.resignationStatus : initialValues?.resignationStatus}
                onChange={handleOnchange}
                onBlur={handleBlur}
                disabled={userData?.role === "employee"}
              >
                <option value="pending" className="text-[#191919]">
                  {t("pending")}
                </option>
                <option value="approved" className="text-[#1A8718]">
                  {t("approved")}
                </option>
                <option value="cancelled" className="text-[#FF0000]">
                  {t("cancelled")}
                </option>
              </select>
              <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="noticePeriod" className="text-[#313135]">
              {t("noticePeriod")}
            </label>
            <input
              type="text"
              name="noticePeriod"
              id="noticePeriod"
              value={handelvalue ? values?.noticePeriod : initialValues?.noticePeriod}
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
              disabled={userData?.role === "employee"}
              onChange={handleOnchange}
              onBlur={handleBlur}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="retenTimePeriod" className="text-[#313135]">
              {t("retentionTimePeriod")}
            </label>
            <div className="relative">
              <select
                type="text"
                name="retentionTime"
                id="retenTimePeriod"
                value={handelvalue ? values?.retentionTime : initialValues?.retentionTime || "" }
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                rounded text-[#191919] bg-white appearance-none"
                disabled={userData?.role === "employee"}
                onChange={handleOnchange}
                onBlur={handleBlur}
              >
                <option value="" disabled>{t("select")}</option>
                <option className="accent-[#031B59]" value="monthly">
                  {t("monthly")}
                </option>
                <option className="accent-[#031B59]" value="quarterly">
                  {t("quarterly")}
                </option>
                <option className="accent-[#031B59]" value="halfYearly">
                  {t("halfYearly")}
                </option>
                <option className="accent-[#031B59]" value="yearly">
                  {t("yearly")}
                </option>
              </select>
              <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                <Svg18 />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label htmlFor="retentionBonusNo" className="text-[#313135]">
              {t("numOfRetentionBonus")}
            </label>
            <input
              type="text"
              name="retentionBonusNo"
              id="retentionBonusNo"
              value={handelvalue ? values.retentionBonusNo : initialValues.retentionBonusNo}
              className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
              disabled={userData?.role === "employee"}
              onChange={handleOnchange}
              onBlur={handleBlur}
              onKeyPress={(e) => {
                const pattern = /[0-9]/;
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault();
                }
              }}
            />
            {handelvalue && (errors.retentionBonusNo && touched.retentionBonusNo ? (
              <p className="text-[red]">{errors.retentionBonusNo}</p>
            ) : null)}
          </div>
          {values?.sectionApplicable !== "section 194" && (
            <div className="flex flex-col space-y-2">
              <label htmlFor="incometax" className="text-[#313135]">
                {t("incometax")}
              </label>
              <div className="relative">
                <select
                  className="h-[2.625rem] w-full p-2 border border-[#E2E8F0]
                 bg-white rounded text-[#191919] appearance-none"
                  type="number"
                  name="incomeTax"
                  id="incometax"
                  value={handelvalue ? values?.incomeTax : initialValues?.incomeTax}
                  onChange={handleOnchange }
                  onBlur={handleBlur}
                  disabled={userData?.role === "employee"}
                >
                  <option value="New Regime">{t("newregime")}</option>
                  <option value="Old Regime">{t("oldRegime")}</option>
                </select>
                <div className="absolute inset-y-0 right-0 top-3 flex items-center px-2 pointer-events-none">
                  <Svg18 />
                </div>
              </div>
            </div>
          )}
        </form>
      ) : (
        <LoaderComp />
      ) }

      {submitshow ? (<div className="w-full h-16 bg-white flex justify-end">
        {userData?.role === "admin" && (
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full"
              onClick={(e) => {
                if (e) {
                  setSubmitshow(false);
                  setHandelValue(false);
                }
              }}
            >
              {t("cancel")}
            </button>
            <button
              className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              onClick={handleSubmit}
            >
              {t("update")}
            </button>
          </div>
        )}
      </div>) : null}
    </div>
  );
}
