import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  setAllLeaveType,
  getAllLeaves,
  setPageCount,
  isLoading,
  updateLeaveStatus,
  setApiStatus,
  setTeamLeave,
  setLeave,
  setTeamLeaveAdmin,
  getLeavesData
} from "redux/actions/action";
import { requests } from "redux/appThunk/Admin/dashboard";
import {
  formatLeaveTypeResponse,
  formatEmployeeData,
  formattedStatusChange,
  LeaveData,
  formatTeamLeaveData,
  formatTeamLeaveDataAdmin,
  formatMyLeaveData
} from "redux/selector/Admin/leave";

export const fetchAllLeaveType = () => async (dispatch) => {
  try {
    const path = apiUrl?.leaveType;
    const { response } = await Helper.get(path);
    const formattedResponse = formatLeaveTypeResponse(response);
    dispatch(setAllLeaveType(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const handleAddLeave = (formData, id, currentPage) => async (dispatch) => {
  try {
    const path = apiUrl.leaves;
    const { status } = await Helper.post(formData, path, true);
    if (status === 201 || status === 200) {
      ToastServices.showToast({
        message: "Leave Added",
        type: "success",
        autoClose: "2000",
      });
      dispatch(setApiStatus("success"));
    }
  } catch (error) {
    dispatch(setApiStatus(null));
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  } finally {
    dispatch(fetchMyLeaveData(id, currentPage));
  }
};

export const fetchEmployeeData =
  (currentPage, itemsPerPage) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const path = `leaves?page=${currentPage}&per_page=${itemsPerPage}`;
      const { response } = await Helper.get(path);
      const formattedResponse = formatEmployeeData(response?.data);

      dispatch(getAllLeaves(formattedResponse));
      dispatch(setPageCount(response?.pagination_data?.total_pages));
      dispatch(isLoading(false));
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: "error",
      });
    }
  };

export const fetchMyLeaveData =
  (id, currentPage) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const path = `my_leaves?user_id=${id}&page=${currentPage}&per_page=${10}`;

      const { response } = await Helper.get(path);
      const formattedResponse = formatMyLeaveData(response?.data);

      dispatch(getAllLeaves(formattedResponse));
      dispatch(setPageCount(response?.total_pages));

    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: "error",
      });
    } finally {
      dispatch(isLoading(false));
    }
  };

export const handleStatusChange = (values, ids, option) => async (dispatch) => {
  try {

    const request = formattedStatusChange(values, option);

    const path = apiUrl.leaves + `/${ids}`;
    await Helper.put({ leave: request }, path);
    dispatch(updateLeaveStatus(ids, option));
    dispatch(requests());
    ToastServices.showToast({
      message: "Updated Successfully",
      type: "success",
    });
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const handleLeaveSearchData =
  ({ currentPage, itemsPerPage, userId, searchName, searchDate, leave_type, status }) =>

    async (dispatch) => {
      dispatch(isLoading(true));
      var url;
      if (searchDate) {
        url = `leaves?&per_page=${itemsPerPage}&page=${currentPage}&from_date=${searchDate || ""}`;
      } else if (searchName) {
        url = `leaves?&per_page=${itemsPerPage}&page=${currentPage}&query=${searchName}`;
      }
      else if (userId) {
        url = `my_leaves?user_id=${userId}&leave_type=${leave_type ? leave_type : ""}&status=${status ? status : ""}&page=${currentPage}&per_page=10`;
      } else {
        url = `leaves?&per_page=${itemsPerPage}&page=${currentPage}`;
      }
      try {
        const { status, response } = await Helper.get(url);

        let formattedResponse = [];

        if (userId) {
          formattedResponse = formatMyLeaveData(response?.data);

        } else {

          formattedResponse = formatEmployeeData(response?.data);
        }

        if (status === 200 || status === 201) {
          dispatch(isLoading(false));
          dispatch(getAllLeaves(formattedResponse));
          dispatch(setPageCount(response?.total_pages));
        }
      } catch (error) {
        dispatch(isLoading(false));
        ToastServices.showToast({
          message: error,
          type: "error",
        });
      } finally {
        dispatch(isLoading(false));
      }
    };

export const handleTeamLeavesData = (id, setIsLoading) => async (dispatch) => {
  try {
    setIsLoading(true);
    const path = `users/assigned_user/${id}`;
    const { response, status } = await Helper.get(path);
    const formattedResponse = formatTeamLeaveData(response?.assigned_users);
    if (status === 200 || status === 201) {
      setIsLoading(false);
      dispatch(setTeamLeave(formattedResponse));
    }
  } catch (error) {
    setIsLoading(false);
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const handleTeamLeaveAdmin = (designations, search) => async (dispatch) => {
  try {
    let path;

    if (designations) {
      path = `/team_leaves?designations=${designations}`;
    } else if (search) {
      path = `/team_leaves?query=${search}`;
    } else {
      path = '/team_leaves';
    }

    const { response, status } = await Helper.get(path);
    const formattedResponse = formatTeamLeaveDataAdmin(response);

    if (status === 200 || status === 201) {
      dispatch(setTeamLeaveAdmin(formattedResponse));
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message || "An error occurred",
      type: "error",
    });
  }
};

export const TeamLeavesget = (id) => async (dispatch) => {
  try {
    const path = `team_leaves/${id}`;
    const { response } = await Helper.get(path);

    const formattedResponse = LeaveData(response);

    dispatch(setLeave(formattedResponse));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const getAllLeavesData = () => async (dispatch) => {
  let month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  if (month.toString().length < 2) {
    month = `0${month}`;
  }
  try {
    const path = `displayleaves?period=${year}-${month}`;
    const { response } = await Helper.get(path);
    dispatch(getLeavesData(response?.leaves));
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const updateLeaveDetails =
  (formData, editedLesave) => async (dispatch) => {
    try {
      const path = `${apiUrl.leaves}/${editedLesave.id}`;
      const { response ,status } = await Helper.put(formData, path);
      if (status === 200 || status === 201) {
        dispatch(fetchMyLeaveData(response?.leave?.user_id, 1));
        ToastServices.showToast({
          message: "Leave updated successfully!",
          type: "success",
          autoClose: 3000,
        });
      }
    } catch (error) {
      ToastServices.showToast({
        message: error.message,
        type: "error",
        autoClose: 3000,
      });
    }
  };

export const handleAddLeaves = (formData,setSubmitOnce) => async (dispatch) => {
  try {
    const path = apiUrl.leaves;
    const { status } = await Helper.post(formData, path);
    if (status === 201 || status === 200) {
      setSubmitOnce(false);
      ToastServices.showToast({
        message: "Leave Added",
        type: "success",
        autoClose: "2000",
      });
      dispatch(setApiStatus("success"));
    }
  } catch (error) {
    dispatch(setApiStatus(null));
    setSubmitOnce(false);
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

