import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const BranchHeadquater = ({isHeadquarter,isEdit,isSelected, onCheckboxToggle}) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div className="flex items-center space-x-2">
        {isEdit && (
          <div className="flex items-center border border-gray-400">
            <input type="checkbox"
              className="w-3 h-3 cursor-pointer m-1 accent-[#F2F6FF] checked:accent-[#031B59]"
              checked={isSelected}
              onChange={onCheckboxToggle} />
            <p className="px-2 py-1 text-xs font-normal text-center rounded text-gray-400">
              {t("Set branch as Headquater")}
            </p>
          </div> )}
        {isHeadquarter && !isEdit && (
          <p className="px-2 py-1 text-xs font-normal text-orange-500 border
           border-orange-500 text-center rounded bg-orange-100">
            {t("headquater")} </p> )}
      </div>
    </div>
  );
};

export default BranchHeadquater;

BranchHeadquater.propTypes = {
  isHeadquarter: PropTypes.bool,
  isEdit: PropTypes.bool,
  isSelected: PropTypes.bool,
  onCheckboxToggle: PropTypes.func,
};

