export const formattedData = (response) => {
  const requiredResponse = [];

  response?.check_in_outs?.map((obj) => {
    requiredResponse.push({
      empName: obj?.name,
      id: obj?.id,
      user: obj?.user_id,
      empCode: obj?.emp_code,
      date: obj?.date,
      checkIn: obj?.check_in,
      checkOut: obj?.check_out,
      totalTime: obj?.total_time,
      punchType: obj?.punch_type,
      workStatus: obj?.work_status,
      remark: obj?.remark,
      lat: obj?.latitude,
      long: obj?.longitude,
      checkOutLat: obj?.check_out_latitude,
      checkOutLong: obj?.check_out_longitude,
    });
  });

  return {
    data: requiredResponse,
    pagCount: response?.total_pages,
  };
};

export const formattedEmployeeData = (response, searchQuery) => {
  const requiredResponse = searchQuery === "Week" ? {
    avg_hr: response?.user_total_hrs_of_last_week,
    on_time_active: response?.on_time_last_week,
  } : {
    avg_hr: response?.user_total_hrs_of_last_month,
    on_time_active: response?.on_time_last_month,
  };

  return requiredResponse;
};

export const formattedProjectData = (response) => {
  const requiredResponse = [];
  response?.projects?.map((obj) => {
    requiredResponse.push({
      projectName: obj?.project?.name,
      projectStatus: obj?.project?.status,
      member: obj?.member,
      description: obj?.project?.description,
      createdDate: obj?.project?.created_at,
      id: obj?.project?.lead_id,
      ids: obj?.project?.id,
    });
  });

  return requiredResponse;
};

export const formattedProjectTeamData = (response) => {
  const requiredResponse = [];
  response?.resources?.map((item) => {
    requiredResponse.push({
      name: item?.employeeName,
      emp_code: item?.empCode,
      userId: item?.userId,
      department: item?.department,
      designation: item?.designation,
    });
  });

  return {
    data: requiredResponse,
    pagCount: response?.totalPages,
  };
};

export const LocationTracker = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.user_id,
      empCode: obj?.emp_code,
      latitude: obj?.latitude,
      longitude: obj?.longitude,
      trackedAt: obj?.tracked_at,
      status: obj?.status,
      createdAt: obj?.created_at,
      updatedAt: obj?.updated_at,
    });
  });

  return requiredResponse;
};
