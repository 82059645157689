import EmployeesTime from "component/common/BankDetails/EmployeesTime";
import React from "react";

function EmployessTime() {
  return (
    <div className="w-full">
      <EmployeesTime />
    </div>
  );
}

export default EmployessTime;
