import React from "react";
import PropTypes from "prop-types";
import EmployeesTime from "component/common/BankDetails/EmployeesTime";

export default function EmployeeTime({
  accordionOpen,
  editState,
  setEditState,
  eyeId,
}) {
  return (
    <div>
      <EmployeesTime
        accordionOpen={accordionOpen}
        editState={editState}
        setEditState={setEditState}
        eyeId={eyeId}
      />
    </div>
  );
}

EmployeeTime.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.func.isRequired,
  eyeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
