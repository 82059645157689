/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import { employeeTypeObj } from "component/common/enum";
import * as Yup from "yup";

export const changePasswordValidation = (role) => {
  return Yup.object({
    currentPassword: role === employeeTypeObj.employee
      ? Yup.string().required("Current password is required!")
      : Yup.mixed().notRequired(),
    newPassword: Yup.string()
      .required("Enter new password")
      .min(8, "Password must contain 8 or more characters")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least one of each: uppercase, lowercase, number, and special character"
      )
      .max(32, "Maximum 32 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm new password"),
  });
};
