export const formatManagerData = (response) => {
  const managerName = [];
  response.managerNames.map((data) => managerName.push(data));

  return managerName;
};

export const formatProjectList = (response) => {
  const ProjectList = [];

  response &&
    response?.projects.map((data) => {
      ProjectList.push({
        id: data?.project?.id,
        name: data?.project?.name,
      });
    });

  return ProjectList;
};

export const GetProfile = (response) => {
  const json = {
    picture: response?.profile_picture_url,
  };

  return json;
};

export const formatUsersData = (response) => {
  const usersList = [];

  response &&
    response?.users.map((data) => {
      usersList.push({
        id: data?.id,
        email: data?.email,
        full_name: data?.full_name,
        profile_url: data?.profile_picture_url ? data?.profile_picture_url : "",
      });
    });

  return usersList;
};

export const formatBankDetails = (response) => {
  return {
    accountName: response?.bank_details?.account_name,
    accountNumber: response?.bank_details?.account_number,
    ifsc: response?.bank_details?.ifsc,
    cancelCheckUrl: response?.cancelled_cheque_name,
    swiftCode: response?.bank_details?.swift_code,
  };
};

export const getEmpTime = (response) => {
  const res = response?.employee_time;
  const requiredResponse = {
    id: res?.user_id,
    employeeTimeId: res?.employee_time_id,
    startTime: res?.start_time,
    endTime: res?.end_time,
    totelHours: res?.total_working_hours_per_shift,
    timeZone: res?.timezone,
  };

  return requiredResponse;
};

export const formatBankDetail = (response) => {
  return {
    accountName: response?.bank_details?.account_name,
    accountNumber: response?.bank_details?.account_number,
    ifsc: response?.bank_details?.ifsc,
    cancelCheckUrl: response?.cancelled_cheque_name,
    swiftCode: response?.bank_details?.swift_code,
  };
};

export const formatBankDetailsForSubmission = (values, cheque) => {
  const formdata = new FormData();
  formdata.append("bank_details[account_name]", values.accountName);
  formdata.append("bank_details[account_number]", values.accountNumber);
  formdata.append("bank_details[ifsc]", values.ifsc);
  formdata.append("bank_details[swift_code]", values.swiftCode);

  if (cheque) {
    formdata.append("bank_details[cancelled_cheque]", cheque);
  }

  return formdata;
};

export const formatBankDetailsFormData = (values, cheque) => {
  const formdata = new FormData();
  formdata.append("bank_details[account_name]", values.accountName);
  formdata.append("bank_details[account_number]", values.accountNumber);
  formdata.append("bank_details[ifsc]", values.ifsc);
  formdata.append("bank_details[swift_code]", values.swiftCode);

  if (cheque) {
    formdata.append("bank_details[cancelled_cheque]", cheque);
  }

  return formdata;
};

export const formatEmployeeTimeFormData = (values) => {
  const formdata = new FormData();
  formdata.append("employee_time[start_time]", values.startTime);
  formdata.append("employee_time[end_time]", values.endTime);
  formdata.append("employee_time[total_working_hours_per_shift]", values.totalWorkingHoursPerShift);
  formdata.append("employee_time[timezone]", values.timeZone);

  return formdata;
};

