import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import swalService from "../../utils/SwalServices";
import { useTranslation } from "react-i18next";
import { changePassword } from "redux/appThunk/Employee/profile";
import PasswordForm from "component/common/EmpolyeeForm/PasswordForm";
import { createPasswordPayload } from "redux/selector/Admin/employee";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [,setReload] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState([false, false, false]);
  const shouldRender = location.pathname === "/profileBasics/changePassword";
  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisible(updatedVisibility);
  };
  const [submitshow,setSubmitshow] = useState(false);
  const changePasswordValidation = Yup.object({
    currentPassword:
      userData?.role === "employee"
        ? Yup.string().required("Current password is required!")
        : null,
    newPassword: Yup.string()
      .required("Enter new password")
      .min(8, "Password must contain 8 or more characters")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "Password must contain at least one of each: uppercase, lowercase, number, and special character"
      )
      .max(32, "Maximum 32 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm new password"),
  });
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordValidation,
    onSubmit: async () => {
      const jsonObj = createPasswordPayload(userData, values);

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === "admin") {
              id = userData?.id;
            }
            if (userData?.role === "employee") {
              id = userData?.id;
            }
            try {
              dispatch(changePassword(id, jsonObj, setSubmitshow, setSubmitshow, setReload));
            } catch (error) {
              if (error.response && error.response.status === 422) {
                console.error("Password change failed:", error.response.data.error);
              } else {
                console.error("Password change failed:", error);
              }
            }
          }
        });
    },
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    Formik;

  return (
    <div
      className="w-[96%] max:h-[46.063rem] min:h-[20rem] p-5 bg-white flex-flex-col space-y-8 rounded-[20px]"
    >
      <PasswordForm
        initialValues={initialValues}
        validationSchema={changePasswordValidation}
        onSubmit={handleSubmit}
        userData={userData}
        togglePasswordVisibility={togglePasswordVisibility}
        passwordVisible={passwordVisible}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        reload={false}
        handleCancel={() => setSubmitshow(false)}
        shouldRender={shouldRender}
      />

      {submitshow ? (
        <div className="w-full h-16 bg-white flex justify-end">
          <div className="flex items-center justify-center space-x-4">
            <button
              className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
              onClick={() => { setSubmitshow(false); }}
            >
              {t("cancel")}
            </button>
            <button
              className="h-[2.813rem] w-[8.625rem] p-2 bg-[#23275E] text-white rounded-full"
              type="submit"
              onClick={handleSubmit}
            >
              {shouldRender ? t("saveUpdate") : t("save")}
            </button>
          </div>
        </div>) : null}
    </div>
  );
}
