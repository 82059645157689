/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import AddLeave from "./AddLeaveData";
import { handleTeamLeaveAdmin } from "redux/appThunk/Admin/leave";
import { useDispatch, useSelector } from "react-redux";
import { AddButton } from "component/common/accessControlUi/Button";
import PropTypes, { array } from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTable from "component/common/table/CustomTable";
import useDebounce from "hooks/useDebounce";
import Paginate from "component/admin/Employee/Paginate";
import { HiArrowNarrowLeft } from "react-icons/hi";
import {employeeDetails} from "./column";

export default function Teamleavestable({
  tableColumn,
  setShowDetails,
  state,
  datas,
}) {
  const dispatch = useDispatch();
  const [searchTerm] = useState("");
  const searchName = useDebounce(searchTerm, 800);
  const [currentPage, setCurrentPage] = useState(1);
  const [addLeavePop, setAddLeavePop] = useState(false);
  const {isLoading} = useSelector((state) => state.leaveReducer);
  const { t } = useTranslation();
  const { allLeaves ,pageCount } = useSelector(
    (state) => state.leaveReducer
  );

  useEffect(() => {
    dispatch(handleTeamLeaveAdmin("", searchTerm));
  }, [dispatch, searchName]);

  return (
    <div className="w-full h-full overflow-hidden">
      <div>
        <div
          className="w-[97.5%] h-[full] bg-white space-y-4
    flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mb-3 ml-3 mt-1"
        >
          <div className="flex justify-between mb-4 items-center">
            <div>
              <h2 className="font-extrabold text-xl text-[#031B59] flex items-center">
                <HiArrowNarrowLeft
                  className={`mr-2  opacity-50" : "cursor-pointer" }`}
                  onClick={() => setShowDetails(false)}
                />
                {datas?.name ? datas?.name : t("N/A")}
              </h2>
              <div className="pl-7 text-gray-400">
                {state.datas?.designation ? state.datas?.designation : t("N/A")}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <AddButton
                title={t("Apply Leave")}
                onClick={() => setAddLeavePop(true)}
              />
              {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}
            </div>
          </div>

          <div
            className={`overflow-x-scroll max-h-[60vh] ${isLoading ? "custom_scroller" : "custom_scroll"}`}
          >
            <CustomTable
              newColumn={["Status", "Action"]}
              columns={[...employeeDetails, ...tableColumn]}
              data={allLeaves}
              isLoading={isLoading}
            />

          </div>
          <div className="w-full h-3 bg-[white] flex justify-between items-center">
            {!isLoading && (
              <div className="w-full h-12 bg-white flex justify-between items-center">
                {allLeaves?.length > 0 ? (
                  <>
                    <div className="text-[#031B59] font-medium">
                      {t("Showing")} {currentPage} {t("of")} {pageCount}
                    </div>{" "}
                    <Paginate
                      currentPage={currentPage}
                      initialPageCount={pageCount}
                      pageRangeDisplayed={5}
                      next=">"
                      previous="<"
                      setCurrentPage={setCurrentPage}
                    />
                  </>
                ) : (
                  <div className="w-full flex items-center justify-center font-medium">
                    {t("no_data_found")}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Teamleavestable.propTypes = {
  setOpenPopup: PropTypes.func.isRequired,
  activeTab: PropTypes.func,
  tableColumn: PropTypes,array,
  setShowDetails: PropTypes.func,
  state: PropTypes.object,
  datas: PropTypes.any,
};
