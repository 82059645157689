import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdRefresh } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { IoIosArrowDown } from 'react-icons/io';

const FilterColumn = ({
  filterTitleLeave,
  filterTitleStatus,
  leaveDatafilter,
  statusDatafilter,
  setShowFilter,
  filterRef,
  leaveFilterTableDefaultValue,
  filters,
  handleColumnFilterChange,
  handleRefresh,
  handleApplyFilters,
  handleChangeLeave,
  tempLeaveType,
  tempStatusType,
  handleChangeStatus,
  filterTitleColumn,
}) => {
  const { t } = useTranslation();

  const [leaveOpen , setLeaveOpen] = useState(false);
  const [statusOpen , setStatusOpen] = useState(false);

  const handleToggleLeave = () => setLeaveOpen(!leaveOpen);
  const handleToggleStatus = () => setStatusOpen(!statusOpen);

  const onClose = () => {
    setShowFilter(false);
  };

  return (
    <divhandleFilter
      ref={filterRef}
      className=" bg-white absolute top-[3.5rem] right-[7rem]
              shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-50 p-4 border-[1px] rounded-md border-[#E2E8F0] "
    >
      <div className="flex justify-between items-center ">
        <div className="text-[#031B59] text-xl font-bold">Filters</div>
        <div onClick={onClose} className="text-xl cursor-pointer" data-testid="close-button">
          <IoClose />
        </div>
      </div>

      <div className="max-h-[16.9rem] max-w-[16.625rem] overflow-scroll custom_scroll">
        <div className="flex flex-col gap-2 mt-4 ">
          <div
            className="border p-2 cursor-pointer flex justify-between items-center"
            onClick={handleToggleLeave}
          >
            <div>{filterTitleLeave}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {leaveOpen &&
          leaveDatafilter?.map((leave) => (
            <div key={leave.id} className="flex items-center mb-2">
              <input
                id={leave.title}
                type="checkbox"
                className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
               checked:bg-[#031B59] h-[20px] w-[20px]"
                name={leave.title}
                onChange={() => handleChangeLeave(leave.title)}
                checked={tempLeaveType.includes(leave.title.split(" ")[0].toLowerCase())}
                data-testid={`leave-checkbox`}
              />
              <label className="text-blue-950" htmlFor={leave.title}>
                {leave.title}
              </label>
            </div>
          ))}

        </div>

        <div>
          <div
            className="border p-2 cursor-pointer flex justify-between items-center"
            onClick={handleToggleStatus}
          >
            <div>{filterTitleStatus}</div>
            <div>
              <IoIosArrowDown />
            </div>
          </div>
          {statusOpen && (
            <div className="p-2 my-1 border ">
              <div className="p-2 my-1 ">
                {statusDatafilter?.map((status) => (
                  <div key={status.id} className="flex items-center mb-2 ">
                    <input
                      id = {status.title}
                      type="checkbox"
                      className="mr-2 rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                      data-testid={`status-checkbox`}
                      name={status.title}
                      onChange={() => handleChangeStatus(status.title)}
                      checked={tempStatusType.includes(status.title.split(" ")[0].toLowerCase())}
                    />
                    <label className="text-blue-950" htmlFor={status.title}>
                      {status.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="text-[#031B59] font-bold text-lg py-2 ">{filterTitleColumn}</div>
        <div className="h-[11.9rem] w-full mt-2">
          {leaveFilterTableDefaultValue?.map((item) => (
            <div
              key={item.id}
              className="h-[2.25rem] w-[13.313rem] space-x-3 flex items-center"
            >
              <input
                className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                      checked:bg-[#031B59] h-[20px] w-[20px]"
                id={item.key}
                type="checkbox"
                value={item.header}
                checked={filters.includes(item.key)}
                onChange={handleColumnFilterChange}
              />
              <span
                className={`${
                  item.isChecked ? 'text-[#031B59]' : 'text-[#191919]'
                }`}
              >
                {item.header}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full h-fit flex items-center justify-between space-x-3 bg-transparent">
        <button
          className="h-[3.063rem] w-full basis-1/3 flex items-center justify-center border-[1.5px]
                  rounded-md enabled:border-[#031B59] enabled:text-[#031B59] disabled:border-[#A1A1A1]
                  disabled:text-[#A1A1A1] disabled:opacity-80"
          type="button"
          onClick={handleRefresh}
        >
          <MdRefresh className="h-5 w-5 flex items-center justify-center" />
        </button>
        <button
          className="h-[3.063rem] basis-2/3 p-2 border-[1.5px]  enabled:border-[#031B59] enabled:text-[#031B59]
                  disabled:border-[#A1A1A1] disabled:text-[#A1A1A1] disabled:opacity-80 font-medium rounded-md"
          type="button"
          onClick={handleApplyFilters}
        >
          {t('save')}
        </button>
      </div>
    </divhandleFilter>
  );
};

FilterColumn.propTypes = {
  filterRef: PropTypes.object.isRequired,
  leaveFilterTableDefaultValue: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  handleColumnFilterChange: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
  statusDatafilter: PropTypes.array.isRequired,
  leaveDatafilter: PropTypes.array.isRequired,
  setShowFilter: PropTypes.func.isRequired,
  filterTitleLeave: PropTypes.string.isRequired,
  filterTitleStatus: PropTypes.string.isRequired,
  handleChangeLeave: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  tempLeaveType: PropTypes.array.isRequired,
  tempStatusType: PropTypes.array.isRequired,
  filterTitleColumn: PropTypes.string.isRequired,
};

export default FilterColumn;
