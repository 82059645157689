import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import swalService from "utils/SwalServices";
import { useTranslation } from "react-i18next";
import {
  empTimeData,
  empTimeDataCreate
} from "redux/appThunk/Employee/profile";
import { employeeTypeObj } from "component/common/enum";

export default function EmployeesTime({ editState, setEditState, eyeId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const timezones = ["IST", "PST", "EST", "GMT", "CET", "JST"];
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const { employeetime } = useSelector((state) => state.employeeReducer);
  const [shiftTime, setShiftTime] = useState(employeetime?.totelHours);
  const [startTime, setStartTime] = useState(employeetime?.startTime);
  const [endTime, setEndTime] = useState(employeetime?.endTime);

  useEffect(() => {
    if (startTime) {
      const [startHours, startMinutes] = startTime.split(":").map(Number);
      const totalMinutes = startHours * 60 + startMinutes + shiftTime * 60;
      const endHours = Math.floor(totalMinutes / 60) % 24;
      const endMinutes = totalMinutes % 60;
      setEndTime(
        `${String(endHours).padStart(2, "0")}:${String(endMinutes).padStart(
          2,
          "0"
        )}`
      );
    }
  }, [startTime, shiftTime]);
  useEffect(() => {
    if (eyeId) {
      dispatch(empTimeData(eyeId));
    } else {
      dispatch(empTimeData(userData.id));
    }
  }, [dispatch]);

  const cancelButton = () => {
    setEditState(false);
    setShiftTime(employeetime?.totelHours);
    setStartTime(employeetime?.startTime);
    setEndTime(employeetime?.endTime);

    formik.setValues({
      startTime: employeetime?.startTime,
      endTime: employeetime?.endTime,
      totalWorkingHoursPerShift: employeetime?.totelHours,
      timeZone: employeetime?.timeZone,
    });
  };

  const initialValues = {
    startTime: employeetime?.startTime || "",
    endTime: employeetime?.endTime || "",
    totalWorkingHoursPerShift: employeetime?.totelHours || "",
    timeZone: employeetime?.timeZone || "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const result = await swalService.showWarning({
        icon: "warning",
        title: "Are you sure?",
        text: "You want to Update this!",
        showCancelButton: true,
        confirmButtonText: "Yes, Update it!",
        cancelButtonText: "No, cancel!",
      });

      if (result.value) {
        const id =
          userData?.role === employeeTypeObj.admin ? eyeId : userData?.id;
        dispatch(empTimeDataCreate(values, id));
        dispatch(empTimeData(id));
      }
    },
  });

  const { values, handleChange, handleSubmit } = formik;

  return (
    <div>
      <form
        className="flex flex-col m-3 p-3 flex-wrap bg-[#f2f6ff] justify-center"
        onSubmit={handleSubmit}
      >
        <div className="text-[#031B59]">{t("shiftduration")}</div>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <label className="text-[1rem] text-[grey]">
              {t("totalworkinghours")}
            </label>
            {editState === 2 ? (
              <input
                type="number"
                name="totalWorkingHoursPerShift"
                min="1"
                max="24"
                value={values.totalWorkingHoursPerShift}
                onChange={(e) => {
                  handleChange(e);
                  setShiftTime(Number(e.target.value));
                }}
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
              />
            ) : (
              <p className=" h-[2.625rem] text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                {shiftTime}
              </p>
            )}
          </div>
          <div>
            <label className="text-[1rem] text-[grey]" htmlFor="timeZone">
              {t("timezone")}
            </label>
            {editState === 2 ? (
              <select
                name="timeZone"
                value={values.timeZone}
                onChange={handleChange}
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919] bg-white"
              >
                {timezones.map((timezone) => (
                  <option key={timezone} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </select>
            ) : (
              <p className="h-[2.625rem] text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                {values.timeZone}
              </p>
            )}
          </div>
          <div>
            <label className="text-[1rem] text-[grey]" htmlFor="start_time">
              {t("starttime")}
            </label>
            {editState === 2 ? (
              <input
                type="time"
                name="startTime"
                value={values.startTime}
                onChange={(e) => {
                  handleChange(e);
                  setStartTime(e.target.value);
                }}
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919]"
              />
            ) : (
              <p className="h-[2.625rem] text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                {startTime}
              </p>
            )}
          </div>
          <div>
            <label className="text-[1rem] text-[grey]" htmlFor="end_time">
              {t("endtime")}
            </label>
            {editState === 2 ? (
              <input
                type="time"
                name="endTime"
                value={endTime}
                readOnly
                className="h-[2.625rem] w-full p-2 border border-[#E2E8F0] rounded text-[#191919] bg-white"
              />
            ) : (
              <p className="h-[2.625rem] text-[1rem] p-1 w-full border border-slate-200 rounded-[4px]">
                {endTime}
              </p>
            )}
          </div>
        </div>
        {editState === 2 && (
          <div className="flex gap-3 items-center p-2 pt-[1rem] justify-end bg-[#f2f6ff]">
            <button
              type="button"
              className="border border-[#031b59] p-2 w-20 rounded-3xl"
              onClick={cancelButton}
            >
              {t("cancel")}
            </button>
            <button
              className="bg-[#031b59] text-white p-2 w-20 rounded-3xl"
              type="submit"
            >
              {t("Save")}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

EmployeesTime.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.func.isRequired,
  eyeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
