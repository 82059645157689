import { useFormik } from "formik";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteDocument,
  fetchProfileAllDocuments,
  uploadIdentityDocuments
} from "redux/appThunk/Admin/profileDocument";
import PropTypes from "prop-types";
import { identitySchema } from "redux/validator/Profile/document";
import { BiCloudUpload } from "react-icons/bi";

const Identity = ({ setCurrentStep }) => {
  const [handelComponent, setHandelComponent] = useState(false);
  const path = useParams().id;
  const { id } = useParams();
  const [identity, setIdentity] = useState([]);
  const identiityData = useSelector(
    (select) => select.profileReducer.profileData?.background_verification
  );
  const formData = new FormData();
  const [handelButton, setHandelButton] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  const deleteFnc = (url) => {
    const updatedIdentity = identity.filter((doc) => doc.url !== url);
    setIdentity(updatedIdentity);

    const tempData = {
      url: url,
      column: "identity_check_documents",
    };

    setHandelButton(false);
    dispatch(deleteDocument(tempData, id, userData?.id));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfileAllDocuments(path ?? userData?.id));
  }, []);

  const initialIdentityValues = {
    documentName: "",
    Adhar: [],
  };

  const handelSubmitButton = async () => {
    if (!handelButton) {
      setCurrentStep(2);
    } else {
      identity.forEach((value) => {
        const file = new File(["foo"], value.name, {
          type: value.type,
        });
        formData.append(
          "background_verification[identity_check_documents][]",
          file
        );
      });
      dispatch(
        uploadIdentityDocuments(formData, path ?? userData?.id, setHandelButton)
      );
    }
  };

  const formik = useFormik({
    initialValues: initialIdentityValues,
    enableReinitialize: true,
    validationSchema: identitySchema,
    onSubmit: () => {
      handelSubmitButton();
    },
  });

  const { handleChange, values, handleSubmit, errors, touched } = formik;

  useEffect(() => {
    if (
      identiityData?.identity_check_documents &&
      identiityData.identity_check_documents.length > 0
    ) {
      setIdentity(identiityData.identity_check_documents);
      setHandelComponent(true);
      formik.setFieldValue("documentName", "Aadhar");
      formik.setFieldValue("Adhar", identiityData.identity_check_documents);
    } else {
      setIdentity([]);
      setHandelComponent(false);
      formik.setFieldValue("documentName", "");
      formik.setFieldValue("Adhar", []);
    }
  }, [identiityData]);

  const handleDocumentTypeChange = (e) => {
    handleChange(e);
    const value = e.target.value;
    const Name = e.target.name;
    const File = e.target.files;
    if (value) {
      setHandelComponent(true);
    }
    if (Name === "Adhar" && File && File.length > 0) {
      setHandelButton(true);
      const newIdentity = [...identity];
      for (let i = 0; i < File.length; i++) {
        newIdentity.push({
          name: File[i].name,
          file: File[i],
          url: URL.createObjectURL(File[i]),
        });
      }
      setIdentity(newIdentity);
      formik.setFieldValue("Adhar", newIdentity);
    }
  };

  return (
    <div>
      <div className="h-[350px] overflow-y-auto bg-white p-6 custom_scroll">
        <form onSubmit={handleSubmit}>
          <div className="mb-4 w-5/6">
            <label
              htmlFor="documentType"
              className="block text-sm font-medium text-gray-700"
            >
              {t("documentType")}
            </label>
            <select
              id="documentType"
              value={values?.documentName}
              name="documentName"
              className="mt-1 w-3/6 block p-2 border border-gray-300 rounded-md"
              onChange={handleDocumentTypeChange}
            >
              <option value="" disabled>
                {t("selectAnOption")}
              </option>
              <option value="Aadhar">{t("adhar")}</option>
            </select>
          </div>

          {handelComponent && (
            <>
              <div className="mt-10">
                <div>
                  <label className="font-semibold text-base">
                    {t("adhar")}
                  </label>
                </div>
                <label
                  role="button"
                  className="border h-[120px] border-dashed flex flex-col gap-3 justify-center
                  items-center rounded w-full"
                >
                  <input
                    type="file"
                    className="hidden"
                    name="Adhar"
                    onChange={handleDocumentTypeChange}
                  />
                  <BiCloudUpload className="text-[#A1A1A1] text-3xl" />
                  <div className="flex items-center">
                    <span>
                      <b className="text-[#031B59]">{t("Upload_File")}</b>
                    </span>
                  </div>
                </label>
                {errors.Adhar && touched.Adhar && (
                  <div className="text-red-500">{errors.Adhar}</div>
                )}

                {identity?.length > 0 &&
                  identity?.map((item, index) => (
                    <div
                      className="flex justify-between mt-3 w-6/6 p-2 border border-dotted border-gray-300
                  rounded-md items-center"
                      key={index}
                    >
                      <a href={item.url} target="blank">
                        {item.name}
                      </a>
                      <button type="button" onClick={() => deleteFnc(item.url)}>
                        <RxCross2 />
                      </button>
                    </div>
                  ))}
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="mt-10 h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E]
                    text-white rounded-full"
                  >
                    {handelButton === false ? t("next") : t("save")}
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Identity;

Identity.propTypes = {
  setCurrentStep: PropTypes.number,
  identiityData: PropTypes.object,
  user: PropTypes.object,
};
