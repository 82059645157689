/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isShowDialogBoxChange } from "../../../redux/actions/action";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../../api/apiUrl";
import { useTranslation } from "react-i18next";
import { filterTableDefaultValue, FilterUsers } from "../../../utils/Constants";
import Helper from "api/Helper";

import ToastServices from "ToastServices";
import AddEmployee from "./AddEmployee";
import EyeProfile from "./EyeProfile";
import Search from "component/common/accessControlUi/Search";
import useFetchUserData from "./useFetchUserData";
import {
  AddButton,
  ColumnButton,
  ImportButton
} from "component/common/accessControlUi/Button";
import FilterColumnEmployee from "./FilterColumnEmployee";
import EmployeeTable from "./EmployeeTable";
import Paginates from "./Paginat";
import PATHS from "routes/path";
import { GetPermission } from "hooks/newPermission";
import { mappedPermissionObj } from "hooks/usePermission";

export default function EmployeeListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [employeesType, setEmployeesType] = useState(FilterUsers[0].header);
  const [filter, setFilter] = useState(false);
  const [id, setId] = useState();
  const [enable, setEnable] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [openOptions, setOpenOptions] = useState(false);
  const [filterTableValue, setFilterTableValue] = useState(
    filterTableDefaultValue
  );
  const [finalFilteredValue, setFinalFiteredValue] = useState([]);
  const [addemployeePop, setAddEmployeePop] = useState(false);
  const [onSpotChange, setOnSpotChange] = useState(false);
  const [submitOnce, setSubmitOnce] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const isOpenDialogBoxToggle = useSelector(
    (state) => state.popUpDialogBox.isShowDialogBox
  );
  const { isLoading, pageCount } = useSelector((state) => state.leaveReducer);
  const { allUser } = useSelector((state) => state.profileReducer);
  const { userPermission } = GetPermission(mappedPermissionObj.User) || {};
  const [itemsPagination, setItemPagination] = useState(10);

  const handleDisable = async (id) => {
    if (!enable) {
      const path = apiUrl.disable + id;
      try {
        await Helper.put({}, path);
        dispatch(isShowDialogBoxChange(false));
      } catch (error) {
        ToastServices.showToast({
          message: "Error!",
          type: "error",
          autoClose: 3000,
        });
      }
      ToastServices.showToast({
        message: "User has been Disabled !",
        type: "success",
        autoClose: 3000,
      });
      setOnSpotChange(!onSpotChange);
      setSubmitOnce(false);
    } else {
      const tempObj = {
        id,
      };
      const path = `users/${id}/activate_user/`;
      try {
        await Helper.patch(tempObj, path, false);
        dispatch(isShowDialogBoxChange(false));
        setEnable(false);
      } catch (error) {
        ToastServices.showToast({
          message: "Error!",
          type: "error",
          autoClose: 3000,
        });
      }
      ToastServices.showToast({
        message: "User has been Enabled !",
        type: "success",
        autoClose: 3000,
      });
      setOnSpotChange(!onSpotChange);
      setSubmitOnce(false);
    }
  };

  const handleCloseDialogBox = () => {
    dispatch(isShowDialogBoxChange(false));
    setEnable(false);
  };

  const handleClick = (e, bool) => {
    const id = Number(e.target.id);
    const updatedFilterTableValue = filterTableValue?.map((item) =>
      item.id === id ? { ...item, isChecked: bool } : item
    );
    setFilterTableValue(updatedFilterTableValue);
  };

  const handleSave = () => {
    setFilter(false);

    const checkedFilters = filterTableValue.filter(
      (item) => item.isChecked && !item?.disable
    );

    setFinalFiteredValue(checkedFilters);
  };

  const handleRefresh = () => {
    setFilterTableValue(filterTableDefaultValue);
    setFinalFiteredValue(
      filterTableDefaultValue.filter((item) => item.isChecked && !item?.disable)
    );
  };

  useFetchUserData({
    query: searchItem,
    itemsPerPage: itemsPagination,
    currentPage,
    employeesType,
    onSpotChange,
    openPopUp,
  });

  return (
    <div
      className="w-[96%] h-[calc(100vh-5.3rem)] bg-white space-y-4 flex flex-col p-5 pb-1
      rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] "
    >
      {addemployeePop && <AddEmployee setAddEmployeePop={setAddEmployeePop} />}

      {openPopUp && <EyeProfile setOpenPopUp={setOpenPopUp} />}
      <div className="w-full h-12 bg-white flex justify-between relative">
        <div className="flex justify-center items-center">
          <h2 className="font-extrabold text-xl text-[#031B59]">
            {t("basic_detail")}
          </h2>
        </div>
        {isOpenDialogBoxToggle && (
          <div
            className="w-full h-full flex items-center
          justify-center fixed top-0 left-0 z-50  bg-[rgba(3,27,89,.2)] op"
          >
            <div
              className="w-[39.5rem] h-[13.75rem] z-60 flex flex-col rounded-[0.5rem]
            items-center gap-[1.5rem] justify-center bg-white drop-shadow-lg"
            >
              <button onClick={handleCloseDialogBox}>
                <GrFormClose className="flex absolute gap-[0.625rem] w-[2rem] h-[2rem] top-[1rem] right-[1rem] text-[#686868]" />
              </button>
              <hr />
              <div
                className="w-[33.5rem] h-[4rem] color-[#191919] font-inter font-bold
              text-2xl leading-8 tracking-[0.0075rem]"
              >
                {enable ? t("enable_emp_message") : t("disable_emp_message")}
              </div>
              <div
                className="flex w-[37.5rem] p-[1.5rem_2.5rem] justify-end items-center gap-[1rem] rounded-br-[2rem]
              border-t-[1px] border-[#E2E8F0] bg-white"
              >
                <button>
                  <div
                    onClick={() => handleCloseDialogBox()}
                    className="flex w-[7.5rem] h-[3.5rem] p-[1rem] justify-center items-center
                    gap-[0.5rem] rounded-[0.5rem] text-[#A1A1A1] font-bold"
                  >
                    {t("cancel")}
                  </div>
                </button>
                <button
                  onClick={() => {
                    handleDisable(id);
                    setSubmitOnce(true);
                  }}
                >
                  <div
                    className={`flex h-[3.5rem] p-[1rem_1.875rem] justify-center items-center gap-[0.5rem]
                  rounded-[2.5rem] ${submitOnce ? "bg-[#6a7daf]" : " bg-[#031B59]"
          } font-inter text-[1rem] font-bold leading-normal text-white`}
                  >
                    {enable ? t("enable") : t("disable")}
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-center space-x-4">
          {userPermission?.can_view_all_user && (
            <Search
              searchItem={searchItem}
              setSearchItem={setSearchItem}
            />
          )}
          {userPermission?.can_view_all_user && (
            <ColumnButton
              title={"Add Column"}
              onToggle={() => {
                setFilter(!filter);
              }}
            />
          )}
          {filter && (
            <FilterColumnEmployee
              showFilter={filter}
              setFilter={setFilter}
              setOpenOptions={setOpenOptions}
              openOptions={openOptions}
              employeesType={employeesType}
              setEmployeesType={setEmployeesType}
              setSearchItem={setSearchItem}
              setCurrentPage={setCurrentPage}
              filterTableValue={filterTableValue}
              onChange={handleClick}
              onSave={handleSave}
              onClose={() => {
                setFilter(false);
              }}
              onRefresh={handleRefresh}
            />
          )}
          {userPermission?.can_view_all_user && (
            <ImportButton
              title={t("import")}
              onClick={() => {
                navigate(PATHS.employee.employeeCSV);
              }}
            />
          )}
          {(userPermission?.can_view_all_user ||
            userPermission?.can_create_user) && (
            <AddButton
              title={t("addemployees")}
              onClick={() => {
                setAddEmployeePop(true);
              }}
            />
          )}
        </div>
      </div>
      <div className="text-[#031B59] text-[1.125rem]">{t("all_employees")}</div>

      <EmployeeTable
        setId={setId}
        finalFilteredValue={finalFilteredValue}
        setOpenPopUp={setOpenPopUp}
        onSpotChange={onSpotChange}
        setOnSpotChange={setOnSpotChange}
        employeesType={employeesType}
        setEnable={setEnable}
        currentPage={currentPage}
        itemsPagination={itemsPagination}
        setCurrentPage={setCurrentPage}
        userPermission={userPermission}
      />
      {!isLoading && (
        <div className="w-full bg-white flex justify-between items-center">
          {allUser?.length > 0 ? (
            <>
              <div className="text-[#031B59] font-medium">
                Showing {currentPage} of {pageCount}
              </div>{" "}
              <div >
              </div>
              <Paginates
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={1}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
                setItemPerPage={setItemPagination}
                itemPerPage={itemsPagination}
              />
            </>
          ) : (
            <div className="w-full flex items-center justify-center font-medium">
              {t("no_data_found")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
