import React, { useEffect, useRef, useState } from "react";
import MyProject1Svg from "svgComponents/myTeam/MyProject1Svg";
import MyProject2Svg from "svgComponents/myTeam/MyProject2Svg";
import MyProject3Svg from "svgComponents/myTeam/MyProject3Svg";
import Human from "svgComponents/myTeam/Human";
import Popup from "component/common/Popup";
import Close from "svgComponents/myTeam/Close";
import PropTypes from "prop-types";
import {
  cloneProject,
  deleteProject,
  editMyTeam
} from "redux/appThunk/Admin/myTeams";
import { useDispatch } from "react-redux";
import ProfileAvtar from "component/common/ProfileAvtar";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import { sendProjectData } from "redux/appThunk/Admin/project";

const ProjectCard = ({
  projectId,
  projectName,
  projectDescription,
  projectResources,
  projectResourceLength,
  setMembers,
  setId,
  setSearchItem,
  allUsers,
  project,
  setDataProject,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCloneOpen, setIsCloneOpen] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [editableProjectName, setEditableProjectName] = useState(projectName);
  const [editableDescription, setEditableDescription] = useState(projectDescription);
  const { t } = useTranslation();
  const dropDownRef = useRef(null);
  const dispatch = useDispatch();

  const AssignPopup = () => {
    setShowAssignPopup(!showAssignPopup);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleEdit = () => {
    setIsEditOpen(!isEditOpen);
  };

  const toggleDelete = () => {
    setShowPopUp(!showPopUp);
  };
  const toggleClone = () => {
    setIsCloneOpen(!isCloneOpen);
  };

  const handleEditButton = () => {
    const requestPayload = {
      name: editableProjectName,
      description: editableDescription,
    };
    dispatch(editMyTeam(projectId, requestPayload));
    toggleEdit();
  };

  const handleDelete = () => {
    dispatch(deleteProject(projectId));
    toggleDelete();
  };

  const handleClone = () => {
    dispatch(cloneProject(projectId));
    toggleClone();
  };

  const handleMembers = () => {
    setMembers(true);
    setId(projectId);
    setDataProject(project);
  };
  const handleSendData = (item) => {
    const jsonObj = {
      "lead_id": item?.id,
    };
    dispatch(sendProjectData(jsonObj, projectId));
    setShowAssignPopup(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  return (
    <div className="border w-full rounded-xl p-8 m-4 relative">
      <div className="flex">
        <div className="flex items-center">
          <div>
            <Human />
          </div>
          <div className="w-[75rem] pl-4 text-[1.25rem] font-bold text-[#031B59]">
            <h2>{projectName}</h2>
          </div>
          <div className="mr-4">
            <p className="text-sm text-gray-500">Project Lead</p>

            <button className="text-lg font-semibold text-blue-800 whitespace-nowrap" onClick={AssignPopup}>
              {project?.projectLead?.full_name || ("Assign")}
            </button>

            {showAssignPopup &&
              (
                <div className="bg-white p-2 absolute right-52 border
                shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-30 rounded-lg">
                  <div className="relative w-full">
                    <input
                      type="search"
                      className="w-full h-10 px-4 pr-10 border outline-none border-[#E2E8F0] placeholder:text-[#A1A1A1]"
                      placeholder="search"
                      onChange={(e) => setSearchItem(e.target.value)}
                    />
                    <button>
                      <BiSearch className="absolute top-1/2 right-4 transform -translate-y-1/2 h-6 w-6 text-[#A1A1A1]" />
                    </button>
                  </div>
                  <div className="flex flex-col w-[325px]">
                    <div className="w-full h-[20rem] overflow-y-auto flex justify-between gap-2 p-2 border-b border-gray-200 flex-col">
                      {allUsers?.map((item) => (
                        <div className="w-auto flex gap-x-[0.44rem]" key={item} onClick={() => handleSendData(item)}>
                          <div className="w-fit min-w-fit flex items-center gap-2">
                            <ProfileAvtar name={item?.name} src="" />
                          </div>
                          <div className="flex flex-col justify-center gap-1">
                            <p className="text-[#191919] text-sm font-normal leading-none">{item?.name}</p>
                            <p className="text-[#A1A1A1] text-xs font-normal leading-none">{item?.email}</p>
                          </div>
                        </div>

                      ))}
                    </div>
                  </div>
                </div>
              )
            }
          </div>

        </div>

        <div className="flex relative gap-[0.625rem]">
          <div
            className="flex w-[2.375rem] h-[2.375rem] py-[0.29081rem]
              px-[0.7755rem] justify-center items-center gap-[0.38775rem]
              flex-shrink-0 rounded-[1.45406rem] border"
          >
            <button
              data-testid="membersButton"
              onClick={handleMembers}>
              <MyProject1Svg />
            </button>
          </div>

          <div
            className="flex w-[2.375rem] h-[2.375rem] py-[0.29081rem]
           px-[0.7755rem] justify-center items-center gap-[0.38775rem]
           flex-shrink-0 rounded-[1.45406rem] border "
          >
            <button
              data-testid="toggleModal"
              onClick={toggleModal}>
              <MyProject2Svg />
            </button>
            {isModalOpen && (
              <div
                className="w-full h-full flex items-center justify-end
                fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
              >
                <div
                  className="max-w-[40%] h-full bg-white flex flex-col
                  p-8 space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]
                  transitionRight "
                >
                  <div className="w-full py-6 bg-white border-b border-slate-200 flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="text-blue-950 text-xl font-extrabold leading-normal">
                        {t("description")}
                      </div>
                    </div>
                    <button
                      onClick={toggleModal}
                      data-testid="toggleModal"
                      className="text-gray-700 hover:text-gray-900 "
                    >
                      <Close className="cursor-pointer" />
                    </button>
                  </div>

                  <div className="w-[689px] flex-grow flex-col justify-start items-center gap-4 flex">
                    <div
                      className="w-full flex-grow flex-col justify-start
                    items-start gap-1 flex custom_scroll overflow-x-hidden"
                    >
                      <div
                        className="w-[60rem] h-7 text-blue-950 text-base
                      font-extrabold font-['Roboto'] leading-snug pr-[47rem] "
                      >
                        {projectName}
                      </div>
                      <div className="w-full flex-grow p-2.5 bg-white rounded border border-slate-200">
                        {editableDescription}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isCloneOpen && (
            <Popup
              title={t("Are you sure you want to clone “UI/UX Team”?")}
              submitBtnText={t("Clone")}
              popupBox={() => setIsCloneOpen(false)}
              handleSubmit={handleClone}
            />
          )}

          {showPopUp && (
            <Popup
              title={t("Are you sure you want to delete “UI/UX Team”?")}
              submitBtnText={t("Delete")}
              popupBox={toggleDelete}
              handleSubmit={handleDelete}
            />
          )}

          {isEditOpen && (
            <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)]">
              <div
                className="max-w-[40%] h-full bg-white flex-flex-col
               space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
              >
                <div
                  className="w-[769px] h-[900px] bg-white
                rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-[32px]
                shadow flex-col justify-start items-center gap-6 inline-flex"
                >
                  <div
                    className="w-full pl-10 pr-6 py-6 bg-white
                  border-b border-slate-200 flex justify-between items-center"
                  >
                    <div className="flex items-center">
                      <div className="text-blue-950 text-xl font-extrabold leading-normal">
                        {t("edit")}
                      </div>
                    </div>
                    <button
                      onClick={toggleEdit}
                      data-testid="toggleEdit"
                      role="button"
                      className="text-gray-700 hover:text-gray-900 pr-[2rem]"
                    >
                      <Close className="cursor-pointer" />
                    </button>
                  </div>

                  <div className="w-[689px] flex-grow flex-col justify-start items-center gap-4 flex">
                    <div className="w-full flex-col justify-start items-start gap-1 flex">
                      <div className="text-zinc-800 text-base font-medium leading-snug">
                        {t("teamName")}
                      </div>

                      <input
                        type="text"
                        value={editableProjectName}
                        onChange={(e) => setEditableProjectName(e.target.value)}
                        className="w-full p-2.5 bg-white rounded
                        border border-slate-200 text-zinc-900 text-base font-normal"
                      />
                    </div>
                    <div className="w-full flex-grow flex-col justify-start items-start gap-1 flex">
                      <div className="text-zinc-800 text-base font-medium leading-snug">
                        {t("description")}
                      </div>
                      <div className="w-full flex-grow p-2.5 bg-white rounded border border-slate-200">
                        <textarea
                          value={editableDescription}
                          onChange={(e) =>
                            setEditableDescription(e.target.value)
                          }
                          className="w-full p-2.5 bg-white rounded border
                           border-slate-200 text-base font-normal
                           resize-none h-[34rem]"
                          rows="10"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="w-full px-14 py-6 bg-white rounded-lg
                  border-t border-slate-200 flex justify-end gap-4"
                  >
                    <button
                      onClick={toggleEdit}
                      className="text-stone-500 text-base"
                    >
                      {t("cancel")}
                    </button>
                    <button
                      className="w-[95px] px-[30px] py-4 bg-blue-950
                    rounded-[40px] text-white text-base font-bold"
                      onClick={handleEditButton}
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="flex w-[2.375rem] h-[2.375rem] py-[0.29081rem]
            px-[0.7755rem] justify-center items-center gap-[0.38775rem]
             flex-shrink-0 rounded-[1.45406rem] border"
            onClick={toggleDropdown}
            data-testid="toggleDropdown"
            ref={dropDownRef}
          >
            <button>
              <MyProject3Svg />
              {isOpen && (
                <div
                  data-testid="dropdown-menu"
                  className="origin-top-right absolute right-0
                mt-2 w-40 rounded-md shadow-lg bg-white
                ring-1 ring-black ring-opacity-5 z-50"
                >
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <button
                      className="block w-full px-4 py-2 text-left text-sm
                       text-gray-700 hover:bg-gray-100
                        hover:text-gray-900"
                      role="menuitem "
                      data-testid="toggleEdit"
                      onClick={toggleEdit}
                    >
                      {t("edit")}
                    </button>
                    <button
                      className="block w-full px-4 py-2 text-left
                      text-sm text-gray-700 hover:bg-gray-100
                       hover:text-gray-900"
                      role="menuitem"
                      data-testid="toggleClone"
                      onClick={toggleClone}
                    >
                      {t("clone")}
                    </button>

                    <button
                      className="block w-full px-4 py-2 text-left text-sm
                       text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      data-testid="toggleDelete"
                      onClick={toggleDelete}
                    >
                      {t("deleteTeam")}
                    </button>
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="h-fit flex justify-between">
        <div
          className="w-[532px] h-[10rem] flex p-5 pl-0 justify-evenly
        align-middle custom_scroll overflow-x-hidden border border-dashed"
        >
          <div className="w-[492px] h-[132px] flex gap-3 flex-wrap">
            {projectResources.map((item) => (
              <ProfileAvtar
                key={item.employeeName}
                name={item.employeeName || "User"}
                src={item?.profilePictureUrl}
              />
            ))}
          </div>
        </div>

        <div className="m-4 w-[40rem] pr-[4rem] bg-[#F9FAFB]">
          <div className=" pb-4 text-blue-950 text-sm  font-semibold">
            {t("projectDescription")}
          </div>
          <div className=" text-gray-500 text-base font-normal text-wrap">
            <p>{projectDescription}</p>
          </div>
          <div className="pt-4 text-orange-500  text-xs font-normal">
            {projectResourceLength} {t("teamMembers")}
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  setDataProject: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  allUsers: PropTypes.any.isRequired,
  setSearchItem: PropTypes.any.isRequired,
  setId: PropTypes.number.isRequired,
  setMembers: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  project: PropTypes.any.isRequired,
  projectDescription: PropTypes.string.isRequired,
  projectResources: PropTypes.arrayOf(
    PropTypes.shape({
      employeeName: PropTypes.string,
      profilePictureUrl: PropTypes.string,
    })
  ).isRequired,
  projectResourceLength: PropTypes.number.isRequired,
};

export default ProjectCard;
