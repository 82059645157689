/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import {
  isLoading,
  setEmployeeData,
  setEmployeeLead,
  setMyTimesheetData,
  setPageCount,
  setStatusByNameAndDate
} from "redux/actions/action";
import {
  formatStatusListResponse,
  formatStatuslist,
  formatStatuslistData,
  formatStatuslistNameAndDate,
  formatTimesheetData
} from "redux/selector/Admin/status";

export const fetchstatus =
  (currentPage, perPage, searchName) => async (dispatch) => {
    try {
      dispatch(isLoading(true));
      let path;
      if (searchName) {
        path = `${apiUrl.allStatus}?name=${searchName}&page=${currentPage}&per_page=${perPage}`;
      } else {
        path = `${apiUrl.allStatus}?page=${currentPage}&per_page=${perPage}`;
      }
      const { response } = await Helper.get(path);
      const formattedResponse = formatStatusListResponse(response);
      dispatch(setStatusByNameAndDate(formattedResponse));
      dispatch(setPageCount(response?.pagination_data?.total_pages));
      dispatch(isLoading(false));
    } catch (error) {
      ToastServices.showToast({
        message: error,
        type: "error",
      });
    }
  };

// for individual user
export const getAllTimesheet =
  ({ name, date, currentPage, desgination }) =>
    async (dispatch) => {
      dispatch(isLoading(true));
      try {
        let path = `${apiUrl.allStatus}`;

        // Append query parameters conditionally
        const queryParams = [];
        if (name)
          queryParams.push(`name=${encodeURIComponent(name)}`);
        if (date)
          queryParams.push(`date=${encodeURIComponent(date)}`);
        if (desgination)
          queryParams.push(`designations=[${encodeURIComponent(desgination)}]`);
        if (currentPage)
          queryParams.push(`page=${encodeURIComponent(currentPage)}`);
        // if (id) queryParams.push(`id=${encodeURIComponent(id)}`);
        // If there are any query parameters, append them to the path
        if (queryParams.length > 0) {
          path += `?${queryParams.join("&")}`;
        }

        const { response } = await Helper.get(path);
        const formattedResponse = formatStatuslistNameAndDate(response.statuses);
        dispatch(setStatusByNameAndDate(formattedResponse));
        dispatch(setPageCount(response?.pagination_data?.total_pages));
        dispatch(isLoading(false));
      } catch (error) {
        ToastServices.showToast({
          message: error,
          type: "error",
        });
      }
    };

export const getTeamTimesheet =
  ({ name, date, currentPage, id }) =>
    async (dispatch) => {
      dispatch(isLoading(true));
      try {
        let path = `${apiUrl.teamTimesheet}`;

        // Append query parameters conditionally
        const queryParams = [];
        if (id) queryParams.push(`id=${encodeURIComponent(id)}`);
        if (name) queryParams.push(`name=${encodeURIComponent(name)}`);
        if (date) queryParams.push(`date=${encodeURIComponent(date)}`);
        if (currentPage)
          queryParams.push(`page=${encodeURIComponent(currentPage)}`);

        // If there are any query parameters, append them to the path
        if (queryParams.length > 0) {
          path += `?${queryParams.join("&")}`;
        }

        const { response } = await Helper.get(path);
        const updatedresponse = response.users.map((user) => (
          {
            designation: user?.designation ?? "-",
            fullName: user?.full_name ?? "User",
            id: user?.id || null,
            employeeId: user?.id,
            statuses: user?.statuses,
          }));
        dispatch(setStatusByNameAndDate(updatedresponse));
        dispatch(setPageCount(response?.pagination_data?.total_pages));
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        ToastServices.showToast({
          message: error,
          type: "error",
        });
      }
    };

export const getUserTimesheet =
  ({ id, currentPage,itemsPerPage, date, name }) =>
    async (dispatch) => {
      try {
        dispatch(isLoading(true));
        let path = `${apiUrl.allStatus}/${id}`;
        // Append query parameters conditionally
        const queryParams = [];
        if (name) queryParams.push(`name=${encodeURIComponent(name)}`);
        if (date) queryParams.push(`date=${encodeURIComponent(date)}`);
        if (currentPage)
          queryParams.push(`page=${encodeURIComponent(currentPage)}`);
        if (itemsPerPage)
          queryParams.push(`per_page=${encodeURIComponent(itemsPerPage)}`);

        if (queryParams.length > 0) {
          path += `?${queryParams.join("&")}`;
        }

        const { response, status } = await Helper.get(path);
        if (status === 200 || status === 201) {
          const formattedResponse = formatTimesheetData(response.statuses);
          dispatch(setMyTimesheetData(formattedResponse));
          dispatch(setPageCount(response?.totalPages));
        }
        else {
          dispatch(setMyTimesheetData([]));
        }
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        dispatch(setMyTimesheetData([]));
      }
    };

export const fetchEmployeeData2 =
  (id, currentPage, searchDate) => async (dispatch) => {
    try {
      const path = `time_sheets/${id}?page=${currentPage}&per_page=10&date=${searchDate}`;
      const { response } = await Helper.get(path);
      const formattedResponse = formatStatuslist(response);

      dispatch(setEmployeeData(formattedResponse));
    } catch (error) {
      ToastServices.showToast({
        message: "Error during fetch data !",
        type: "error",
        autoClose: 3000,
      });
    }
  };

// to get team timesheet data
export const fetchEmployeeDatas = () => async (dispatch) => {
  const id = JSON.parse(localStorage.getItem("userLoginToken")).id ?? null;
  try {
    dispatch(isLoading(true));
    const path = `time_sheets/team_timesheets?id=${id}&page=1&per_page=1`;
    const { response } = await Helper.get(path);
    const formattedResponse = formatStatuslistData(response);
    dispatch(setEmployeeLead(formattedResponse));
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: "Error during fetch data !",
      type: "error",
      autoClose: 3000,
    });
  }
};
