import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddButton } from "component/common/accessControlUi/Button";
import CustomTable from "component/common/table/CustomTable";
import { useTranslation } from "react-i18next";
import AddLeave from "./AddLeaveData";
import Paginate from "component/admin/Employee/Paginate";
import { employeeDetails } from "./column";
import { HiArrowNarrowLeft } from "react-icons/hi";
import PropTypes from "prop-types";
import useFetchLeaveData from "./UseFetchLeaveData";

const OrganisationleaveDetails = (
  {
    setShowDetails,
    datas,
    columnData,
  }) => {
  const { t } = useTranslation();
  const [addLeavePop, setAddLeavePop] = useState(false);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { allLeaves, pageCount, isLoading } = useSelector(
    (state) => state.leaveReducer
  );

  useFetchLeaveData({
    currentPage: currentPage,
    itemsPerPage: itemsPerPage,
    userId: datas?.userId,
  });

  return (
    <>
      <div className="w-full h-fit overflow-hidden">
        <div>
          <div
            className="w-[97.5%] h-[calc(100vh-10rem)] bg-white space-y-4
        flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mb-3 ml-3 mt-4"
          >
            <div className="flex justify-between mb-4 items-center">
              <div>
                <h2 className="font-extrabold text-xl text-[#031B59] flex items-center">
                  <HiArrowNarrowLeft
                    className={`mr-2  opacity-50" : "cursor-pointer" }`}
                    onClick={() => setShowDetails(false) }
                  />
                  {datas?.name ? datas?.name : t("N/A")}
                </h2>
                <div className="pl-7 text-gray-400">
                  {datas?.designation ? datas?.designation : t("N/A")}
                </div>
              </div>
              <div className="flex items-center space-x-4">
                {/* {userPermission?.create && ( */}
                <AddButton
                  // currentResource={mappedPermissionObj.Leave}// All permission related comments TODO Hemraj Mahawar
                  title={t("Apply Leave")}
                  onClick={() => setAddLeavePop(true)}
                />
                {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}
              </div>
            </div>

            <div
              className={`overflow-y-scroll h-full ${isLoading ? "custom_scroller" : "custom_scroll"}`}
            >
              <CustomTable
                newColumn={["Status", "Action"]}
                columns={[...employeeDetails, ...columnData]}
                data={allLeaves}
                isLoading={isLoading}
              />
            </div>
            {/* {(userPermission?.view || secondPermission) && ( */}
            <div className="w-full h-12 bg-white flex justify-between items-center">
              {!isLoading && (
                <>
                  <div className="w-full flex flex-wrap justify-between items-center mb-4 ">
                    <div className="text-[#031B59] bg-white font-medium ">
                      {t("Showing")} {currentPage} {t("of")} {pageCount}
                    </div>
                    <Paginate
                      currentPage={currentPage}
                      initialPageCount={pageCount}
                      pageRangeDisplayed={5}
                      next=">"
                      previous="<"
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </>
              )}
              {allLeaves.length === 0 && (
                <div className="w-full flex items-center justify-center font-medium">
                  {t("no_data_found")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganisationleaveDetails;

OrganisationleaveDetails.propTypes = {
  activeTab: PropTypes.func,
  setShowDetails: PropTypes.func,
  setPopupTitle: PropTypes.func,
  datas: PropTypes.any,
  showDetails: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleOpens: PropTypes.func,
  setUserIdAction: PropTypes.func,
  openPopup: PropTypes.bool,
  columnData: PropTypes.array,
  searchItem: PropTypes.any,
  searchName: PropTypes.any,
};
