import PropTypes from "prop-types";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import swalService from "utils/SwalServices";
import { changePassword } from "redux/appThunk/Employee/profile";
import { employeeTypeObj } from "component/common/enum";
import { changePasswordValidation } from "redux/validator/Profile/changePass";
import PasswordForm from "component/common/EmpolyeeForm/PasswordForm";

export default function ResetPassword({
  accordionOpen,
  setAccordionOpen,
  eyeId,
}) {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const [passwordVisible, setPasswordVisible] = useState([false, false, false]);
  const shouldRender = location.pathname === "/profileBasics/changePassword";
  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisible(updatedVisibility);
  };
  const Formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async () => {
      const jsonObj = {
        currentPassword:
          userData?.role === employeeTypeObj.employee ? values?.currentPassword : "",
        newPassword: values?.newPassword,
        confirmPassword: values?.confirmPassword,
      };
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this!",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel!",
        })
        .then(async (result) => {
          if (result.value) {
            let id;
            if (userData?.role === employeeTypeObj.admin) {
              id = eyeId;
            }
            if (userData?.role === employeeTypeObj.employee) {
              id = userData?.id;
            }

            dispatch(changePassword(id, jsonObj, setAccordionOpen, setAccordionOpen, setReload));
            values.currentPassword = "";
            values.newPassword = "";
            values.confirmPassword = "";

          }
        });
    },
  });

  const handleCancel = () => {
    setAccordionOpen(false);
    values.newPassword = "";
    values.confirmPassword = "";
  };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    Formik;

  return (
    <div>
      <div>
        {accordionOpen === 7 && (
          <PasswordForm
            validationSchema={changePasswordValidation}
            onSubmit={handleSubmit}
            userData={userData}
            togglePasswordVisibility={togglePasswordVisibility}
            passwordVisible={passwordVisible}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            reload={reload}
            handleCancel={handleCancel}
            shouldRender={shouldRender}
          />
        )}
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  accordionOpen: PropTypes.any,
  setAccordionOpen: PropTypes.any,
  eyeId: PropTypes.string.isRequired,
};
