/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { getAllTimesheet } from "redux/appThunk/Admin/status";
import { IoFilter } from "react-icons/io5";
import useDebounce from "hooks/useDebounce";
import { teamTimeSheets } from "./columnTimeSheet";
import Search from "component/common/accessControlUi/Search";
import Paginate from "component/admin/Employee/Paginate";
import CustomTable from "component/common/table/CustomTable";
import {
  fetchDepartments,
  fetchDesignations
} from "redux/appThunk/Admin/department";
import AttendanceFilter from "../employee/attendance/AttendanceFilter";
import TeamEmployee from "./TeamEmployee";

const UsersTimesheet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const { searchData, isLoading, pageCount } = useSelector(
    (state) => state.statusReducer
  );
  const { data } = useSelector((state) => state.departmentReducer);
  const [state, setState] = useState({
    desgination: [],
    tableData: null,
    teamData: false,
    searchName: null,
    departmentId: null,
    filter: false,
    loader: false,
    dataFilter: [],
    dataFilterValue: [],
    selectedDesignation: [],
    localSelectedDepartment: [],
    selectedDepartment: [],
    localSelectedDesignation: [],
    localSelectedDepartmentTitle: [],
    localSelectedDesignationTitle: [],
    userName: "",
    filterTableValue: [],
    currentPage: 1,
    columnFilters: new Set(["fullName", "designation", "action"]),
  });

  const searchFor = useDebounce(state.searchName, 500);
  const { id } = JSON.parse(localStorage?.getItem("userLoginToken")) || {};

  const setCurrentPage = (value) => {
    setState({...state,currentPage: value});
  };

  useEffect(() => {
    dispatch(fetchDesignations());
    dispatch(fetchDepartments(1, 10, "", () => {}, ""));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllTimesheet({
        name: searchFor,
        designation: state.desgination,
        page: state.currentPage,
      })
    );
  }, [state.currentPage, searchFor, state.desgination, dispatch]);

  useEffect(() => {
    const dataFilter = data?.data?.map((item) => ({
      id: item?.id,
      title: item?.department,
    }));
    setState((prev) => ({ ...prev, dataFilter }));
  }, [data]);

  useEffect(() => {
    const selectedDepartment = data?.data?.find(
      (item) => item?.id === state.departmentId
    );
    const dataFilterValue = selectedDepartment?.designation?.map((item) => ({
      id: item?.id,
      title: item?.designation,
    }));
    setState((prev) => ({ ...prev, dataFilterValue }));
  }, [state.departmentId]);

  const handleOpen = (job) => {
    setState((prev) => ({
      ...prev,
      teamData: true,
      tableData: job,
    }));
  };

  const handleDepartmentChange = (resource) => {
    setState((prev) => ({
      ...prev,
      departmentId: resource?.id,
      localSelectedDepartment: prev.localSelectedDepartment.includes(
        resource.id
      )
        ? prev.localSelectedDepartment.filter((item) => item !== resource.id)
        : [...prev.localSelectedDepartment, resource.id],
      localSelectedDepartmentTitle: prev.localSelectedDepartmentTitle.includes(
        resource.title
      )
        ? prev.localSelectedDepartmentTitle.filter(
          (item) => item !== resource.title
        )
        : [...prev.localSelectedDepartmentTitle, resource.title],
    }));
  };
  const handleDesignationChange = (project) => {
    setState((prev) => ({
      ...prev,
      localSelectedDesignation: prev.localSelectedDesignation.includes(
        project.id
      )
        ? prev.localSelectedDesignation.filter((item) => item !== project.id)
        : [...prev.localSelectedDesignation, project.id],
      localSelectedDesignationTitle:
        prev.localSelectedDesignationTitle.includes(project.title)
          ? prev.localSelectedDesignationTitle.filter(
            (item) => item !== project.title
          )
          : [...prev.localSelectedDesignationTitle, project.title],
    }));
  };

  const handleApply = () => {
    dispatch(
      getAllTimesheet({
        id,
        currentPage: state.currentPage,
        desgination: state.localSelectedDesignationTitle,
      })
    );
    setState((prev) => ({ ...prev, filter: false }));
  };

  const handleRefresh = () => {
    setState((prev) => ({
      ...prev,
      localSelectedDepartment: [],
      localSelectedDepartmentTitle: [],
      localSelectedDesignation: [],
      localSelectedDesignationTitle: [],
      selectedDepartment: [],
      selectedDesignation: [],
      departmentId: null,
    }));
    dispatch(
      getAllTimesheet({
        id,
        currentPage: 1,
        designation: [],
        department: [],
      })
    );
  };

  const handleCloseFilter = (value) => {
    setState((prev) => ({ ...prev, filter: value}));
  };

  return (
    <>
      {state.teamData ? (
        <TeamEmployee
          userDetail={state.tableData}
          setTeamData={(value) =>
            setState((prev) => ({ ...prev, teamData: value }))
          }

        />
      ) : (
        <div className="w-[96%] h-[calc(100vh-8.1rem)] bg-white flex flex-col gap-[1.35rem]
         pt-[1.25rem] px-[1.38rem] rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]">
          <div className="w-full h-[2.663rem] flex justify-between">
            <div className="flex justify-center items-center">
              <h2 className="font-extrabold text-xl text-[#031B59]">
                {t("details")}
              </h2>
            </div>
            <div className="flex items-center justify-center lg:space-x-4 md:space-x-2">
              <Search
                searchItem={state.searchName}
                setSearchItem={(value) =>
                  setState((prev) => ({ ...prev, searchName: value }))
                }
              />
              <div className="p-3 text-xl rounded-full border">
                <IoFilter
                  className="w-6 h-6 stroke-[#031B59]"
                  data-testid="filter-icon"
                  onClick={() =>
                    setState((prev) => ({ ...prev, filter: !state.filter }))
                  }
                />
                {state.filter && (
                  <div className="absolute z-50 right-24 ml-[-20rem]">
                    <AttendanceFilter
                      {...state}
                      handleDepartmentChange={handleDepartmentChange}
                      handleDesignationChange={handleDesignationChange}
                      setShowFilter={handleCloseFilter}
                      filterTitleDepartMent={t("department")}
                      filterTitleDesignation={t("designation")}
                      handleApply={handleApply}
                      handleRefresh={handleRefresh}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-full overflow-auto border rounded-lg custom_scroll">
            <CustomTable
              columns={teamTimeSheets}
              data={searchData}
              isLoading={isLoading}
              renderAction={(job) => (
                <div className="flex gap-5">
                  <div onClick={() => handleOpen(job)}>
                    <FaEye fill="#031B59" />
                  </div>
                </div>
              )}
            />
          </div>
          {searchData.length > 0 && (
            <div className="w-full h-16 bg-white flex justify-between items-center">
              <div className="text-[#031B59] font-medium">
                {t("showing")} {state.currentPage} {t("of")} {pageCount}
              </div>
              <Paginate
                currentPage={state.currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={5}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UsersTimesheet;
