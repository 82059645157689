export const formatEmployeeListData = (response) => {
  const requiredResponse = [];

  response?.forEach((obj) => {
    const salary = obj?.salary || {};
    const bank = obj?.bank || {};

    requiredResponse.push({
      id: obj?.id,
      contactNo: obj?.contact_no,
      designation: obj?.designation,
      employeeId: obj?.employee_id,
      dateOfJoining: obj?.date_of_joining,
      passwordUpdated: obj?.password_updated,
      isActive: obj?.is_active,
      deactivatedDate: obj?.deactivated_date,
      bloodGroup: obj?.blood_group,
      city: obj?.city,
      companyDetailId: obj?.company_detail_id,
      country: obj?.country,
      createdAt: obj?.created_at,
      ctc: obj?.ctc,
      dateOfBirth: obj?.date_of_birth,
      departmentId: obj?.department_id,
      designationId: obj?.designation_id,
      emergencyContactNo: obj?.emergency_contact_no,
      empCode: obj?.emp_code,
      employeeTimeId: obj?.employee_time_id,
      employeeType: obj?.employee_type,
      esicNo: obj?.esic_no,
      fatherName: obj?.father_name,
      fullName: obj?.full_name,
      gender: obj?.gender,
      incomeTax: obj?.income_tax,
      jobType: obj?.job_type,
      linkedinProfile: obj?.linkedin_profile,
      managerId: obj?.manager_id,
      managerName: obj?.manager_name,
      maritalStatus: obj?.marital_status,
      motherName: obj?.mother_name,
      noticePeriod: obj?.notice_period,
      panNo: obj?.pan_no,
      personalEmail: obj?.personal_email,
      pincode: obj?.pincode,
      position: obj?.position,
      profilePictureUrl: obj?.profile_picture_url,
      relievingDate: obj?.relieving_date,
      resignationDate: obj?.resignation_date,
      resignationStatus: obj?.resignation_status,
      retentionBonus: obj?.retention_bonus,
      retentionBonusNo: obj?.retention_bonus_no,
      retentionTime: obj?.retention_time,
      salary: {
        id: salary?.id,
        ctc: salary?.ctc,
        addition: {
          basicSalary: salary.addition?.basic_salary,
          houseRentAllowance: salary.addition?.house_rent_allowance,
          medicalAllowance: salary.addition?.medical_allowance,
          specialAllowance: salary.addition?.special_allowance,
        },
        deduction: {
          esic: salary.deduction?.esic,
          tds: salary.deduction?.tds,
          gratuity: salary.deduction?.gratuity,
          providentFund: salary.deduction?.provident_fund,
        },
        other: {
          incentives: salary.other?.incentives,
          retentionBonus: salary.other?.retention_bonus,
        },
      },
      sectionApplicable: obj?.section_applicable,
      state: obj?.state,
      uanNo: obj?.uan_no,
      uniqueBulkUploadId: obj?.unique_bulk_upload_id,
      updatedAt: obj?.updated_at,
      workFromOffice: obj?.work_from_office,
      accountId: obj?.account_id,
      address: obj?.address,
      assignedUserId: obj?.assigned_user_id,
      backgroundVerification: obj?.background_verification,
      bank: {
        id: bank?.id,
        accountName: bank?.account_name,
        accountNumber: bank?.account_number,
        ifsc: bank?.ifsc,
        userId: bank?.user_id,
        swiftCode: bank?.swift_code,
        cancelledChequeName: bank?.cancelled_cheque_name,
        cancelledChequeType: bank?.cancelled_cheque_type,
        cancelledChequeUrl: bank?.cancelled_cheque_url,
      },
      ifsc: obj?.ifsc,
      swiftCode: obj?.swift_code,
      userId: obj?.user_id,
    });
  });

  return requiredResponse;
};

export const createPasswordPayload = (userData, values) => {
  return {
    current_password: userData?.role === "employee" ? values?.currentPassword : "",
    new_password: values?.newPassword,
    confirm_password: values?.confirmPassword,
  };
};
