/* eslint-disable react/no-unknown-property */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  alignValues,
  MyTimesheets
} from "component/admin/TimeSheet/columnTimeSheet";
import { FaEye } from "react-icons/fa6";
import Paginate from "component/admin/Employee/Paginate";
import { useTranslation } from "react-i18next";
import TimeSheetPopup from "component/timesheet/viewsheet/TimeSheetPopup";
import CustomTable from "component/common/table/CustomTable";

const MyTimesheetListing = ({ currentPage, setCurrentPage }) => {
  const { t } = useTranslation();
  const { myTimesheetData, pageCount, isLoading } = useSelector(
    (state) => state.statusReducer
  );
  // const { userId, isAdmin } = usePermissions();
  const [openPopup, setOpenPopup] = useState(false);
  const [datas, setDatas] = useState(null);

  const tempData = [
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index, timeSheet) => {
        return (
          <div className="items-start cursor-pointer">
            <div onClick={() => handletask(timeSheet)}>
              <FaEye />
            </div>
          </div>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  const handletask = (data) => {
    setOpenPopup(true);
    setDatas(data);
  };

  return (
    <>
      <div className="">
        {openPopup && (
          <TimeSheetPopup
            setOpenPopup={setOpenPopup}
            setDatas={setDatas}
            datas={datas}
          />
        )}

        <CustomTable
          newColumn={["Action"]}
          columns={[...MyTimesheets, ...tempData]}
          data={myTimesheetData}
          isLoading={isLoading}
        />
      </div>
      {
        <div className="w-full h-16 bg-white flex justify-between items-center">
          {myTimesheetData?.length > 0 && (
            <>
              <div className="text-[#031B59] font-medium">
                {t("showing")} {currentPage} {t("of")}
                {` ${pageCount}`}
              </div>
              <Paginate
                currentPage={currentPage}
                initialPageCount={pageCount}
                pageRangeDisplayed={5}
                next=">"
                previous="<"
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </div>
      }
    </>
  );
};

export default MyTimesheetListing;

MyTimesheetListing.propTypes = {
  data: PropTypes.object,
  statusdelete: PropTypes.bool,
  isClicked: PropTypes.bool,
  finalFilteredValue: PropTypes.array,
  setCurrentPage: PropTypes.any,
  currentPage: PropTypes.number,
  status: PropTypes.any,
};
