/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import swalService from "../../utils/SwalServices";
import { useTranslation } from "react-i18next";
import { bankDetailsSchema } from "redux/validator/Profile/bankInfo";
import LoaderComp from "component/loader/LoaderComp";
import { updateBankDetailnew } from "redux/appThunk/Employee/profile";
import BankDetailForm from "component/common/BankDetails/BankDetailForm";
import { formatBankDetailsFormData } from "redux/selector/Employee/profile";

export default function BankDetails() {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const bankDetails = useSelector((state) => state.bankReducer.bankData);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const [submitshow, setSubmitshow] = useState(false);

  const initialvalues = {
    accountName: bankDetails?.bank_details?.accountName || "--",
    accountNumber: bankDetails?.bank_details?.accountNumber || "--",
    ifsc: bankDetails?.bank_details?.ifsc || "--",
    cancelCheckUrl: bankDetails?.cancelCheckUrl || "--",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: bankDetailsSchema,
    onSubmit: async (values) => {
      const formdata = formatBankDetailsFormData(values);
      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this?",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel",
        })
        .then(async (result) => {
          if (result.value) {
            dispatch(
              updateBankDetailnew(id, formdata, setSubmitshow, setReload)
            );
          }
        });
    },
  });
  const { handleSubmit } = Formik;

  useEffect(() => {
    Formik.setValues({
      accountName: bankDetails?.bank_details?.accountName,
      accountNumber: bankDetails?.bank_details?.accountNumber,
      ifsc: bankDetails?.bank_details?.ifsc,
      cancelCheckUrl: bankDetails?.cancelCheckUrl,
    });
  }, [bankDetails]);

  return (
    <div className="w-[96%] max:h-[46.063rem] min:h-[20rem] p-5 bg-white flex-flex-col space-y-8 rounded-[20px]">
      {!reload ? <BankDetailForm /> : <LoaderComp />}
      {submitshow && (
        <div
          data-testId="update-section"
          className="w-full h-16 bg-white flex justify-end"
        >
          {userData?.role === "admin" && (
            <div className="flex items-center justify-center space-x-4">
              <button
                data-testId="cancel-button"
                className="w-[7.625rem] h-[2.688rem] flex items-center justify-center rounded-full"
                onClick={() => {
                  setSubmitshow(false);
                }}
              >
                {t("cancel")}
              </button>
              <button
                data-testId="update-button"
                className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
                type="submit"
                onClick={handleSubmit}
              >
                {t("update")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
