import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import swalService from "utils/SwalServices";
import { bankDetailsSchema } from "redux/validator/Profile/bankInfo";
import { updateBankDetails } from "redux/appThunk/Employee/profile";
import BankDetailForm from "component/common/BankDetails/BankDetailForm";

export default function BankDetailInfo({
  accordionOpen,
  editState,
  setEditState,
  bankId,
}) {
  const bankDetails = useSelector((state) => state.bankReducer.bankData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialvalues = {
    account_name: bankDetails?.bank_details?.account_name || "",
    account_number: bankDetails?.bank_details?.account_number || "",
    ifsc: bankDetails?.bank_details?.ifsc || "",
    cancelled_cheque: bankDetails?.cancelled_cheque_url || "",
    swift_code: bankDetails?.swift_code || "",
  };

  const Formik = useFormik({
    initialValues: initialvalues,
    validationSchema: bankDetailsSchema,
    onSubmit: async (values) => {
      const formdata = new FormData();
      formdata.append("bank_details[account_name]", values.account_name);
      formdata.append("bank_details[account_number]", values.account_number);
      formdata.append("bank_details[ifsc]", values.ifsc);
      formdata.append("bank_details[swift_code]", values.swift_code);

      swalService
        .showWarning({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to Update this?",
          showCancelButton: true,
          confirmButtonText: "Yes, Update it!",
          cancelButtonText: "No, cancel",
        })
        .then(async (result) => {
          if (result.value) {
            dispatch(
              updateBankDetails(
                bankId,
                formdata,
                setEditState,
                setEditState,
                setLoading
              )
            );
          }
        });
    },
  });

  useEffect(() => {
    Formik.setValues({
      account_name: bankDetails?.bank_details?.account_name,
      account_number: bankDetails?.bank_details?.account_number,
      ifsc: bankDetails?.bank_details?.ifsc,
      cancelled_cheque: bankDetails?.cancelled_cheque_name,
    });
  }, [bankDetails]);

  return (
    <div>
      <div>
        {accordionOpen === 5 && !loading && (
          <div className="max-h-full w-full p-3 m-[0.8rem] bg-[#f2f6ff]">
            <BankDetailForm
              editState={editState}
              setEditState={setEditState}
              bankId={bankId}
            />
          </div>
        )}
      </div>
    </div>
  );
}

BankDetailInfo.propTypes = {
  accordionOpen: PropTypes.any,
  editState: PropTypes.any,
  setEditState: PropTypes.any,
  eyeId: PropTypes.any,
  bankId: PropTypes.any,
};
